import "../../../assets/custom-style.css";
import "../../../assets/custom-style.scss";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import Header from "../../sidebar/Header";

const Support = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <Header />
          <section className="content-section">
            {/* <!-- Main Content Section Starts here --> */}
            <section className="row main-content settlement-content settings-content">
              <div className="title mb-3 d-flex align-items-center">
                <h3 className="mb-0 me-2">Support</h3>
                <p className="mb-0 opacity-150 mt-2">
                  {/* <i
                    className="material-symbols-outlined info-ico me-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={`Hello ${currentUser?.data?.name || 'there'}! Update your restaurant's banner and profile to shine on the user app!`}
                    // title="Hey dude! Update your restaurant's banner and profile to shine on the user app!"
                  >
                    info
                  </i> */}
                </p>
              </div>
              <div className="title mb-12 md-3 mt-3">
                <h5 className="mb-1 mid-gray fw-bold">
                  <small>Hello, Chef {currentUser?.data?.name}!</small>
                </h5>
              </div>
              <h6 className="mb-3 mt-3">
               
                <small className="mt-1">
                  Thank you for reaching out to HomeChef360. For additional
                  assistance,
                </small>
            
              </h6>
              <h6 className="mb-3">
               
           
              <small className="mt-1">
  Please contact <a className="link cursorPointer" href="mailto:support@homechef360.com"><b>support@homechef360.com</b></a>
</small>

             </h6>
            </section>
            {/* <!-- Main Content Section Ends here --> */}
          </section>
        </div>
      </section>
    </>
  );
};

export default Support;
