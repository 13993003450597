import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import Avatar from "react-avatar";
import { useEffect, useState } from "react";
import {
  ProfileDetails,
  clearNotification,
  getNotification,
  orderList,
  currentlyAvailable,
  searching,
  changeStatusOrders,
} from "../../auth/core/_requests";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

interface Dish {
  uuid: string;
  orderId: string;
  dishImage: string;
  name: string;
  cuisine: any;
  category: any;
  type: any;
  tags: any;
  offerPrice: any;
  enableDisableStatus: boolean;
  netValue: string;
  orderstatus: {
    name: string;
  };
  orderdetails: [
    {
      dish: {
        name: string;
      };
      quantity: string;
    }
  ];
}
interface Orders {
  primaryId: number;
  orderstatusId: number;
  uuid: string;
  orderId: string;
  chefId: string;
  userId: string;
  amount: number;
  tax: number;
  deliveryCharge: number;
  deliveryType: number;
  couponApplied: boolean;
  couponAmount: number | null;
  walletDiscount: number | null;
  deliveryPersonTips: number;
  chefTips: number;
  paymentMethod: string;
  netValue: number;
  orderDate: string;
  orderTime: string;
  cookingInstruction: string;
  totalAmount: number;
  orderstatus: {
    name: string;
  };
  chef: {
    name: string;
    currentlyAvailableStatus: boolean;
    email: string;
    phoneNumber: string | null;
    phoneNumber1: string | null;
    mobileNumber: string;
    mobileNumber1: string | null;
    location: string | null;
    licence: string | null;
    chefProfile: string | null;
    approvalStatus: boolean;
    address1: string;

    city: string;
    state: string;
    country: string;
    zipCode: string;
    status: number;
    enableDisableStatus: boolean;
  };
  orderdetails: [
    {
      id: number;
      orderId: string;
      dishId: string;
      quantity: number;
      amount: number;
      status: number;
      createdAt: string;
      updatedAt: string;
      dish: {
        name: string;
        dishImage: string;
        description: string;
        price: number;
        dishvariants: [
          {
            id: number;
            measurementId: number;
            unit: number;
            price: number;
            discount: number;
            finalPrice: number;
          }
        ];
        addons: [
          {
            id: number;
            name: string;
            quantity: number;
            price: number;
          }
        ];
      };
    }
  ];
  user: {
    id: number;
    uuid: string;
    userProfile: string;
    deviceId: string | null;
    token: string;
    verifyStatus: string | null;
    deviceType: string | null;
    deviceToken: string | null;
    loginType: string | null;
    name: string;
    email: string;
    latitude: string | null;
    longitude: string | null;
    contactNumber: string;
    plansId: string | null;
    planStartDate: string | null;
    planEndDate: string | null;
    planStatus: string | null;
    status: number;
    createdBy: string | null;
    updatedBy: string | null;
    createdAt: string;
    updatedAt: string;

    addressbooks: [
      {
        label: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        country: string;
        zipcode: any;
      }
    ];
  };
}
type NotificationData = {
  id: string;
  title: string;
  description: string;
  name: string;
  time: string;
};

const Header = () => {
  const navigate = useNavigate();
  const { logout, currentUser } = useAuth();
  const [slug, setslug] = useState<any>("");
  const [notification, setNotification] = useState<NotificationData[]>([]);
  console.log("notification-header",notification)
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<Dish[]>([]);
  const [showModal, setShowModel] = useState(false);
  const [chefStatus, setChefStatus] = useState<boolean>();
  const chefId = currentUser?.uuid;
  const [order, setOrder] = useState<Orders[]>([]);
  // console.log("orders-setOrder", order);
  const [menuMobileActive, setMenuMobileActive] = useState(false); // State for menu-mobile
  const toggleMenuMobile = () => {
    setMenuMobileActive(!menuMobileActive);
  };
  const [msgsound, setnotificationSound] = useState<any>();
  const [listDish, setListDish] = useState<Dish[]>([]);
  const [profiledata, setProfileData] = useState<any>();
  const [mainSearch, setMainSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null); // Initial value is null
  console.log("orderId-header",orderId)

  useEffect(() => {
    const pathname = window.location.pathname;
    const pathSegments = pathname.split("/");
    const firstSlug = pathSegments.find((segment) => segment !== "");
    setslug(firstSlug);
  }, [slug]);

  const logoutFunction = () => {
    localStorage.removeItem("homechef360ChefPortal");
    localStorage.removeItem("data");
    localStorage.removeItem("mobileNumber");
    localStorage.removeItem("chefStatus");
    localStorage.removeItem("name");
    localStorage.removeItem("chefemail");
    navigate("/login");
  };

  useEffect(() => {
    ProfileDetails(chefId).then((res) => {
      setProfileData(res.data);
      setData(res.data);
      setChefStatus(res?.data?.currentlyAvailableStatus);
      setnotificationSound(res?.data?.notification_master?.tone);
      console.log("msgsound-res.data", res.data);
      console.log(
        "msgsound-res?.data?.notification_master?.tone",
        res?.data?.notification_master?.tone
      );

      console.log(
        "res.data.currentlyAvailableStatus-header",
        res.data.currentlyAvailableStatus
      );
    });
  }, []);
  const showNotification = () => {
    setShowModel(true);
    // playNotificationSound(); // Play the notification sound when the notification is displayed
  };

  const handleClose = () => {
    setShowModel(false);
    clearNotification(chefId).then((res: any) => {
      setNotification(res);
    });
  };
  useEffect(() => {
    const storedStatus = localStorage.getItem("chefStatus");
    if (storedStatus !== null) {
      setChefStatus(JSON.parse(storedStatus));
    }
  
    // Function to fetch order details and update state
    const fetchOrderDetails = () => {
      orderList(chefId).then((res: any) => {
        console.log("Order List Response:", res);
  
        // Extract orderId from the order object
        const orderId = res.orderId;
  
        console.log("Order ID:", orderId);
  
        // Set orderId state
        setOrderId(orderId);
  
        // Setting the whole order response in state
        console.log("Setting order state:", res);
        setOrder(res);
        
        console.log("Setting data state:", res);
        setData(res);
      });
  
      getNotification(chefId).then((res: any) => {
        console.log("Notification Response:", res);
        setNotification(res);
      });
    };
  
    // Initial call
    fetchOrderDetails();
  
    // Set up interval to fetch order details every 30 seconds
    const intervalId = setInterval(fetchOrderDetails, 30000);
  
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
  // useEffect(() => {
  //   const storedStatus = localStorage.getItem("chefStatus");
  //   if (storedStatus !== null) {
  //     setChefStatus(JSON.parse(storedStatus));
  //   }
  //   orderList(chefId).then((res: any) => {
  //     console.log("res-->orderlist-->header", res);
  //     console.log("res-->orderlist-->header", res.orderId);
  //     setOrder(res);

  //     setData(res);
  //   });
  //   getNotification(chefId).then((res: any) => {
  //     console.log("getNotification-res",res)
  //     setNotification(res);
  //   });
  // }, []);

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/${encodeURIComponent(searchTerm)}`);
  };

  const fetchChefProfile = async () => {
    try {
      const res = await ProfileDetails(chefId);
      setProfileData(res.data);
      setData(res.data);
      setChefStatus(res.data.currentlyAvailableStatus);
      // Check if the current page is the "Orders" page
      if (slug === "orders") {
        // Redirect based on chef status
        if (res.data.currentlyAvailableStatus === true) {
          navigate("/orders/success", { replace: true });
        } else {
          navigate("/orders/list", { replace: true });
        }
      }
    } catch (error) {
      console.error("Error fetching chef profile:", error);
    }
  };

  const handleChefStatusChange = async () => {
    const newStatus = !chefStatus;
    const data = {
      currentlyAvailableStatus: newStatus,
    };
    await currentlyAvailable(chefId, data);
    localStorage.setItem("chefStatus", JSON.stringify(newStatus));
    fetchChefProfile();
    setChefStatus(newStatus);
  };
  const [isLeftBarActive, setLeftBarActive] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLeftBarActive(!isLeftBarActive);
    document.body.classList.add("overflow-hidden");
  };

  const handleMenuBackdropClick = () => {
    // Remove the active classes
    setLeftBarActive(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleMenuItemClick = () => {
    // Remove the active classes
    setLeftBarActive(false);
    document.body.classList.remove("overflow-hidden");
  };
  const clickMainSearch = async (e: any) => {
    e.preventDefault();
    const searchTerm = mainSearch;
    
    setLoading(true);

    try {
      await searching(chefId, searchTerm).then((res) => {
        setLoading(false);
        // Check the type of search result
        if (res?.success === true) {
          toast.success(res?.message, { autoClose: 5000 });
        } else {
          toast.error(res?.message, { autoClose: 5000 });
        }
        if (res?.data?.type === "dish") {
          // Append the search term as a query parameter if it's not empty
          const destination = searchTerm
            ? `/dish/list?search=${encodeURIComponent(searchTerm)}`
            : "/dish/list";
          navigate(destination);
        } else if (res?.data?.type === "orders") {
          const destination = searchTerm
            ? `/orders/history?search=${encodeURIComponent(searchTerm)}`
            : "/orders/history";
          navigate(destination);
        }
      });
    } catch (error) {
      console.error("Error fetching coupon:", error);
      setLoading(false);
    }
  };

  const clearSearch = () => {
    setMainSearch(""); // Clear the search term
    localStorage.removeItem("mainSearch"); // Remove the search term from localStorage
    navigate("/dish/list"); // Navigate to the dish list without any search parameter
  };
  const [showOrderModal, setShowOrderModal] = useState(true); // Add state variable for modal visibility
  const handleToggleOrderModal = () => {
    setShowOrderModal(!showOrderModal);
  };
  const handleOrderStatusChange = async (order: Orders, action: string) => {
    try {
      let newOrderStatusId;

      // Map action to the corresponding status name
      switch (action) {
        case "Accept Order":
          newOrderStatusId = 3;
          break;
        case "Cancel Order":
          newOrderStatusId = 7;
          break;
        default:
          console.warn("Unknown action:", action);
          return;
      }

      // Make the API call to update the order status
      await changeStatusOrders(order.uuid, { orderStatus: newOrderStatusId });

      // Fetch the updated order list
      const updatedOrders = await orderList(chefId);
      setOrder(updatedOrders);
      setShowOrderModal(true);
      // Navigate to orders view
      navigate("/orders/view");

      // Hide the modal
      setShowOrderModal(false);
    } catch (error) {
      console.error("Error handling order status change:", error);
    }
  };
  const playNotificationSound = () => {
    const sound = new Audio(msgsound);
    sound.play();
  };

  return (
    <>
      <section className={`left-bar ${isLeftBarActive ? "active" : ""}`}>
        <div className="lb-top">
          <div className="nav-brand mx-auto mt-md-5 mt-4 mb-4">
            <img
              className="img-fluid"
              src="../media/home-chef-logo.svg"
              alt="HomeChef360"
            />
          </div>
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "dashboard" ? "active" : ""}`}
                to="/dashboard"
                onClick={handleMenuItemClick}
              >
                <i className="material-symbols-outlined">home</i>
                <span>Home</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "orders" ? "active" : ""}`}
                to="/orders"
                onClick={() => {
                  handleMenuItemClick();
                  ProfileDetails(chefId).then((res) => {
                    const chefStatus = res.data.currentlyAvailableStatus;
                    if (chefStatus === true) {
                      navigate("/orders/success");
                    } else if (chefStatus === false) {
                      navigate("/orders/list");
                    }
                  });
                }}
              >
                <i className="material-symbols-outlined">orders</i>
                <span>Orders</span>
              </Link>
            </li>
           
            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "addons" ? "active" : ""}`}
                aria-current="page"
                to="/addons"
                onClick={handleMenuItemClick}
              >
                <i className="material-symbols-outlined">restaurant_menu</i>
                <span>Addons</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "dish" ? "active" : ""}`}
                aria-current="page"
                to="/dish"
                onClick={handleMenuItemClick}
              >
                <i className="material-symbols-outlined">restaurant</i>
                <span>Dishes</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "settlements" ? "active" : ""}`}
                aria-current="page"
                to="/settlements"
                onClick={handleMenuItemClick}
              >
                <i className="material-symbols-outlined"> payments</i>
                <span> Settlements</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "coupon" ? "active" : ""}`}
                aria-current="page"
                to="/coupon"
                onClick={handleMenuItemClick}
              >
                <i className="material-symbols-outlined">sell</i>
                <span> Coupons</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "report" ? "active" : ""}`}
                aria-current="page"
                to="/report"
                onClick={handleMenuItemClick}
              >
                <i className="material-symbols-outlined">analytics</i>
                <span>Reports</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "support" ? "active" : ""}`}
                aria-current="page"
                to="/support"
                onClick={handleMenuItemClick}
              >
                <i className="material-symbols-outlined">support</i>
                <span>Support</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${slug === "help" ? "active" : ""}`}
                aria-current="page"
                to="/help"
                onClick={handleMenuItemClick}
              >
                <i className="material-symbols-outlined">help</i>
                <span>Help</span>
              </Link>
            </li>
          </ul>
        </div>
        <ul className="nav btm">
          <li className="nav-item">
            <Link
              className={`nav-link ${slug === "report" ? "active" : ""}`}
              aria-current="page"
              to="/settings"
              onClick={handleMenuItemClick}
            >
              <span>Settings</span>
              <i className="material-symbols-outlined">settings</i>
            </Link>
          </li>
        </ul>
      </section>

      <section className="head row top-header">
        <div className="col-6">
          <div className="nav-brand d-md-none">
            <img
              className="img-fluid"
              src="../media/head-nav-brand.svg"
              alt="HomeChef360"
            />
          </div>
          <form action="#" className="search d-none d-md-inline-flex">
            <div className="input-group">
              <input
                type="text"
                className="form-control me-3"
                placeholder="Search what you need..."
                value={mainSearch}
                onChange={(e) => {
                  setMainSearch(e.target.value);
                  localStorage.setItem("mainSearch", e.target.value);
                }}
              />
              {mainSearch && ( // Render clear button only when search text is present
                <button
                  className="btn small mt-1"
                  // className="btn btn-secondary rounded-5 small mt-1"
                  onClick={clearSearch}
                >
                  <i className="material-symbols-outlined">clear</i>
                </button>
              )}
              <span className="input-group-text" onClick={clickMainSearch}>
                <i className="material-symbols-outlined">search</i>
              </span>
            </div>
          </form>
        </div>

        <div className="col-6 d-flex justify-content-end align-items-center">
          <a
            className="me-3"
            href="https://www.youtube.com/watch?v=f5zge_mI02M&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer"
          >
             <i
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Walk through video."
              className="material-symbols-outlined"
              style={{
                color: "red",
                marginLeft: "12px",
                fontSize: "38px",
                width: "48px",
                height: "38px",
              }}
            >
              youtube_activity
            </i>
          </a>
          <div className="form-check form-switch live me-3">
            <input
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="An Online/Offline status indicator for chefs. When a chef is Online, they're ready to accept orders. 
              If they're Offline, they're temporarily unable to process orders, ensuring transparency and helping you make informed dining choices."
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={chefStatus}
              onChange={handleChefStatusChange}
            />
            <label className="yes">Online</label>
            <label className="no">Offline</label>
          </div>

          <a
            href="javascript:;"
            className="me-3 notify-ico"
            onClick={showNotification}
          >
            <span className="material-symbols-outlined">notifications</span>
          </a>
          <div className="dropdown profile-dd">
            <button
              className="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {profiledata?.profile ? (
                <Avatar
                  src={profiledata?.profile}
                  name={profiledata?.name}
                  maxInitials={1}
                  size="25"
                  round
                ></Avatar>
              ) : (
                <Avatar
                  name={profiledata?.name}
                  maxInitials={1}
                  size="25"
                  round
                  color="#607458"
                  fgColor="#ffffff"
                ></Avatar>
              )}

              <p className="px-2">{profiledata?.name}</p>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <Link className="dropdown-item" to="/settings">
                  Settings
                </Link>
              </li>

              <li>
                <a
                  className="dropdown-item cursorPointer"
                  onClick={logoutFunction}
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
          <button
            className="btn menu-mobile ms-3 d-md-none"
            onClick={handleClick}
          >
            <span>&nbsp;</span>
          </button>
          <div
            className={`menu-backdrop ${isLeftBarActive ? "active" : ""}`}
            onClick={handleMenuBackdropClick}
          ></div>
        </div>
      </section>

      {/* Modal for Notification */}
      <Modal show={showModal} className="p-10" onHide={handleClose}>
        <div className="w-250px w-lg-275px ">
          <div
            className="d-flex flex-column  rounded-top "
            style={{ padding: "20px", backgroundColor: "#5DA571" }}
          >
            <h3 className=" fw-bold text-white px-9 mt-10">Notifications</h3>
          </div>

          <div className="tab-content ">
            <div className="scroll-y mh-50px my-1 px-8 p-3">
              {notification?.length > 0 ? (
                notification?.map((item, i) => (
                  <div
                    className="card mb-2 "
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                  >
                    <div
                      className="card-header p-2 d-flex align-items-center justify-content-between"
                      style={{ backgroundColor: "#E2E1E866" }}
                    >
                      <strong className="me-auto text-gray">
                        {item?.title}
                      </strong>
                      <small className="text-end ms-auto">{item?.time}</small>
                      {/* <button className="btn-close btm-sm text-end" onClick={() => handleClose(item?.id)}></button> */}
                    </div>
                    <div className="card-body  p-2">{item?.description}</div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <div className="thumbnail">
                    <img
                      src="../../../assets/images/noDishes.svg"
                      style={{ height: "50px" }}
                    />
                  </div>
                  No notification
                </div>
              )}
            </div>

            <div
              className="tab-pane fade"
              id="kt_topbar_notifications_3"
              role="tabpanel"
            >
              <div className="scroll-y mh-325px my-5 px-8"></div>
              <div className="py-3 text-center border-top">
                <Link
                  to="/crafted/pages/profile"
                  className="btn btn-color-gray-600 btn-active-color-primary"
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Button
            className="btn btn-cancel rounded-5 small me-2 mt-1"
            onClick={handleClose}
          >
            clear
          </Button>
        </Modal.Footer>
      </Modal>

      {showOrderModal && (
        <>
          {/* {console.log(
            "order------>mapping",
            order.length,
            order.length > 0,
            order
          )} */}
          <>
            {order.map((item, index) => {
              // Check if the order status is 1
              if (item.orderstatusId === 1) {
                playNotificationSound(); // Play the notification sound
                return (
                  <div
                    className="modal fade show new-order d-block"
                    key={index}
                    id={`newOrder-${index}`}
                    aria-labelledby={`newOrderLabel-${index}`}
                    aria-hidden="true"
                  >
                    <div className="new-order-overlay"></div>
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                      <div className="modal-content px-md-4 px-3 overflow-visible">
                        <div className="modal-header px-0 flex-column align-items-start">
                          <h2 className="modal-title mb-md-3 mb-2">
                            New Order
                          </h2>

                          <div className="order-detail">
                            <div>
                              <h5 className="primary-color mb-0">
                                Order {item.orderId}
                              </h5>
                              <p className="mb-0">
                                <span className="tiny mid-gray">
                                  Order by {item.user.name}
                                </span>
                              </p>
                            </div>
                            <h6 className="mb-0 mid-gray">{item.orderTime}</h6>
                          </div>
                        </div>
                        {item.orderdetails.map((orderDetail, j) => (
                          <div className="modal-body" key={`orderDetail-${j}`}>
                            <div className="item-detail">
                              <div className="row mb-2">
                                <h6 className="medium px-0">Item Details</h6>
                              </div>
                              <div className="row mb-3">
                                <div className="col-8 ps-0">
                                  <div className="dish">
                                    <div className="thumbnail">
                                      <img
                                        src={orderDetail.dish.dishImage}
                                        alt={orderDetail.dish.name}
                                      />
                                    </div>
                                    <p className="mb-0">
                                      <span className="fw-500">
                                        {orderDetail.quantity}
                                      </span>{" "}
                                      x {orderDetail.dish.name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-4 pe-0 d-flex align-items-center justify-content-end">
                                  <h6 className="mb-0">
                                    ${" "}
                                    {orderDetail.quantity *
                                      orderDetail.dish.dishvariants[0].price}
                                  </h6>
                                </div>
                              </div>

                              {orderDetail.dish.addons.map((addon, k) => (
                                <div className="row mb-3">
                                  <p className="px-0 mb-0">
                                    <small className="fw-500">Addons</small>
                                  </p>
                                  <div className="col-8 ps-0">
                                    <div className="dish">
                                      <p className="px-0 mb-0">
                                        <span className="tiny mid-gray">
                                          {addon.name}
                                        </span>{" "}
                                        x {addon.quantity}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-4 pe-0 d-flex align-items-center justify-content-end">
                                    <h6 className="mb-0">
                                      $ {addon.quantity * addon.price}
                                    </h6>
                                  </div>
                                </div>
                              ))}
                              <div className="row mb-3">
                                <p className="px-0 mb-1">
                                  <small className="fw-500">
                                    Cooking Instructions
                                  </small>
                                </p>
                                <p className="px-0 mb-0">
                                  <span className="tiny mid-gray">
                                    {item.cookingInstruction}
                                  </span>
                                </p>
                              </div>
                              <div className="row mb-3">
                                <div
                                  className="accordion bill-toggle px-0"
                                  id="accordionExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        <h6 className=" mb-0">
                                          Total Bill: ${" "}
                                          {item?.totalAmount.toFixed(2)}{" "}
                                          <span className="badge bg-primary ms-1">
                                            PAID
                                          </span>
                                        </h6>
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <div className="row mb-2">
                                          <p className="col-7 mid-gray mb-0">
                                            <small>Item Total</small>
                                          </p>
                                          <h6 className="col-5 mid-gray medium mb-0 amount">
                                            $ {item?.totalAmount.toFixed(2)}
                                          </h6>
                                        </div>
                                        <div className="row mb-2">
                                          <p className="col-7 mid-gray mb-0">
                                            <small>Delivery Fee</small>
                                          </p>
                                          <h6 className="col-5 mid-gray medium mb-0 amount">
                                            $ {item?.deliveryCharge.toFixed(2)}
                                          </h6>
                                        </div>
                                        <div className="row mb-2">
                                          <p className="col-7 mid-gray mb-0">
                                            <small>Tip Charge</small>
                                          </p>
                                          <h6 className="col-5 mid-gray medium mb-0 amount">
                                            $ {item?.chefTips.toFixed(2)}
                                          </h6>
                                        </div>
                                        <div className="row mb-2">
                                          <p className="col-7 mid-gray mb-0">
                                            <small>Taxes</small>
                                          </p>
                                          <h6 className="col-5 mid-gray medium mb-0 amount">
                                            $ {item?.tax.toFixed(2)}
                                          </h6>
                                        </div>
                                        <div className="row mb-1 mt-1">
                                          <p className="col-7 mb-0">
                                            Grand Total
                                          </p>
                                          <h6 className="col-5 medium mb-0 amount">
                                            $ {item?.netValue.toFixed(2)}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="modal-footer border-0 new-order-ftr px-0">
                          <button
                            type="button"
                            className="btn btn-danger rounded-5"
                            data-bs-dismiss="modal"
                            onClick={() =>
                              handleOrderStatusChange(item, "Cancel Order")
                            }
                          >
                            Reject
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary rounded-5"
                            onClick={() =>
                              handleOrderStatusChange(item, "Accept Order")
                            }
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              // If the order status is not 1, return null (don't render anything)
              return null;
            })}
          </>
        </>
      )}
    </>
  );
};
export default Header;