import React, { useEffect, useState } from "react";
import "../../../assets/custom-style.css";
import Header from "../../sidebar/Header";
import { useParams } from "react-router-dom";
import {
  deleteDish,
} from "../core/_requests";
import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";

const DeleteDish = () => {
  const { id } = useParams();
  const navigate = useNavigate();


  const deleteItem = () => {
    console.log("id===", id);
    deleteDish(id)
      .then((res) => {
        console.log("res", res); // Add this line
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
    navigate("/dish");
  };


  const clickCancel = () => {
    navigate("/dish");
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <Header />
          <div className="overlay"></div>
          <div className="drawer small add-dish">
            <div className="alert-wrap">
              <img
                className="ico mb-4"
                src="../../dish_images/alert-warning-ico.svg"
                alt="success"
              />
              <h1 className="mb-3">Are you sure?</h1>
              <p className="mid-gray mb-4 text-center">
                <small>
                  Do you want delete this dish. You can retrieve
                  <br />
                  this dish if you delete
                </small>
              </p>
              <button
                className="btn btn-danger rounded-5 px-5 mt-2 mb-2"
                onClick={deleteItem}
              >
                Yes, Delete
              </button>
              <button
                className="btn btn-cancel rounded-5"
                onClick={clickCancel}
              >
                No, I don't want to delete
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteDish;
