import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendEmailOtp, verifyChefEmail } from "../core/_requests";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const Otpverification = () => {
  const [btnstate, setbtnstate] = useState(true);
  const [otpValue, setOtpValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [expiredIn, setExpiredIn] = useState(60);
  const [timerRunning, setTimerRunning] = useState(true);
  const [email, setemail] = useState(localStorage.getItem("chefemail"));
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleClose = () => {
    setShowAlert(false);
  };

  const handleKeyUp = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    const values = otp.slice();
    const nonEmptyValues = values.filter((value) => value.trim() !== "");
    const valuesTar = inputRefs.current.map((ref) => ref?.value || "");
    const nonEmptyValuess = valuesTar.filter((value) => value.trim() !== "");

    if (nonEmptyValuess.length === 6) {
      setbtnstate(false);
      const concatenatedString = nonEmptyValuess.join("");
      setOtpValue(concatenatedString);
    } else {
      setbtnstate(true);
      setOtpValue("");
    }

    const maxLength = inputRefs.current[index]?.maxLength || 0;

    if (event.keyCode === 8 && event.currentTarget.value === "" && index > 0) {
      // Backspace key pressed and current input is empty
      const previousIndex = index - 1;
      if (inputRefs.current[previousIndex]) {
        inputRefs.current[previousIndex]?.focus();
      }
    } else if (event.currentTarget.value.length === maxLength) {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex]?.focus();
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text/plain");
    const otpDigits = pastedData.trim().slice(0, 6).split("");

    if (otpDigits.length === 6) {
      setbtnstate(false);
      const concatenatedString = otpDigits.join("");
      setOtpValue(concatenatedString);
    } else {
      setbtnstate(true);
      setOtpValue("");
    }
    
    otpDigits.forEach((digit, index) => {
      if (inputRefs.current && inputRefs.current[index]) {
        const inputRef = inputRefs.current[index];
        if (inputRef) {
          inputRef.value = digit;
          handleKeyUp(index, { keyCode: 0, currentTarget: inputRef } as React.KeyboardEvent<HTMLInputElement>);
        }
      }
    });
  };

  const cancelOtp = () => {
    navigate("/auth", { replace: true });
  }
  const validationSchema = Yup.object().shape({
    otp1: Yup.string().matches(/^\d$/, "OTP must be a digit").required("OTP is required"),
    otp2: Yup.string().matches(/^\d$/, "OTP must be a digit").required("OTP is required"),
    otp3: Yup.string().matches(/^\d$/, "OTP must be a digit").required("OTP is required"),
    otp4: Yup.string().matches(/^\d$/, "OTP must be a digit").required("OTP is required"),
    otp5: Yup.string().matches(/^\d$/, "OTP must be a digit").required("OTP is required"),
    otp6: Yup.string().matches(/^\d$/, "OTP must be a digit").required("OTP is required"),
  });
  
  const formik = useFormik({
    initialValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      verifyFunction();
    },
  });
  
  const verifyFunction = () => {
    setLoading(true);
    if (email && otpValue) {
      verifyChefEmail(email, otpValue).then((res) => {
        console.log("res========", res);
        localStorage.removeItem("chefemail");
        if (res?.status === true) {
          toast.success(res?.message, { autoClose: 6000 });
          navigate("/otp-success", { replace: true });
          setShowAlert(false);
        } else {
          setErrorMessage(res?.message);
          setShowAlert(true);
          toast.error(res?.message);
          navigate("/otp-verification", { replace: true });
        }
        setLoading(false);
      });
    } else {
      setErrorMessage("OTP is required");
    }
  };
  
  useEffect(() => {
    const SettedEmail = localStorage.getItem("chefemail");

    if (
      !SettedEmail ||
      SettedEmail === "undefined" ||
      SettedEmail === undefined ||
      SettedEmail === null
    ) {
      navigate("/otpVerifyError");
    }
  }, []);
  
  const resendOtp = () => {
    const data = { email: email };
    sendEmailOtp(data).then((res) => { 
      if (res?.status === true) {
        toast.success(res?.message, { autoClose: 6000 });
       
      } else {
        toast.error(res?.message, { autoClose: 6000 });
        
      }     
      setErrorMessage(res?.message);
      setShowAlert(true);
      setExpiredIn(20);
      setTimerRunning(true);
    }).catch((e) => {
      console.log("error in sms status handling", e);
    });
    setOtpValue("");
  }
  
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (expiredIn === 0) {
        setTimerRunning(false);
        clearInterval(timerInterval);
      } else {
        setExpiredIn((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [expiredIn]);
  
  return (
    <div className="container-fluid signup-sec py-3">
    <div className="row">
      <div className="col-xl-5 col-md-5 d-none d-lg-block ">
        <div className="signup-img">
          <img
            src="./assets/images/signup-grid.png"
            className="img-fluid"
            alt="img-grid"
          />
        </div>
      </div>
      <div className="col-xl-7 col-lg-7 ">
        <div className="right-form verification-content px-4 d-flex justify-content-between flex-column vh-100">
          <div className="logo-wrap mb-5">
            <img
              src="./assets/images/logo.svg"
              className="img-fluid"
              alt="logo"
            />
          </div>
{/*          
          {showAlert ? (
            // <div className="mb-lg-15 alert alert-danger">
            <div className={`mb-lg-10 alert ${ showAlert ? 'alert-success' : 'alert-danger'}`}>
              <div className="alert-text font-weight-bold">
                {errorMessage}
                <button
                  className="btn p-0 close d-inline-end"
                  onClick={handleClose}
                >
                  <i className="material-symbols-outlined fs-18  px-10">
                    close
                  </i>
                </button>
              </div>
            </div>
          ) : null} */}
          <div className="content-sec">
            <div className="row mb-4">
              <div className="col-xl-7">
                <p className="fw-bold fs-6">Verification</p>
                <p className="small-font mb-5 opacity-80">
                  We have sent the verification{" "}
                  <span className="d-block">
                    code to your registered email ({email})
                  </span>
                </p>

                <div className="d-flex gap-2 gap-md-3 verification-input-wrap ">
                  <input
                    type="text"
                    className="form-control verification-input"
                    ref={(el) => (inputRefs.current[0] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(0, event)}
                    onPaste={handlePaste}
                  />
                  <input
                    type="text"
                    className="form-control verification-input"
                    ref={(el) => (inputRefs.current[1] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(1, event)}
                    onPaste={handlePaste}
                  />
                  <input
                    type="text"
                    className="form-control verification-input"
                    ref={(el) => (inputRefs.current[2] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(2, event)}
                    onPaste={handlePaste}
                  />
                  <input
                    type="text"
                    className="form-control verification-input"
                    ref={(el) => (inputRefs.current[3] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(3, event)}
                    onPaste={handlePaste}
                  />
                  <input
                    type="text"
                    className="form-control verification-input"
                    ref={(el) => (inputRefs.current[4] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(4, event)}
                    onPaste={handlePaste}
                  />
                  <input
                    type="text"
                    className="form-control verification-input"
                    ref={(el) => (inputRefs.current[5] = el)}
                    maxLength={1}
                    onKeyUp={(event) => handleKeyUp(5, event)}
                    onPaste={handlePaste}
                  />
                </div>
                <div className="mt-4">
                <span className="text-start small-font me-1" > Expired In {expiredIn} sec </span>
                {expiredIn === 0 && (
                  <span className="text-start small-font me-1">|&nbsp;
                    <a href="#" className="link" onClick={resendOtp}>
                      Resend
                    </a>
                  </span>
                )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="d-flex justify-content-end ">
              <div className="d-flex gap-3">
                <button className="btn outline-btn px-5" onClick={cancelOtp}>Cancel</button>
                <button
                  className="btn primary-btn px-5"
                  disabled={btnstate}
                  onClick={verifyFunction}
                >
                  {!loading && (
                    <span className="indicator-label">Verify</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
export { Otpverification };
