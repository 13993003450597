import  { useEffect, useState } from "react";
import "../../../assets/custom-style.css";

import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";

import { getOrderStatus, getUser, getHistory } from "../core/_requests";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UUID } from "crypto";
interface History {
  primaryId: any;
  orderId: string;
  orderDate: string;
  user:{
    userName: string;
  };
  orderstatus: {
    orderStatus: string;
  };
  netValue: number;
}

interface UserList {
  uuid: UUID;
  userName: string;
}


interface OrderStatusList {
  id: string | number | readonly string[] | undefined;
  uuid: UUID;
  name: string;
}

const OrderHistory = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [listHistory, setlistHistory] = useState<History[]>([]);
  const [user, setUser] = useState<UserList[]>([]);
  const [orderStatus, setOrderStatus] = useState<OrderStatusList[]>([]);
  const [userId, setUserId] = useState("");
  const [orderStatusId, setOrderStatusId] = useState("");
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const { order } = useParams();
  const decodedOrder = decodeURIComponent( order || "");
   //pagination
   const [currentPage, setCurrentPage] = useState(1);
   const itemsPerPage = 10;
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const dataSearch = searchParams.get('search');
   const chefId =currentUser?.uuid
   const data = {
    search: dataSearch,
    orderstatusId: orderStatusId,
    userId: userId,
  };
  console.log("data",data)
  useEffect(() => {
 
    if (dataSearch !== undefined) {
      console.log("filterData", data);
      setSearch(search);
      getHistory(chefId,data)
        .then((res) => {
          console.log("setlistHistory-res?.data-", res?.data);
          setlistHistory(res?.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching dishes:", error);
          setLoading(false);
        });
    }
  },[decodedOrder])

  useEffect(() => {
    getUser()
      .then((res) => {
        console.log("user",res?.data?.data);
        setUser(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching financial year:", error);
      });
  }, []);

  useEffect(() => {
    getOrderStatus()
      .then((res) => {
        setOrderStatus(res?.data?.data);
        console.log("data123",orderStatus);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  const clickApply = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setCurrentPage(1);
   const data = {
    search: search,
    orderstatusId: orderStatusId,
    userId: userId,
  };
    try {
       await getHistory(chefId, data).then((res) => {
        setlistHistory(res?.data);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching coupon:", error);
      setLoading(false);
    }
   
  };
  
  const clickCancel = () => {
    navigate("/coupon");
  };
 
  
  //pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listHistory.slice(indexOfFirstItem, indexOfLastItem);
  const pages = Math.ceil(listHistory.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage;

    let pageNumberContent;

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <a
            className={`page-link cursorPointer ${
              number === currentPage ? "active" : ""
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className="page-item">
          <a
            className="page-link cursorPointer"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    }

    return pageNumberContent;
  });

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          {/* <Sidebar />   */}
          <Header />
          <section className="content-section">
            {/* <Header /> */}
            <section className="row main-content">
              <div className="title mb-3">
                <h3 className="mb-0">History ({listHistory?.length})</h3>
              </div>
            <div className="col-12 title-btm">
              <div className="search">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="material-symbols-outlined">search</i>
                  </span>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="OrderId..."
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyUp={clickApply}
                    />
                </div>
              </div>
                
            </div>
            <div className="filter-sec mt-2 mt-md-4">
                <form>
                  <div className="row gx-3">
                    <div className="col-md-4">
                      <label className="form-label">Order Status</label>
                      <select
                        className="form-select form-control mb-0"
                        onChange={(e) => setOrderStatusId(e.target.value)}
                      >
                        <option value="">All</option>
                        {orderStatus.map((item, i) => {
                          return (
                            <option value={item?.id} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">User</label>
                      <select
                        className="form-select form-control mb-0"
                        onChange={(e) => setUserId(e.target.value)}
                      >
                        <option value="">All</option>
                        {user.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.userName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-lg-end justify-content-between pt-md-4 pt-3 filter-btn-sec">
                      <button
                        className="btn btn-cancel rounded-5 small me-2 mt-1"
                        onClick={clickCancel}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-primary rounded-5 small mt-1"
                        onClick={clickApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="table-wrap mt-4">
                <table className="table custom-table">
                  <thead className="">
                    <tr>
                      <th>S.No</th>
                      <th>Order Id</th>
                      <th>User Name</th>
                      <th>Order Status</th>
                      <th>Date</th>
                      <th>Amount</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    
                  {currentItems.length > 0 ? (
                    currentItems.map((item, i) => (
                        <tr key={i}>
                          <td>
                             <p>{1+i}</p>
                          </td>
                          <td>
                            <div className="dish">
                              <p className="ms-1 mb-0">{item?.orderId}</p>
                            </div>
                          </td>
                          <td>
                             <p>{item?.user?.userName} </p>
                          </td>
                          <td>
                            <p className="mb-0">{item?.orderstatus?.orderStatus}</p>
                          </td>
                         
                          <td>
                          <p className="mb-0">{item?.orderDate}</p>
                          </td>
                          <td> {item?.netValue.toFixed(2)}</td>
                          
                          {/* <td>
                            <div className="action-item">
                              <a 
                            //   onClick={() => viewDish(item?.primaryId)}
                              >
                                <span className="material-symbols-outlined cursorPointer">
                                  info
                                </span>
                              </a>
                            </div>
                          </td> */}
                        </tr>
                     ))
                     ) : loading ? (
                       <tr>
                         <td colSpan={8} className="text-center">
                           Loading...{" "}
                         </td>
                       </tr>
                     ) : (
                       <tr>
                         <td colSpan={8} className="text-center">
                           No data
                         </td>
                       </tr>
                     )}
                  </tbody>
                </table>
              </div>

              {currentItems.length > 0 && (
                <nav className="pagination-wrap mb-4">
                  <p className="mb-0">
                    Showing {indexOfFirstItem + 1} to{" "}
                    {Math.min(indexOfLastItem, listHistory.length)} of{" "}
                    {listHistory.length} items
                  </p>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(1)}
                      >
                        <i className="material-symbols-outlined">first_page</i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={prevPage}
                        aria-label="Previous"
                      >
                        <i className="material-symbols-outlined">
                          navigate_before
                        </i>
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        <i className="material-symbols-outlined">
                          navigate_next
                        </i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(pages)}
                      >
                        <i className="material-symbols-outlined">last_page</i>
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </section>
          </section>
        </div>
      </section>
      
    </>
  );
};

export default OrderHistory;
