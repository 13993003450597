import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendMobileOtp, verifyChefMobile } from "../core/_requests";
import { toast } from "react-toastify";

const MobileOtpVerification = () => {
  const [btnstate, setbtnstate] = useState(true);
  const [otpValue, setOtpValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleClose = () => {
    setShowAlert(false);
  };
  const [expiredIn, setExpiredIn] = useState(60);
  const [timerRunning, setTimerRunning] = useState(true);
  const [mobileNumber, setMobileNumber] = useState(localStorage.getItem("mobileNumber"));
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const handleKeyUp = (index: any, event: any) => {
    const values = otp.slice();
    const nonEmptyValues = values.filter((value) => value.trim() !== "");
    const valuesTar = inputRefs.current.map((ref: any) => ref.value);
    const nonEmptyValuess = valuesTar.filter((value) => value.trim() !== "");
    if (nonEmptyValuess.length === 6) {
      setbtnstate(false);
      const numbers = nonEmptyValuess.map((element) => parseInt(element, 10));
      const concatenatedString = numbers.join("");
      setOtpValue(concatenatedString);
    } else {
      setbtnstate(true);
      setOtpValue("");
    }
    const maxLength = inputRefs.current[index]?.maxLength || 0;
    if (event.keyCode === 8 && event.target.value === "" && index > 0) {
      // Backspace key pressed and current input is empty
      const previousIndex = index - 1;
      if (inputRefs.current[previousIndex]) {
        inputRefs.current[previousIndex]?.focus();
      }
    } else if (event.target.value.length === maxLength) {
      // Next input field focus logic (same as before)
      const nextIndex = index + 1;
      if (
        nextIndex < inputRefs.current.length &&
        inputRefs.current[nextIndex]
      ) {
        inputRefs.current[nextIndex]?.focus();
      }
    }
  };

  const handlePaste = (event: any) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text/plain");
    const otpDigits = pastedData.trim().slice(0, 6).split("");
    if (otpDigits.length === 6) {
      setbtnstate(false);
      const numbers = otpDigits.map((element: any) => parseInt(element, 10));
      const concatenatedString = numbers.join("");
      setOtpValue(concatenatedString);
    } else {
      setbtnstate(true);
      setOtpValue("");
    }
    // Distribute the OTP digits to the corresponding input boxes
    otpDigits.forEach((digit: any, index: any) => {
      if (
        inputRefs.current &&
        inputRefs.current[index] &&
        inputRefs.current[index]?.maxLength !== undefined
      ) {
        const inputRef = inputRefs.current[index];
        if (inputRef) {
          inputRef.value = digit;
          handleKeyUp(index, { target: inputRef });
        }
      }
    });
  };

  const cancelOtp =()=>{
    navigate("/auth", { replace: true });
  }
  
  const verifyFunction = () => {
    setLoading(true);
    console.log("vaa", mobileNumber, otpValue);
    if (mobileNumber && otpValue) {
        verifyChefMobile(mobileNumber, otpValue).then((res) => {
        console.log("res========", res);
        //setIsFormSubmitted(true);
        localStorage.removeItem("mobileNumber");
  
        if (res?.status === true) {
          toast.success(res?.message, { autoClose: 6000 });
          navigate("/otp-success", { replace: true });
          localStorage.removeItem("mobileNumber");
          setShowAlert(false);
        } else {
          toast.error(res?.message, { autoClose: 6000 });
          setErrorMessage(res?.message);
          setShowAlert(true);
          navigate("/otp-verification-mobile", { replace: true });
        }
        setLoading(false);
      });
    } else {
      setErrorMessage("OTP is required");
    }
  };
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (expiredIn === 0) {
        setTimerRunning(false);
        clearInterval(timerInterval);
      } else {
        setExpiredIn((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [expiredIn]);
  useEffect(() => {
    const SettedMobile = localStorage.getItem("mobileNumber");
    if (
      !SettedMobile ||
      SettedMobile === "undefined" ||
      SettedMobile === undefined ||
      SettedMobile === null
    ) {
      navigate("/otpVerifyError");
    }
  }, []);

  const resendOtp=()=>{
   const data={
    mobileNumber:mobileNumber
   }
   sendMobileOtp(data).then((res:any) => {
    if (res?.status === true) {
      toast.success(res?.message, { autoClose: 6000 });
     
    } else {
      toast.error(res?.message, { autoClose: 6000 });
      
    }
    setErrorMessage(res?.message);
    setShowAlert(true);
    setExpiredIn(20);
    setTimerRunning(true);
    }).catch((e:any)=>{
   
      console.log("error in sms status handleing",e)
    })
  }

  return (
    <div className="container-fluid signup-sec py-3">
      <div className="row">
        <div className="col-xl-5 col-md-5 d-none d-lg-block ">
          <div className="signup-img">
            <img
              src="./assets/images/signup-grid.png"
              className="img-fluid"
              alt="img-grid"
            />
          </div>
        </div>
        <div className="col-xl-7 col-lg-7 ">
          <div className="right-form verification-content px-4 d-flex justify-content-between flex-column ">
            <div className="logo-wrap">
              <img
                src="./assets/images/logo.svg"
                className="img-fluid"
                alt="logo"
              />
            </div>
{/*            
            {showAlert ? (
              // <div className="mb-lg-15 alert alert-danger">
              <div className={`mb-lg-10 alert ${showAlert ? 'alert-success' : 'alert-danger'}`}>
                <div className="alert-text font-weight-bold">
                  {errorMessage}
                  <button
                    className="btn p-0 close d-inline-end"
                    onClick={handleClose}
                  >
                    <i className="material-symbols-outlined fs-18  px-10">
                      close
                    </i>
                  </button>
                </div>
              </div>
            ) : null} */}
            <div className="content-sec">
              <div className="row mb-4">
                <div className="col-xl-7">
                  <p className="fw-bold fs-6">Verification</p>
                  <p className="small-font mb-5 opacity-80">
                    We have sent the verification{" "}
                    <span className="d-block">
                      code to your registered mobileNumber ({mobileNumber})
                    </span>
                  </p>

                  <div className="d-flex gap-3 verification-input-wrap ">
                    <input
                      type="text"
                      className="form-control verification-input"
                      ref={(el) => (inputRefs.current[0] = el)}
                      maxLength={1}
                      onKeyUp={(event) => handleKeyUp(0, event)}
                      onPaste={handlePaste}
                    />
                    <input
                      type="text"
                      className="form-control verification-input"
                      ref={(el) => (inputRefs.current[1] = el)}
                      maxLength={1}
                      onKeyUp={(event) => handleKeyUp(1, event)}
                      onPaste={handlePaste}
                    />
                    <input
                      type="text"
                      className="form-control verification-input"
                      ref={(el) => (inputRefs.current[2] = el)}
                      maxLength={1}
                      onKeyUp={(event) => handleKeyUp(2, event)}
                      onPaste={handlePaste}
                    />
                    <input
                      type="text"
                      className="form-control verification-input"
                      ref={(el) => (inputRefs.current[3] = el)}
                      maxLength={1}
                      onKeyUp={(event) => handleKeyUp(3, event)}
                      onPaste={handlePaste}
                    />
                    <input
                      type="text"
                      className="form-control verification-input"
                      ref={(el) => (inputRefs.current[4] = el)}
                      maxLength={1}
                      onKeyUp={(event) => handleKeyUp(4, event)}
                      onPaste={handlePaste}
                    />
                    <input
                      type="text"
                      className="form-control verification-input"
                      ref={(el) => (inputRefs.current[5] = el)}
                      maxLength={1}
                      onKeyUp={(event) => handleKeyUp(5, event)}
                      onPaste={handlePaste}
                    />
                  </div>
                  {/* <p className="text-start small-font mt-4">
                    Didn’t receive code?{" "}
                    <a href="#" className="link" onClick={resendOtp}>
                      Resend
                    </a>
                  </p> */}
                    <div className="mt-4">
                <span className="text-start small-font me-1" > Expired In {expiredIn} sec </span>
                {expiredIn === 0 && (
                  <span className="text-start small-font me-1">|&nbsp;
                    <a href="#" className="link" onClick={resendOtp}>
                      Resend
                    </a>
                  </span>
                )}
                </div>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="d-flex justify-content-end ">
                <div className="d-flex gap-3">
                  <button className="btn outline-btn px-5" onClick={cancelOtp}>Cancel</button>
                  <button
                    className="btn primary-btn px-5"
                    disabled={btnstate}
                    onClick={verifyFunction}
                  >
                    {!loading && (
                      <span className="indicator-label">Verify</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export {MobileOtpVerification };
