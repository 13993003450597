import { useEffect, useState } from "react";
import "../../../assets/custom-style.css";

import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";
import "../../../assets/custom-style.scss";
import {
  listBankData,
  listChefSettlementDetail,
  listWalletAmount,
} from "../core/_requests";

import { Link, useNavigate } from "react-router-dom";
import { UUID } from "crypto";
interface Bank {
  primaryId: any;
  chefId: string | undefined;
  bankId: string | undefined;
  routingNumber: string;
  accountName: string;
  accountNumber: string;
  phoneNumber: string;
  address: string;
  createdBy: any;
  updatedBy: any;
}

const ListSettlements = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [listSettlements, setListSettlements] = useState<Bank[]>([]);
  const [bank, setListBank] = useState<any>();
  const [walletAmount, setWalletAmount] = useState<any>();
  const [wallet, setWallet] = useState<any[]>([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const chefId =currentUser?.uuid
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filterData = {
    search: search,
  };

  useEffect(() => {
    listChefSettlementDetail(chefId).then((res) => {
      setWallet(res?.data); // Set wallet state with data from the response or an empty array
    });
  }, []);

  useEffect(() => {
    listBankData(chefId).then((res) => {
      setListSettlements(res?.data);
      console.log("setListSettlements", res);

      setListBank(res);
    });
  }, []);
  useEffect(() => {
    listWalletAmount(chefId).then((res) => {
      setWalletAmount(res?.data);
      console.log("setListSettlements", res.data);
    });
  }, []);

  const EditSettlements = (primaryId: string) => {
    navigate("/settlements/edit/" + primaryId);
  };

  const deleteSettlements = (primaryId: string) => {
    navigate("/settlements/delete/" + primaryId);
  };

  const clickApply = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setCurrentPage(1);
    const data = {
      search: search,
    };
    try {
      const res = await listBankData(chefId).then((res) => {
        setListSettlements(res?.data);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching Settlements:", error);
      setLoading(false);
    }
  };

  //pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listSettlements.slice(indexOfFirstItem, indexOfLastItem);
  const pages = Math.ceil(listSettlements.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage;

    let pageNumberContent;

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <a
            className={`page-link cursorPointer ${
              number === currentPage ? "active" : ""
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className="page-item">
          <a
            className="page-link cursorPointer"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    }

    return pageNumberContent;
  });

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const listTransaction = () => {
    navigate("/settlements/all-transaction");
  };
  const clickAllSettlements = () => {
    navigate("/settlements/all-settlement");
  };
  return (
    <>
      <section className="container-fluid">
        <div className="row">
          {/* <Sidebar />   */}
          <Header />
          <section className="content-section">
            {/* <Header /> */}
            <section className="row main-content settlement-content">
              <div className="title mb-3 d-flex align-items-center justify-content-between">
                <h3 className="mb-0">Settlement</h3>
                <button
                  className="btn btn-primary btn-ico rounded-5 btn-empty small"
                  onClick={listTransaction}
                >
                  <i className="material-symbols-outlined">history</i>
                  <span>Transaction History</span>
                </button>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-md-8">
                    <div className="wallet-snippet">
                      <div className="amount-text">
                        <p>
                          <small className="text-white">
                            Total amount in your wallet
                          </small>
                        </p>

                        {walletAmount && (
                          <span className="amount-text">
                            {" "}
                            $ {walletAmount.balance}
                          </span>
                        )}

                        <p className="mt-4 mb-0 opacity-75 d-flex align-items-center">
                          <span className="tiny text-white">
                            Your total amount and withdrawal amount are not same
                          </span>
                          <i
                            className="ms-1 material-symbols-outlined info-ico"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Withdrwable balance (=) Total Amount (-) Deposit Amount"
                          >
                            info
                          </i>
                        </p>
                      </div>
                      <span></span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row h-100">
                      <div className="col-12 mb-4">
                        <div className="snippet-block h-100 d-flex align-items-center justify-content-between">
                          <div className="cont d-flex align-items-center">
                            <img
                              className="img-fluid me-3"
                              src="../../../assets/images/settlement-mode-ico.svg"
                              alt="ico"
                            />
                            <div>
                              <p className="mid-gray mb-0">
                                <small>Settlement Mode</small>
                              </p>
                              <h6 className="mb-0 fw-med">Auto</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      {bank?.data?.length === 0 ? (
                        <div className="col-12">
                          <div className="snippet-block h-100 empty">
                            <Link to="/settlements/add" className="add-bank">
                              <i className="material-symbols-outlined me-1">
                                account_balance
                              </i>
                              Add your bank account here
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12">
                          {bank?.data?.map((bankItem: any, index: any) => (
                            <div key={index} className="snippet-block h-100">
                              <div className="cont d-flex align-items-center justify-content-between">
                                <div>
                                  <p className="mb-0 mid-gray">
                                    <small>{bankItem.accountName}</small>
                                  </p>
                                  <h6 className="fw-med mb-2">
                                    {bankItem.accountNumber}
                                  </h6>
                                  <p className="mb-0 mid-gray">
                                    <small>
                                      Routing No.: {bankItem.routingNumber}
                                    </small>
                                  </p>
                                </div>
                                <a
                                  className="btn btn-primary btn-ico btn-empty change-btn"
                                  onClick={() =>
                                    EditSettlements(bankItem.primaryId)
                                  }
                                >
                                  <span className="material-symbols-outlined">
                                    edit
                                  </span>
                                </a>
                                <a
                                  className="btn btn-primary btn-ico btn-empty change-btn"
                                  onClick={() =>
                                    deleteSettlements(bankItem.primaryId)
                                  }
                                >
                                  <span className="material-symbols-outlined">
                                    delete
                                  </span>
                                </a>
                                {/* <div className="logo-wrap">
                                  <img
                                    className="img-fluid"
                                    src="../../../assets/images/citi-logo.png"
                                    alt="Bank Name"
                                  />
                                </div> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-12 mt-4 mb-4">
                    <div className="snippet-block">
                      <div className="head">
                        <h6 className="mb-0">Recent Settlements</h6>
                        <a
                          href="javascript:;"
                          className="text-link"
                          onClick={clickAllSettlements}
                        >
                          View All
                          <i className="material-symbols-outlined fall">
                            arrow_forward
                          </i>
                        </a>
                      </div>
                      {/* Check if wallet array is empty */}

                      {wallet.length === 0 ? (
                        <div className="snippet-content">
                          <div className="no-data">
                            <img
                              className="img-fluid mb-3"
                              src="../assets/images/no-data-img.svg"
                              alt="No Data"
                            />
                            <p className="mb-0 mid-gray">
                              <small>Still you don’t get any settlements</small>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="snippet-content">
                          <table className="table custom-table mb-0">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Settlement Id</th>
                                <th>Date</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Use slice to get only the first 5 elements of the wallet array */}
                              {wallet
                                .slice(0, 5)
                                .map((walletDetail: any, index: any) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{walletDetail.settlementId}</td>
                                    <td>{walletDetail.date}</td>
                                    <td>{walletDetail.amount}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export default ListSettlements;
