import React from "react";
import axios from "axios";
import { getToken } from "../../../auth";

//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;

const getReports = async (ChefId: any,filterData:any) => {
  const response = await axios
    .post(`${API_URL}chefPortal/listReports/${ChefId}`,filterData);
    console.log("getReports-apii",response.data)
  return response.data;
};
const getCategory = async () => {
  const res = await axios.get(
    `${API_URL}chefPortal/listCategory`,getToken()
  );
  return res;
};

const getCuisine = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getCuisine`,getToken());
  return res;
};

const getType = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getType`, getToken());
  return res;
};

const getDish = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getDish`, getToken());
  return res;
};
const getOrderStatus = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getOrderStatus`,getToken());
  return res;
};
const getUser = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getUser/`, getToken());
  return res;
};

export {
  getReports,
  getCategory,
  getCuisine,
  getType,getDish,getOrderStatus,getUser,
  
};
