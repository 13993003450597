import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import "../../../assets/custom-style.css";

import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";
import clsx from "clsx";
import Select from "react-select"; // Import Select from react-select

import {
  getCategory,
  getCuisine,
  getReports,
  getType,
  getDish,
  getUser,
  getOrderStatus,
} from "../core/_requests";
import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";
import { useDownloadExcel } from "react-export-table-to-excel";
interface Orders {
  primaryId: number;
  orderstatusId: number;
  uuid: string;
  orderId: string;
  chefId: string;
  userId: string;
  amount: number;
  tax: number;
  deliveryCharge: number;
  deliveryType: number;
  couponApplied: boolean;
  couponAmount: number | null;
  walletDiscount: number | null;
  deliveryPersonTips: number;
  chefTips: number;
  paymentMethod: string;
  netValue: number;
  orderDate: string;
  orderTime: string;
  totalAmount: number;
  orderstatus: {
    name: string;
  };
  chef: {
    name: string;
    currentlyAvailableStatus: boolean;
    email: string;
    phoneNumber: string | null;
    phoneNumber1: string | null;
    mobileNumber: string;
    mobileNumber1: string | null;
    location: string | null;
    licence: string | null;
    chefProfile: string | null;
    approvalStatus: boolean;
    address1: string;

    city: string;
    state: string;
    country: string;
    zipCode: string;
    status: number;
    enableDisableStatus: boolean;
  };
  orderdetails: [
    {
      id: number;
      orderId: string;
      dishId: string;
      quantity: number;
      amount: number;
      status: number;
      createdAt: string;
      updatedAt: string;
      dish: {
        name: string;
        dishImage: string;
        price: number;
        cuisine: {
          name: string;
        };
        category: {
          name: string;
        };
        type: {
          name: string;
        };
        addons: [
          {
            id: number;
            name: string;
            quantity: number;
            price: number;
          }
        ];
      };
    }
  ];
  user: {
    id: number;
    uuid: string;
    userProfile: string;
    deviceId: string | null;
    token: string;
    verifyStatus: string | null;
    deviceType: string | null;
    deviceToken: string | null;
    loginType: string | null;
    name: string;
    email: string;
    latitude: string | null;
    longitude: string | null;
    contactNumber: string;
    plansId: string | null;
    planStartDate: string | null;
    planEndDate: string | null;
    planStatus: string | null;
    status: number;
    createdBy: string | null;
    updatedBy: string | null;
    createdAt: string;
    updatedAt: string;

    addressbooks: [
      {
        label: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        country: string;
        zipcode: any;
      }
    ];
  };
}
interface OrderStatusList {
  id: string | number | readonly string[] | undefined;
  uuid: UUID;
  name: string;
}

interface User {
  uuid: UUID;
  userName: string;
}
interface Type {
  uuid: UUID;
  name: string;
}
interface Dish {
  uuid: UUID;
  name: string;
}
interface Category {
  uuid: UUID;
  name: string;
}
interface Cuisine {
  uuid: UUID;
  name: string;
}
const ListReport = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [listReport, setReport] = useState<Orders[]>([]);
  const [orderStatus, setOrderStatus] = useState<OrderStatusList[]>([]);
  const [dish, setDish] = useState<Dish[]>([]);
  const [user, setUser] = useState<User[]>([]);
  const [cuisine, setCuisine] = useState<Cuisine[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [type, setType] = useState<Type[]>([]);
  const [cuisineId, setCuisineId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [dishId, setDishId] = useState("");
  const [userId, setUserId] = useState("");
  const [orderStatusId, setOrderStatusId] = useState("");
  const [search, setSearch] = useState("");
  const [selectedTypes, setSelectedTypes] = useState<Type[]>([]);
  // Removed the default values for fromDate and toDate
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  // const [fromDate, setFromDate] = useState(
  //   new Date().toISOString().split("T")[0]
  // );
  // const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const tableRef = useRef(null);
  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(e.target.value);
  };
  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(e.target.value);
  };
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filterData = {
    search: search,
    dishId: dishId,
    cuisineId: cuisineId,
    categoryId: categoryId,
    typeId: selectedTypes,
    orderstatusId: orderStatusId,
    userId: userId,

  };
  useEffect(() => {
    getReports(chefId, filterData).then((res) => {
      console.log("getReports-filterData", res?.data);
      setReport(res?.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getDish()
      .then((res) => {
        setDish(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    getCategory()
      .then((res) => {
        setCategory(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    getCuisine()
      .then((res) => {
        setCuisine(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    getUser()
      .then((res) => {
        setUser(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    getType()
      .then((res) => {
        setType(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  useEffect(() => {
    getOrderStatus()
      .then((res) => {
        setOrderStatus(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  const handleCheckboxChange = (value: any) => {
    setSelectedTypes((prevSelectedTypes) =>
      prevSelectedTypes.includes(value)
        ? prevSelectedTypes.filter((type) => type !== value)
        : [...prevSelectedTypes, value]
    );
  };

  const clickApply = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setCurrentPage(1);
    const data = {
      search: search,
      orderstatusId: orderStatusId,
      userId: userId,
      dishId: dishId,
      cuisineId: cuisineId,
      categoryId: categoryId,
      typeId: selectedTypes,
    };
    try {
      await getReports(chefId, data).then((res) => {
        setReport(res?.data);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching coupon:", error);
      setLoading(false);
    }

  };


  const clickCancel = () => {
    navigate("/report");
  };
  const chefId = currentUser?.uuid;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listReport.slice(indexOfFirstItem, indexOfLastItem);
  const pages = Math.ceil(listReport.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage;

    let pageNumberContent;

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <a
            className={`page-link cursorPointer ${number === currentPage ? "active" : ""
              }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className="page-item">
          <a
            className="page-link cursorPointer"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    }

    return pageNumberContent;
  });

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  var DatefileName = new Date();
  var fileName =
    DatefileName.getFullYear() +
    "-" +
    (DatefileName.getMonth() + 1) +
    "-" +
    DatefileName.getDate();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `chef_Report_${fileName}`,
    sheet: "Chefs",
  });
  function getStatusColor(name: any) {
    switch (name) {
      case "ordered":
        return "text-primary";
      case "ongoing":
        return "text-danger";
      case "completed":
        return "text-pink";
      case "cancelled":
        return "text-violet";
      case "delivered":
        return "text-black";
      case "returned":
        return "text-warning";
      case "failed":
        return "text-danger";
      default:
        return "";
    }
  }

  return (
    <>
      <section className="container-fluid">
        <div className="row">
           {/* <Sidebar />   */}
           <Header />
          <section className="content-section">
            {/* <Header /> */}
            <section className="row main-content">
              <div className="title mb-3">
                <h3 className="mb-0">Report </h3>
              </div>

              <div className="col-12 title-btm">
                <div className="search">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="material-symbols-outlined">search</i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Order Id, Dish, Category, Cuisine, Type, Contact Number"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyUp={clickApply}
                    />
                  </div>
                </div>
                <div className="col-md-4 d-flex align-items-center justify-content-end pt-4">
                  <button
                    type="submit"
                    className="btn btn-danger rounded-5 large mt-1 clickedbutton"
                    onClick={onDownload}
                  >
                    Export on Excel
                  </button>
                </div>
              </div>

              <div className="filter-sec mt-4">
                <form>
                  <div className="row gx-3">

                    <div className="col-md-2">
                      <label className="form-label">Cuisine</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setCuisineId(e.target.value)}
                      >
                        <option value="">All</option>
                        {cuisine.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">Category</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setCategoryId(e.target.value)}
                      >
                        <option value="">All</option>
                        {category.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">Dish</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setDishId(e.target.value)}
                      >
                        <option value="">All</option>
                        {dish.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-md-2">
                      <label className="form-label">Order Status</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setOrderStatusId(e.target.value)}
                      >
                        <option value="">All</option>
                        {orderStatus.map((item, i) => {
                          return (
                            <option value={item?.id} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Type</label>
                      {type.map((item: any, i) => {
                        return (
                          <div
                            className="form-check form-check-inline me-2 mt-2"
                            key={i}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`inlineCheckbox${i + 1}`}
                              value={item?.uuid}
                              checked={selectedTypes.includes(item?.uuid)}
                              onChange={() => handleCheckboxChange(item?.uuid)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`inlineCheckbox${i + 1}`}
                            >
                              {item?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">User</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setUserId(e.target.value)}
                      >
                        <option value="">All</option>
                        {user.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.userName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">From Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                      />
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">To Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center justify-content-end pt-4">
                      <button
                        className="btn btn-cancel rounded-5 small me-2 mt-1"
                        onClick={clickCancel}
                      >
                        Clear
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary rounded-5 small mt-1 clickedbutton"
                        onClick={clickApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="table-wrap mt-4">
                <table className="table custom-table">
                  <thead>
                    <tr>
                    <th>S.No</th>
                      <th className="text-green">Order Id</th>
                      <th>User Name</th>
                      <th>Dishes</th>
                      <th>Type</th>
                      <th>Category</th>
                      <th>Cuisine</th>
                      <th>Total Amount</th>
                      <th>Rating</th>
                      <th>Order Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((item, i) => (
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td className="text-warnings">{item?.orderId}</td>
                          <td>{item?.user?.name} <br />({item?.user?.contactNumber})</td>
                          <td>
                            {item?.orderdetails.map((detail, j) => (
                              <div key={j} className="dish-details">
                                <p>{detail?.dish?.name} * {detail?.quantity}</p>

                              </div>
                            ))}
                          </td>
                          <td>
                            {item?.orderdetails.map((detail, j) => (
                              <div key={j} className="dish-details">


                                <p>{detail?.dish?.type?.name}</p>


                              </div>
                            ))}
                          </td>

                          <td>
                            {item?.orderdetails.map((detail, j) => (
                              <div key={j} className="dish-details">

                                <p>{detail?.dish?.category?.name}</p>

                              </div>
                            ))}
                          </td>
                          <td>
                            {item?.orderdetails.map((detail, j) => (
                              <div key={j} className="dish-details">


                                <p>{detail?.dish?.cuisine?.name}</p>


                              </div>
                            ))}
                          </td>
                          <td>${item?.totalAmount.toFixed(2)}</td>
                          <td>
                            <span className="rating">
                              <img src="../dish_images/star.svg" alt="Rating" />
                              0{/* You may replace 0 with the actual rating */}
                            </span>
                          </td>
                          <td>{item?.orderDate} <br /> {item?.orderTime}</td>
                          <td className={getStatusColor(item?.orderstatus.name)}><b>{item?.orderstatus.name}</b></td>

                        </tr>
                      ))
                    ) : loading ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {currentItems.length > 0 && (
                <nav className="pagination-wrap mb-4">
                  <p className="mb-0">
                    Showing {indexOfFirstItem + 1} to{" "}
                    {Math.min(indexOfLastItem, listReport.length)} of {listReport.length}{" "}
                    items
                  </p>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(1)}
                      >
                        <i className="material-symbols-outlined">first_page</i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={prevPage}
                        aria-label="Previous"
                      >
                        <i className="material-symbols-outlined">
                          navigate_before
                        </i>
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        <i className="material-symbols-outlined">
                          navigate_next
                        </i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(pages)}
                      >
                        <i className="material-symbols-outlined">last_page</i>
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export default ListReport;
