import { useEffect, useState } from "react";
import "../../../assets/custom-style.css";

import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";
import "../../../assets/custom-style.scss";
import { Link, useNavigate } from "react-router-dom";
import { BannerDetails, ProfileDetails, listTiming } from "../core/_requests";
import Avatar from "react-avatar";

const ListSettings = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [listProfile, setListProfile] = useState<any>();
  const [banner, setBannerImage] = useState<any>();
  const [bank, setListBank] = useState<any>();
  const [timing, setTiming] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const chefId =currentUser?.uuid
  console.log("listProfile", listProfile);
  console.log("listProfile:notification_master?.tone", listProfile?.notification_master?.tone);

  const [isAuto, setIsAuto] = useState<boolean>(false); // Track isAuto state

  const [timingType, setTimingType] = useState<boolean>(false); // Track isAuto state

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filterData = {
    search: search,
  };
  useEffect(() => {
    listTiming(chefId)
      .then((res) => {
        setTiming(res);
        setIsAuto(res.data[0]?.isAuto);
        setTimingType(res.data[0]?.timingType);
      })
      .catch((error) => {
        console.error("Error fetching timing data:", error);
      });
  }, []);

  useEffect(() => {
    ProfileDetails(chefId).then((res) => {
      setListProfile(res?.data);
      console.log("setListProfile-data", res?.data);

      setListBank(res);
    });
  }, []);
  useEffect(() => {
    BannerDetails(chefId).then((res) => {
      setBannerImage(res?.data[0]);
      console.log("setBannerImage-data", res?.data);

      setListBank(res);
    });
  }, []);
  const AddProfile = () => {
    navigate("/settings/update-profile");
  };
  const AddProfileDetails = () => {
    navigate("/settings/profile-details");
  };
  const bannerUpdate = () => {
    navigate("/settings/change-banner");
  };
  const changeTiming = () => {
    navigate("/settings/change-timing");
  };
  const changePassword = () => {
    navigate("/settings/change-password");
  };
  const editBrand = () => {
    navigate("/settings/brand-update");
  };
  const editSound = () => {
    navigate("/settings/sound-update");
  };
  const updateBio = () => {
    navigate("/settings/bio-update");
  };
  const clickApply = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setCurrentPage(1);
    const data = {
      search: search,
    };
    try {
      const res = await ProfileDetails(chefId).then((res) => {
        setListProfile(res?.data);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching Settlements:", error);
      setLoading(false);
    }
  };

  const formatTime = (timeString: any) => {
    if (!timeString) return ""; // Return an empty string if timeString is null or undefined
    const [hours, minutes] = timeString.split(":").slice(0, 2); // Extract hours and minutes
    return `${hours}:${minutes}`; // Format as hh:mm
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          {/* <Sidebar />   */}
          <Header />
          <section className="content-section">
            {/* <Header /> */}
            {/* <!-- Main Content Section Starts here --> */}
            <section className="row main-content settlement-content settings-content">
              <div className="title mb-3 d-flex align-items-center">
                <h3 className="mb-0 me-2">My Account</h3>
                <p className="mb-0 opacity-150 mt-2">
                  <i
                    className="material-symbols-outlined info-ico me-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={`Hello ${
                      currentUser?.data?.name || "there"
                    }! Update your restaurant's banner and profile to shine on the user app!`}
                    // title="Hey dude! Update your restaurant's banner and profile to shine on the user app!"
                  >
                    info
                  </i>
                </p>
              </div>

              {/* <!-- Content Wrap Starts here --> */}
              <div className="col-12">
                <div className="row mb-4">
                  <div className="col-md-8">
                    <div className="snippet-block p-0">
                      <div className="cover-photo">
                        {/* <img src={listProfile?.profile} alt="Cover Photo" /> */}
                        {banner?.image ? (
                          <img
                            src={banner?.image}
                            alt="Cover Photo"
                            width="620px"
                            height="210px"
                          />
                        ) : (
                          <img
                            src="../../../assets/images/cover-placeholder.svg"
                            alt="Cover Photo"
                          />
                        )}
                        <button
                          className="btn btn-rounded btn-edit"
                          onClick={bannerUpdate}
                        >
                          <i className="material-symbols-outlined">edit</i>
                          Edit Cover
                        </button>
                      </div>

                      <div className="cover-cont">
                        <div className="profile-pic-wrap">
                          <div className="pic-wrap">
                            <span className="txt">
                              {" "}
                              {listProfile?.profile ? (
                                <Avatar
                                  src={listProfile?.profile}
                                  name={currentUser?.data?.name}
                                  maxInitials={1}
                                  size="125"
                                  round
                                ></Avatar>
                              ) : (
                                <Avatar
                                  name={listProfile?.name}
                                  maxInitials={1}
                                  size="125"
                                  round
                                  color="#607458"
                                  fgColor="#ffffff"
                                ></Avatar>
                              )}
                            </span>
                          </div>
                          <button
                            className="btn btn-primary btn-circle"
                            onClick={AddProfile}
                          >
                            <i className="material-symbols-outlined">
                              add_photo_alternate
                            </i>
                          </button>
                        </div>

                        <div className="personal-detail mt-4 mb-3">
                          <div>
                            <h3 className="fw-med">{listProfile?.name}</h3>
                            <ul className="list-inline mt-3">
                              <li className="list-inline-item">
                                <i className="material-symbols-outlined call">
                                  call
                                </i>
                                {listProfile?.mobileNumber}
                              </li>
                              <li className="list-inline-item">
                                <i className="material-symbols-outlined">
                                  mail
                                </i>
                                {listProfile?.email}
                              </li>
                              <li className="list-inline-item w-100 mt-1">
                                <i className="material-symbols-outlined">
                                  location_on
                                </i>
                                {listProfile?.address}
                              </li>
                            </ul>
                          </div>
                          <a
                            href="javascript:;"
                            className="text-link"
                            onClick={AddProfileDetails}
                          >
                            Edit
                          </a>
                        </div>
                        {listProfile?.chefProfile ? (
                          <div className="short-bio mb-3">
                            <div className="head-line">
                              <h6 className="fw-med mb-0">Short Bio</h6>
                              <a
                                href="javascript:;"
                                className="text-link"
                                onClick={updateBio}
                              >
                                Edit
                              </a>
                            </div>
                            <p>
                              <span className="tiny">
                                {listProfile?.chefProfile}
                              </span>
                            </p>
                          </div>
                        ) : (
                          <div className="short-bio mb-3">
                            <a
                              className="empty-wrap"
                              href="javascript:;"
                              onClick={updateBio}
                            >
                              Give short bio about yourself & cooking...
                              <i className="material-symbols-outlined">edit</i>
                            </a>
                          </div>
                        )}
                    
{listProfile?.notification_master?.tone ? (
  <div className="short-bio mb-3">
    <div className="head-line">
      <h6 className="fw-med mb-0">Notification Tone</h6>
      <a
        href="javascript:;"
        className="text-link"
        onClick={editSound}
      >
        Edit
      </a>
    </div>
    <p>
      <span className="tiny">
        {/* Embed the audio player with the source URL */}
        <audio controls>
          <source
            src={listProfile.notification_master?.tone}
            type="audio/mpeg"
          />
        </audio>
      </span>
    </p>
  </div>
) : (
  <div className="your-cuisine mb-3">
    <a
      className="empty-wrap"
      href="javascript:;"
      onClick={editSound}
    >
      Add your Notification Sound...
      <i
        className="material-symbols-outlined"
        onClick={editSound}
      >
        edit
      </i>
    </a>
  </div>
)}

                        {listProfile?.brandName ? (
                          <div className="your-cuisine mb-3">
                            <div className="head-line">
                              <h6 className="fw-med mb-0">
                                My Brand (Optional)
                              </h6>
                              <a
                                href="javascript:;"
                                className="text-link"
                                onClick={editBrand}
                              >
                                Edit
                              </a>
                            </div>
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item">
                                {listProfile?.brandName}
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <div className="your-cuisine mb-3">
                            <a
                              className="empty-wrap"
                              href="javascript:;"
                              onClick={editBrand}
                            >
                              Add your brand here...
                              <i
                                className="material-symbols-outlined"
                                onClick={editBrand}
                              >
                                edit
                              </i>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="snippet-block">
                          <div className="head">
                            <h6 className="mb-0">
                              Complete Your Profile
                              <small className="mt-1">
                                Create your own identity and stand out from the
                                crowd
                              </small>
                            </h6>
                            <div className="progress-count">
                              {" "}
                              {/* Calculate and display the completion percentage */}
                              {listProfile &&
                              listProfile.profile &&
                              banner &&
                              listProfile.chefProfile &&
                              timing &&
                              timing.data.length > 0
                                ? "100%"
                                : `${
                                    (listProfile && listProfile.profile
                                      ? 30
                                      : 0) +
                                    (banner ? 20 : 0) +
                                    (listProfile && listProfile.chefProfile
                                      ? 20
                                      : 0) +
                                    (timing && timing.data.length > 0 ? 30 : 0)
                                  }%`}
                            </div>
                          </div>
                          <div className="cont">
                            <ul className="list-unstyled progress-list">
                              {/* <li className="done"> */}
                              <li
                                className={
                                  listProfile && listProfile.profile
                                    ? "done"
                                    : ""
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    className="ico"
                                    src="../../../assets/images/circle-tick-filled-ico.svg"
                                    alt="ico"
                                  />
                                  Add profile
                                </div>
                                <span className="level">
                                  {" "}
                                  {listProfile && listProfile.profile ? 30 : 0}%
                                </span>
                              </li>
                              <li className={banner ? "done" : ""}>
                                <div className="d-flex align-items-center">
                                  <img
                                    className="ico"
                                    src="../../../assets/images/circle-tick-filled-ico.svg"
                                    alt="ico"
                                  />
                                  Upload cover photo
                                </div>
                                <span className="level">
                                  {" "}
                                  {banner ? 20 : 0}%
                                </span>
                              </li>
                              <li
                                className={
                                  listProfile && listProfile.chefProfile
                                    ? "done"
                                    : ""
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    className="ico"
                                    src="../../../assets/images/circle-tick-filled-ico.svg"
                                    alt="ico"
                                  />
                                  Add short bio
                                </div>
                                <span className="level">
                                  {listProfile && listProfile.chefProfile
                                    ? 20
                                    : 0}
                                  %
                                </span>
                              </li>

                              <li
                                className={
                                  timing && timing.data.length > 0 ? "done" : ""
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    className="ico"
                                    src="../../../assets/images/circle-tick-filled-ico.svg"
                                    alt="ico"
                                  />
                                  Add business timings
                                </div>

                                <span className="level">
                                  {" "}
                                  {timing && timing.data.length > 0 ? 30 : 0}%
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>{" "}
                      {timing?.data.length === 0 ? (
                        // Render this block when timing.data.length is 0
                        <div className="col-12 mb-4">
                          <div className="snippet-block empty">
                            <a
                              href="javascript:;"
                              className="add-bank py-5"
                              onClick={changeTiming}
                            >
                              <i className="material-symbols-outlined me-2">
                                schedule
                              </i>
                              Add your Business Timings
                            </a>
                          </div>
                        </div>
                      ) : (
                        // Render this block when timing.data has items
                        <div className="col-12 mb-4">
                          <div className="snippet-block">
                            <div className="head">
                              <h6 className="fw-med mb-0">
                                Business Days & Timings
                              </h6>
                              <a
                                href="javascript:;"
                                className="text-link"
                                onClick={changeTiming}
                              >
                                Edit
                              </a>
                            </div>
                            <div className="cont mt-3">
                              {timing?.data.map((item: any, index: number) => (
                                <div className="row mb-2" key={index}>
                                  <div className="col-md-3">
                                    <p className="fw-med mb-0">
                                      <small>{item?.day}</small>
                                    </p>
                                  </div>
                                  <div className="col-md-9">
                                    {item?.isClosed ? (
                                      // Render opening and closing times if isClosed is true
                                      <>
                                        <p className="mid-gray mb-0">
                                          <small>
                                            {formatTime(item?.openingTime)} am -{" "}
                                            {formatTime(item?.closingTime)} pm
                                          </small>
                                        </p>
                                      </>
                                    ) : (
                                      // Render "Closed" if isClosed is false
                                      <>
                                        <p className="text-danger mb-0">
                                          <small>Closed</small>
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <hr />
                              <h6 className="mb-0">Auto Online/Offline</h6>

                              {timing && timing.data.length > 0 && (
                                <p className="mb-0 mid-gray">
                                  <small>
                                    {timing.data[0]?.isAuto
                                      ? "Enabled"
                                      : "Disabled"}
                                  </small>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-12">
                        <div className="snippet-block h-100 d-flex align-items-center justify-content-between">
                          <div className="cont d-flex align-items-center">
                            <img
                              className="img-fluid me-2"
                              src="../../../assets/images/key-circle-ico.svg"
                              alt="ico"
                            />
                            <p className="mid-gray mb-0">Change Password</p>
                          </div>
                          <button
                            className="btn btn-primary btn-ico btn-empty change-btn pe-0"
                            onClick={changePassword}
                          >
                            <i className="material-symbols-outlined me-0">
                              chevron_right
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Content Wrap Ends here --> */}
            </section>
            {/* <!-- Main Content Section Ends here --> */}
          </section>
        </div>
      </section>
    </>
  );
};

export default ListSettings;
