import axios from "axios";
import { getToken } from "./AuthHelpers";


//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;

//API URL
export const LOGIN = `${API_URL}login/userLogin`;
export const VERIFY_EMAIL = `${API_URL}login/verifyEmail`;
export const VERIFY_TOKEN = `${API_URL}login/userVerifyToken`;
export const REGISTER_USER = `${API_URL}login/createUserData`;
export const RESEND_OTP = `${API_URL}login/resendOTP`;

//VERIFY EMAIL
export function verifyEmail(email: string, emailOtp: string) {
  return axios.post(VERIFY_EMAIL, {
    email,
    emailOtp,
  });
}

//VERIFY TOKEN
export function getUserByToken(token: any) {
  console.log("getUserByToken-api",token);
  return axios.post(`${API_URL}chefPortal/verifyToken`, {
    
    token: token,
   
  });
}

//REGISTER
const chefRegisteration = async (data: FormData) => {
  const response = await axios.post(`${API_URL}chefPortal/signUp`, data);
  console.log("response.data ==chef", response.data);
  return response.data;
};
//social register
const chefSocialRegisteration = async (data: any) => {
  console.log("chefSocialRegisteration-chefSocialRegisdata--apiiii",data);
  
  const response = await axios.post(`${API_URL}chefPortal/social-signUp`, data);
  console.log("response.data ==chefSocialRegisteration===apiii", response.data);
  return response.data;
};
const sociaLogin = async (data: any) => {
  console.log("social login data =====", data);
  const response = await axios.post(`${API_URL}chefPortal/socialogin`, data);
  return response.data.result;
};

const verifyChefEmail = async (email: any, otp: any) => {
  const response = await axios.post(`${API_URL}chefPortal/verifyEmail`, {
    email,
    otp,
  });

  return response.data;
};

const login = async (email: any, password: any) => {
  const response = await axios.post(`${API_URL}chefPortal/login`, {
    email,
    password,
  });
  console.log("response login", response.data);
  return response.data;
};

const forgotPasswordMail = async (email: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/forgot-password-send-mail`,
    { email }
  );
  return response.data;
};

const decryptData = async (userData: any) => {
  const response = await axios.post(`${API_URL}chefPortal/decryptData`,  getToken(),{
    data: userData,
  });
  return response.data;
};

const callGooglePlacesApi = async (address: any) => {
  const response = await axios.get(
    `${API_URL}chefPortal/locationAPIFetch?input=${address}`
  );
  return response.data;
};

const locationLatLongAPIFetch = async (place_id: any) => {
  const response = await axios.get(
    `${API_URL}chefPortal/locationLatLongAPIFetch?input=${place_id}`
  );
  return response.data;
};

const mostOrerDish = async (id: any) => {
  return await axios
    .get(`${API_URL}chefPortal/mostOrderDish/${id}`, getToken())
    .then((response) => {
      return response.data.data;
    });
};

const topRatedDish = async (id: any) => {
  return await axios
    .get(`${API_URL}chefPortal/topRatedChefsDish/${id}`, getToken())
    .then((response) => {
      return response.data.data;
    });
};

const recentReview = async (id: any) => {
  return await axios
    .get(`${API_URL}chefPortal/DashboardRecentRatings/${id}`, getToken())
    .then((response) => {
      return response.data.data;
    });
};

const graphData = async (id: any) => {
  return await axios
    .get(`${API_URL}chefPortal/graph/${id}`, getToken())
    .then((response) => {
      return response.data;
    });
};

const orderList = async (id: any) => {
  return await axios
    .get(`${API_URL}chefPortal/listChefOrder/${id}`, getToken())
    .then((response) => {
      return response.data.data;
    });
};

const listOrdersData = async (ChefId: any) => {
  console.log("listChefOrder-dashhhh--ChefId",ChefId)
  const response = await axios.get(
    `${API_URL}chefPortal/listChefOrder/${ChefId}`,getToken(),
  
  );
  return response.data;
};

const totalNetValue = async (ChefId: any) => {
  const response = await axios.get(
    `${API_URL}chefPortal/dashbordNetvalue/${ChefId}`, getToken()
  );
  return response.data;
};

const getNotification = async (ChefId: any) => {
  const response = await axios.get(
    `${API_URL}chefPortal/notification/${ChefId}`, getToken()
  );
  //  console.log("data==========123",response.data)
  return response.data.data;
};

const clearNotification = async (ChefId: any) => {
  const response = await axios.delete(
    `${API_URL}chefPortal/clearnotification/${ChefId}`, getToken()
  );
  return response.data.data;
};
const ProfileDetails = async (chefId: any) => {
  const response = await axios.get(`${API_URL}chefPortal/chef/view/${chefId}`);
  return response.data;
};
const addressUserFetch = async (data: any) => {
  const response = await axios.post(`${API_URL}chefPortal/addressFetch`, data, getToken());
  console.log("response.data.data", response.data.data);
  return response.data.data;
};

const currentlyAvailable = async (id: any, currentlyAvailableStatus: any) => {
  const response = await axios.put(
    `${API_URL}chefPortal/chefAvailable/${id}`, 
    currentlyAvailableStatus,getToken()
  );
  return response.data;
};

const sendEmailOtp = async (data: any) => {
  console.log("resendOtp", data);
  return await axios
    .post(`${API_URL}chefPortal/resend-otp-email`, data)
    .then((response) => {
      // console.log('data======listtime',response.data)
      return response.data;
    });
};

const sendOtpData = async (data: any) => {
  console.log("sendOtp-dataapiii", data);
  return await axios
    .post(`${API_URL}chefPortal/sendotp`, data)
    .then((response) => {
      console.log('data======response-res-api',response)
      console.log('response.data-sent otp-apiii',response.data)
      return response.data;
    });
};

const sendMobileOtp = async (data: any) => {
  console.log("sendMobileOtp", data);
  return await axios
    .post(`${API_URL}chefPortal/resend-otp-mobile`, data)
    .then((response) => {
      // console.log('data======listtime',response.data)
      return response.data;
    });
};

const verifyChefMobile = async (mobileNumber: any, otp: any) => {
  console.log("mobileNumber", mobileNumber, "otp", otp);
  const response = await axios.post(`${API_URL}chefPortal/verify-otp-mobile`, {
    mobileNumber,
    otp,
  });
  return response.data;
};
const searching = async (ChefId: any, searchTerm: string) => {
  
  const response = await axios
    .post(`${API_URL}chefPortal/hardSearch/${ChefId}?search=${searchTerm}`, getToken());
    
  return response.data;
};
const changeStatusOrders = async (id: any,orderStatus: any) => {
  console.log("api-orderstatus",orderStatus)
  console.log("api-orderstatus--id",id)
  const res = await axios.post(`${API_URL}chefPortal/changeChefOrderStatus/${id}`, getToken(), orderStatus);
  console.log("api-res.data-changeStatusOrders",res.data)
  return res.data;
};
export {
  decryptData,ProfileDetails,
  verifyChefMobile,
  sendMobileOtp,
  chefRegisteration,chefSocialRegisteration,
  verifyChefEmail,
  login,
  callGooglePlacesApi,
  locationLatLongAPIFetch,
  mostOrerDish,
  topRatedDish,
  recentReview,
  graphData,
  orderList,
  listOrdersData,
  totalNetValue,
  getNotification,
  clearNotification,
  sociaLogin,
  addressUserFetch,
  currentlyAvailable,
  forgotPasswordMail,
  sendEmailOtp,
  sendOtpData,searching,changeStatusOrders
};
