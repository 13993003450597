import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAuth } from "../core/Auth";
import { login, getUserByToken, sociaLogin } from "../core/_requests";
import { useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  getRedirectResult,
  OAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase/FirebaseConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [showAlert, setShowAlert] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowAlert(false);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),

    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const data = await login(values.email, values.password);
        if (data?.status === true) {
          saveAuth(data);
          const { data: user } = await getUserByToken(data?.data.api_token);
          setCurrentUser(user);
          setLoading(false);
          navigate("/settings");
        } else {
          setShowAlert(true);
          setStatus(data?.message);
          setLoading(false);
        }
      } catch (error) {
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
        setShowAlert(true);
      }
    },
  });

  const handleGoogle = async (e: any) => {
    const googleProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("handleGoogle=====result", result);
      const chef = result.user;
      console.log("handleGoogle=====result.user", result.user);

      if (chef) {
      console.log("handleGoogle=====ifff.chef",chef);

        const data = {
          email: chef.email,
        };
        const googleData = await sociaLogin(data);
       
        if (googleData) {
          saveAuth(googleData);
          setCurrentUser(googleData);
          setLoading(false);
          navigate("/settings");
        } else {
          toast.error(
            "Kindly proceed to register your details on our delightful delicacies website. "
          );
          setLoading(false);
          setShowAlert(true);
          navigate("/auth");
        }
      } else {
        console.log("Sorry, you are not a user", chef);
        setShowAlert(true);
        setLoading(false);
      }
    } catch (error: any) {
      if (error.code === "auth/cancelled-popup-request") {
        console.log("Popup request cancelled");
      } else {
        console.error("Error signing in with Google:", error);
      }
    }
  };

  const handleFacebook = async () => {
    console.log("FACEBOOK WORKING");
    const facebookProvider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log("FACEBOOK TRY TO GET CHEF ", result);
      const chef = result.user;
      console.log("FACEBOOK CHEF email", chef?.providerData?.[0]?.email);
      // console.log("FACEBOOK CHEF phoneNumber",chef?.providerData?.[0]?.phoneNumber);
      const email = chef?.providerData?.[0]?.email;
      // const mobileNumber =chef?.providerData?.[0]?.phoneNumber
      // const credential = FacebookAuthProvider.credentialFromResult(result);
      // const accessToken = credential ? credential.accessToken : null;
      if (chef) {
        const data = {
          email: email,
          // mobileNumber:mobileNumber
        };
        const googleData = await sociaLogin(data);
        console.log("FACEBOOK=====1223", googleData);
        if (googleData) {
          saveAuth(googleData);
          setCurrentUser(googleData);
          setLoading(false);
          navigate("/settings");
        } else {
          toast.error(
            "Kindly proceed to register your details on our delightful delicacies website."
          );
          setLoading(false);
          setShowAlert(true);
          navigate("/auth");
        }
      } else {
        console.log("Sorry, you are not a user", chef);
        setShowAlert(true);
        setLoading(false);
      }
    } catch (error) {
      // Handle errors...
      console.error("Error during Facebook sign-in:", error);
    }
  };

  const handleApple = async () => {
    console.log("Apple WORKING");
    const provider = new OAuthProvider("apple.com");
    try {
      const email = provider.addScope("email");
      const name = provider.addScope("name");
      console.log("email", email, "name", name);
      // provider.setCustomParameters({
      //   locale: 'us'
      // });

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const chef = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          // const accessToken = credential?.accessToken;
          // const idToken = credential?.idToken;

          if (chef) {
            const data = {
              email: chef.email,
            };

            const appleData = await sociaLogin(data);
       

            if (appleData) {
              saveAuth(appleData);
              setCurrentUser(appleData);
              setLoading(false);
              navigate("/settings");
            } else {
              toast.error(
                "Kindly proceed to register your details on our delightful delicacies website."
              );
              setLoading(false);
              setShowAlert(true);
              navigate("/auth");
            }
          } else {
            console.log("Sorry, you are not a user", chef);
            setShowAlert(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
    } catch (error) {
      // Handle errors...
      console.error("Error during Facebook sign-in:", error);
    }
  };
  useEffect(() => {
    checkForRedirectResult();
  }, []);

  const checkForRedirectResult = async () => {
    try {
      const result = await getRedirectResult(auth);
      console.log("result from getRedirectResult:", result);
      console.log("result from getRedirectResult:.result?.user",result?.user );
      console.log("result from getRedirectResult:.result?.user.data",result?.user);
      const user = result?.user;
      if (user) {
        console.log("User signed in:", user);
      }
    } catch (error) {
      console.error("Error during redirect result:", error);
    }
  };
  const logoutFunction = () => {
    localStorage.removeItem("homechef360ChefPortal");
    localStorage.removeItem("data");
    localStorage.removeItem("mobileNumber");
    localStorage.removeItem("chefStatus");
    localStorage.removeItem("name");
    localStorage.removeItem("chefemail");
  };
  const handleRegister = () => {
    logoutFunction();
    navigate("/signup");
  };
  // console.log("formik", formik.values);
  return (
    <div className="container-fluid signup-sec py-3">
      <div className="row">
        <div className="col-xl-5 col-md-2 d-none d-lg-block">
          <div className="signup-img">
            <img
              src="./assets/images/signup-grid.png"
              className="img-fluid"
              alt="img-grid"
            />
          </div>
        </div>

        <div className="col-xl-7 col-lg-7">
          {formik.status !== undefined && showAlert ? (
            <div
              className={`mb-lg-10 alert ${
                formik.status ? "alert-danger" : "alert-success"
              }`}
            >
              <div className="alert-text font-weight-bold">
                {formik.status}
                <button
                  className="btn p-0 close d-inline-end"
                  onClick={handleClose}
                >
                  <i className="material-symbols-outlined fs-18  px-10">
                    close
                  </i>
                </button>
              </div>
            </div>
          ) : null}
          <form onSubmit={formik.handleSubmit}>
            <div className="right-form px-4 d-flex justify-content-center flex-column">
              <div className="content-sec">
                <div className="row justify-content-center">
                  <div className="col-xl-6">
                    <div className="d-flex flex-column text-center align-items-center justify-content-center">
                      <div className="login-logo mb-1">
                        <img
                          src="./assets/images/logo.svg"
                          className="img-fluid"
                          alt="logo"
                        />
                      </div>
                      <h2 className="mb-2">Welcome Back! </h2>
                      <div className="row">
                        <div className="col-xl-12 text-start mb-2">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="small-font mb-2"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            {...formik.getFieldProps("email")}
                            className={clsx(
                              "form-control",
                              {
                                "is-invalid":
                                  formik.touched.email && formik.errors.email,
                              },
                              {
                                "is-valid":
                                  formik.touched.email && !formik.errors.email,
                              }
                            )}
                            id="exampleFormControlInput1"
                            placeholder="Enter your email"
                          />{" "}
                          {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.email}</span>
                            </div>
                          )}
                        </div>
                        <div className="col-xl-12 text-start mb-2">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="small-font mb-2"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              placeholder="Enter your password"
                              type={!showPassword ? "password" : "text"}
                              className={clsx(
                                "form-control",
                                {
                                  "is-invalid":
                                    formik.touched.password &&
                                    formik.errors.password,
                                },
                                {
                                  "is-valid":
                                    formik.touched.password &&
                                    !formik.errors.password,
                                }
                              )}
                              {...formik.getFieldProps("password")}
                            />
                            <div className="input-group-text">
                              <span
                                className="cursorPointer"
                                onClick={handleTogglePassword}
                              ></span>
                            </div>
                          </div>
                          {formik.touched.password &&
                            formik.errors.password && (
                              <div className="fv-plugins-message-container">
                                <span role="alert">
                                  {formik.errors.password}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn primary-btn px-5 text-center mb-3 mt-2"
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Login</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                      <a href="/forgot-password" className="link">
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-center mt-0 mb-4 ss-ico">
                  <a className="btn" onClick={handleGoogle}>
                    <img
                      alt="Google Logo"
                      src="./assets/images/google-icon.svg"
                      className="ico google"
                    />
                  </a>

                  <a className="btn" onClick={handleFacebook}>
                    <img
                      alt="Facebook Logo"
                      src="./assets/images/facebook.png"
                      className="ico fb"
                    />
                  </a>

                  <a className="btn " onClick={handleApple}>
                    <img
                      alt="apple Logo"
                      src="./assets/images/apple.png"
                      className="ico"
                    />
                  </a>
                </div>
                <div className="row mt-0 pt-4 pb-3 footer-login">
                  <p className="text-center small-font mt-0">
                    If you don't have an account.{" "}
                    <a onClick={handleRegister} className="link cursorPointer">
                      Register
                    </a>{" "}
                    here
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export { Login };
