import { Suspense, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SplashScreen } from "../features/SplashScreen";
import { Dashboard } from "../auth/component/Dashboard";
import DishPage from "../modules/dish/DishPage";
import CouponPage from "../modules/coupon/CoponPage";
import OrdersPage from "../modules/orders/OrdersPage";
import { Login } from "../auth/component/Login";
import ReportPage from "../modules/report/ReportPage";
import SettlementsPage from "../modules/settlements/SettlementsPage";
import ReviewsPage from "../modules/reviews/ReviewsPage";
import SettingsPage from "../modules/settings/SettingsPage";
import Support from "../modules/support/files/Support"
import { Signup } from "../auth/component/Signup";
import AddonPage from "../modules/addon/AddonPage";
import Help from "../modules/help/files/Help";



const PrivateRoutes = () => {
  const [getType, setGetType] = useState();
  return (
    <Routes>
      <Route path="auth/*" element={<Navigate to={"/"} />} />
      <Route
        path="dashboard/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <Dashboard />
          </Suspense>
        }
      />
      <Route
        path="dish/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <DishPage />
          </Suspense>
        }
      />
   
      <Route
        path="coupon/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <CouponPage />
          </Suspense>
        }
      />
      <Route
        path="orders/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <OrdersPage />
          </Suspense>
        }
      />
      <Route
        path="report/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <ReportPage />
          </Suspense>
          }
      />

      <Route
        path="settlements/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <SettlementsPage />
          </Suspense>
        }
      />
       <Route
        path="settings/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <SettingsPage />
          </Suspense>
        }
      />
       <Route
        path="reviews/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <ReviewsPage />
          </Suspense>
          }
      />
            <Route
        path="support/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <Support />
          </Suspense>
          }
      />
          <Route
        path="help/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <Help />
          </Suspense>
          }
      />
          <Route
        path="signup/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <Signup />
          </Suspense>
          }
      />
    
        <Route
        path="addons/*"
        element={
          <Suspense fallback={<SplashScreen />}>
            <AddonPage />
          </Suspense>
          }
      />
      <Route path="login" element={<Login />} />
    </Routes>
  );
};

export { PrivateRoutes };
