import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

// //ADD CSS
import "../src/app/assets/style.css";
import "../src/app/assets/style.css.map";
import "../src/app/assets/style.scss";
import "../src/app/assets/variables.css";
import "../src/app/assets/variables.css.map";
import "../src/app/assets/variables.scss";

import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./app/auth";
import AppRoutes from "./app/routings/AppRoutes";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AppRoutes />
      <ToastContainer />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
