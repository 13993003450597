import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { listTiming, updateTiming } from "../core/_requests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../auth";
import Header from "../../sidebar/Header";

interface Addtime {
  id: string;
  chefId: string;
  isClosed: boolean;
  isAuto: boolean;
  timingType: boolean;
  openingTime: string;
  closingTime: string;
  status: string;
  day: string;
}

type Props = {
  initialValues: any;
};

const ChangeChefTiming: FC<Props> = ({ initialValues }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const chefId = currentUser?.uuid;
  const [isAuto, setIsAuto] = useState<boolean>(false); // Track isAuto state
  const [timingType, setTimingType] = useState<boolean>(false); // Track timingType state

  const [isClosed, setIsClosed] = useState<boolean[]>(
    initialValues.map((item: { isClosed: any }) => {
      return item.isClosed;
    })
  );
  const inputs = useRef<Array<HTMLInputElement | HTMLSelectElement | null>>([]);
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index < inputs.current.length - 1) {
        // If not the last input field, focus on the next input
        const nextIndex = index + 1;
        inputs.current[nextIndex]?.focus();
      } else {
        // If the last input field, submit the form
        formik.handleSubmit();
      }
    }
  };
  useEffect(() => {
    listTiming(chefId).then((res) => {
      setIsAuto(res.data[0].isAuto);
      setTimingType(res.data[0].timingType);
      setData(res.data);
    });
  }, [chefId]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newTimingType = value === "true"; // Convert string value to boolean
    setTimingType(newTimingType); // Update timingType state
    formik.setFieldValue("timingType", newTimingType); // Update timingType in formik
  };

  const clickCancel = () => {
    navigate("/settings");
  };

  const handleTimeChange = (day: string, field: string, value: string) => {
    setData((prevData: any) => {
      const newData = prevData.map((item: any) => {
        if (day === "all") {
          // If updating all days, set the same value for all days
          return { ...item, [field]: value };
        } else if (item.day === day) {
          // If updating a specific day, set the value for that day only
          return { ...item, [field]: value };
        }
        return item;
      });

      // Update isClosed for all days if day === "all"
      if (day === "all") {
        const newIsClosed = timingType === false ? false : true; // Adjust this condition as needed
        newData.forEach((item: any) => {
          item.isClosed = newIsClosed;
        });
      }

      return newData;
    });
  };

  const handleCheckboxChange = (index: number) => {
    setIsClosed((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleModeChange = (value: boolean) => {
    setIsAuto(value);
  };

  const formik = useFormik<Addtime>({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      const updatedData = data.map((item: any, index: number) => ({
        chefId,
        openingTime: item.openingTime,
        closingTime: item.closingTime,
        day: item.day,
        isClosed: isClosed[index], // Use the updated isClosed value from the state
      }));
      console.log("updatedData--->res", updatedData);

      try {
        const res = await updateTiming({
          timingType: timingType,
          isAuto: isAuto,
          chefWorkingHours: updatedData,
        });
        console.log("updateTiming--->res", res);
        if (res) {
          setLoading(false);
          toast.success(res?.message, { autoClose: 6000 });
          navigate("/settings");
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred while updating timing.");
        setLoading(false);
      }

      resetForm();
    },
  });

  console.log("change time-formik", formik);
  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <Header />
          <section className="content-section">
            <div className="overlay"></div>
            <div className="drawer small">
              <div className="title row">
                <h3 className="col-10 mb-0">Business Timings</h3>
                <div className="col-2 text-end">
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={clickCancel}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </button>
                </div>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="row mt-4 add-bank-form"
              >
                <div className="col-12 mb-3">
                  <label className="form-label">Working Days & Timings</label>
                  <div className="radio-btn-row">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          {...formik.getFieldProps("timingType")}
                          name="timingType"
                          value="true" // Value for "All Days"
                          checked={timingType} // Checked if timingType is true
                          onChange={handleRadioChange}
                          ref={(el) => (inputs.current[3] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 3)}
                        />
                        All Days
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          {...formik.getFieldProps("timingType")}
                          name="timingType"
                          value="false" // Value for "Specific Days & Timings"
                          checked={!timingType} // Checked if timingType is false
                          onChange={handleRadioChange}
                          ref={(el) => (inputs.current[3] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 3)}
                        />
                        Specific Days & Timings
                      </label>
                    </div>
                  </div>
                </div>

                {timingType ? (
                  <>
                    <div className="col-12 mb-3">
                      <div className="d-flex">
                        <label htmlFor="" className="form-label w-50">
                          Start Time
                        </label>
                        <label htmlFor="" className="form-label w-50">
                          End Time
                        </label>
                      </div>
                      <div className="input-group">
                        <input
                          type="time"
                          id="appt"
                          ref={(el) => (inputs.current[3] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 3)}
                          className="form-control"
                          {...formik.getFieldProps("openingTime")}
                          value={data[0].openingTime}
                          onChange={(e) =>
                            handleTimeChange(
                              "all",
                              "openingTime",
                              e.target.value
                            )
                          }
                        />
                        <input
                          type="time"
                          id="appt"
                          ref={(el) => (inputs.current[3] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 3)}
                          className="form-control"
                          {...formik.getFieldProps("closingTime")}
                          value={data[0].closingTime}
                          onChange={(e) =>
                            handleTimeChange(
                              "all",
                              "closingTime",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  data.map((item: any, i: any) => (
                    <div key={i}>
                      <div className="col-12 mt-4 mb-3">
                        <div className="row mb-1">
                          <div className="col-3">
                            <label className="form-label">Days</label>
                          </div>
                          <div className="col-9">
                            <div className="d-flex">
                              <label className="form-label w-50">
                                Start Time
                              </label>
                              <label className="form-label w-50">
                                End Time
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-3 pt-1">
                            <div className="form-check form-switch days">
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                ref={(el) => (inputs.current[3] = el)}
                                onKeyDown={(e) => handleKeyPress(e, 3)}
                                checked={isClosed[i]}
                                onChange={() => handleCheckboxChange(i)}
                              />
                              <label className="yes"> {item.day}</label>
                              <label className="no"> {item.day} </label>
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="input-group">
                              <input
                                type="time"
                                id="appt"
                                ref={(el) => (inputs.current[3] = el)}
                                onKeyDown={(e) => handleKeyPress(e, 3)}
                                className="form-control"
                                {...formik.getFieldProps(`openingTime${i}`)}
                                value={item.openingTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    item.day,
                                    "openingTime",
                                    e.target.value
                                  )
                                }
                              />
                              <input
                                type="time"
                                id="appt"
                                ref={(el) => (inputs.current[3] = el)}
                                onKeyDown={(e) => handleKeyPress(e, 3)}
                                className="form-control"
                                {...formik.getFieldProps(`closingTime${i}`)}
                                value={item.closingTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    item.day,
                                    "closingTime",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                <div className="col-12 mt-4 mb-3">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="autoOnOff"
                      ref={(el) => (inputs.current[3] = el)}
                      onKeyDown={(e) => handleKeyPress(e, 3)}
                      checked={isAuto}
                      onChange={() => setIsAuto(!isAuto)}
                      value="option1"
                    />

                    <label
                      className="form-check-label ms-2"
                      htmlFor="autoOnOff"
                    >
                      <p className="mb-0 fw-med line-height-normal">
                        <small>Enable Auto Online/Offline ? </small>
                      </p>
                    </label>
                  </div>
                  <div className="col-12 mb-3 mt-4 pt-3">
                    <p className="fw-med mb-3">
                      <small>Please note</small>
                    </p>
                    <ul className="pwd-criteria-list">
                      <li>
                        When "auto enabled," the system automatically adjusts
                        the Chef's availability status based on predefined
                        timing, switching between online and offline modes.
                      </li>
                      <li>
                        When "disabled," the Chef manually controls their
                        online/offline status, overriding any automatic updates
                        from the system.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-cancel rounded-5"
                        onClick={clickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={formik.isSubmitting || !formik.isValid}
                        className="btn btn-outline-primary rounded-5 me-2"
                      >
                        {!loading && (
                          <span className="indicator-label">Publish</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export { ChangeChefTiming };
