import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDishView } from "../core/_requests";
import EditDish from "./EditDish";
import { log } from "util";


export const EditBasicDetailsWrapper = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    getDishView(id).then((res:any) => {
    console.log("getDishView-wrap",res.data);
      setData(res.data);
    });
  }, []);

  if (data) {
    return <EditDish initialValues={data} />;
  }
  return null;
};
