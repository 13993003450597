import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FC, useEffect, useState, SetStateAction, useMemo, useRef } from "react";
import {
  ProfileDetails,
  UpdateProfile,
  addressUserFetch,
  callGooglePlacesApi,
  locationLatLongAPIFetch,
} from "../core/_requests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Modal } from "react-bootstrap";
import { ChefProfile, useAuth } from "../../../auth";

import Header from "../../sidebar/Header";

const mapContainerStyle = {
  width: "470px",
  height: "280px",
};
const libraries = ["places"];

type Props = {
  initialValues: any;
};

const ProfilePicUpdate: FC<Props> = ({ initialValues }) => {
  const [data, setData] = useState<ChefProfile>(initialValues);
  const [showMarker, setShowMarker] = useState(false);
  const LatLng = {
    lat: Number(data.latitude),
    lng: Number(data.longitude),
  };
  const center = useMemo(
    () => ({ lat: Number(data.latitude), lng: Number(data.longitude) }),
    []
  );

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFileMessage, setShowFileMessage] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [formImage, setFormImage] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [mapCenter, setMapCenter] = useState(center);
  const [userLocation, setUserLocation] = useState(LatLng);
  const [addressData, setAddressData] = useState<any>({});
  const [suggestions, setSuggestions] = useState<any>([]);
  const [googledata, setgoogledata] = useState<any>({});
  const [dropdownCLicked, setdropdownCLicked] = useState(false);
  const chefId =currentUser?.uuid
  const inputs = useRef<Array<HTMLInputElement | HTMLSelectElement | null>>([]);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (index < inputs.current.length - 1) {
        // If not the last input field, focus on the next input
        const nextIndex = index + 1;
        inputs.current[nextIndex]?.focus();
      } else {
        // If the last input field, submit the form
        formik.handleSubmit();
      }
    }
  };
  const clickCancel = () => {
    navigate("/settings");
  };

  useEffect(() => {
    ProfileDetails(chefId)
      .then((res) => {
        setData(res?.data);
        setImagePreview(res?.data?.profile);
        setUserLocation({
          lat: res?.data?.latitude,
          lng: res?.data?.longitude,
        });
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);



  const formik = useFormik<ChefProfile>({
    initialValues,
  
    onSubmit: async (values, actions) => {
      setLoading(true);
      console.log("values", values);
      const updatedData = Object.assign(data, values);
      console.log("updatedData", updatedData);
      const formData = new FormData();
      if (formImage) formData.append("profile", formImage);
      

      UpdateProfile(formData, chefId).then((res) => {
   
        if (res?.status === true) {
          navigate("/settings/profile-success", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    },
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleErrorMessageClose = () => {
    setErrorMessage("");
    setData({
      ...data,
      profile: null,
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const imageHandler = (e: { target: { files: any } }) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImagePreview(reader.result as string);
        setShowFileMessage(false);
      }
    };

    const files = e.target.files;
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (files && !allowedTypes.includes(files[0].type)) {
      setErrorMessage(
        "Invalid file type. Allowed types are JPEG, PNG and JPG."
      );
      setFormImage(null);
    }  else if (files && files[0].size > 1024 * 1024) {
      // Check if file size exceeds 1MB
      setErrorMessage("File size should be less than 1MB.");
      setFormImage(null);}
    else {
      setErrorMessage("");
      if (files) {
        setFormImage(files[0]);
        reader.readAsDataURL(files[0]);
      }
    }
  };



  console.log("data", data);
  return (
    <>
      <section className="container-fluid">
        <div className="row">
              {/* <Sidebar />   */}
              <Header />
          <section className="content-section">
            {/* <Header /> */}
            <div className="overlay"></div>
            <div className="drawer small  md-5">
            <div className="title row">
                <h3 className="col-10 mb-0">Edit Profile</h3>
                <div className="col-2 text-end">
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={clickCancel}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </button>
                </div>
              </div>
            
            <br></br> <br></br> 
              <form onSubmit={formik.handleSubmit}>
              <div className="title row">
           
           <div className="col-10 d-flex align-items-center">
             <div className="image-container row me-3">
               {imagePreview || data.profile ? (
                 <img
                   className="image-preview shadow rounded"
                   style={{ width: "100px", height: "100px" }}
                   src={imagePreview || data.profile}
                   alt="Profile"
                 />
               ) : (
                 <FontAwesomeIcon
                   icon={faUserCircle}
                   className="user-icon"
                   size="3x"
                 />
               )}
             </div>
             <br></br>
             <div className="row">
               <label
                 htmlFor="imageInput"
                 className="cursor-pointer custom-label"
                 //  className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
               >
                 <input
                   id="imageInput"
                   type="file"
                   accept="image/*"
                   className="hidden"
                   onChange={imageHandler}
                   
                 />
               </label>
             </div>
           </div>
        
           {errorMessage && (
           <div style={{ color: "red" }} className="custom-error-messages">
             {errorMessage}
             <button
               className="btn p-0 close d-inline-flex"
               onClick={handleErrorMessageClose}
             >
               <i className="material-symbols-outlined fs-18">close</i>
             </button>
           </div>
         )}
         </div>

                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-cancel rounded-5"
                        onClick={clickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={formik.isSubmitting || !formik.isValid}
                        className="btn btn-outline-primary rounded-5 me-2"
                      >
                        {!loading && (
                          <span className="indicator-label">Publish</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>

        </div>
      </section>
    </>
  );
};
export { ProfilePicUpdate };
