import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAuth } from "../core/Auth";
import { forgotPasswordMail,} from "../core/_requests";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
  email: ""
};

const ForgotPasswordUserData = () => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [showAlert, setShowAlert] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowAlert(false);
  };
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        localStorage.setItem("chefemail", values.email)
        const data = await forgotPasswordMail(values.email);
        console.log("data",data)
        if (data?.status === true) {
          toast.success(data?.message, { autoClose: 6000 });
          navigate("/forgot-password");
          setShowAlert(true);
          setStatus(data?.message);
          setLoading(false);
        } else {
          toast.error(data?.message, { autoClose: 6000 });
          
        }   
    
      } 
      catch (error) {
        saveAuth(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
        setShowAlert(true);
      }
    },
  });
  const handleLogin = () => {
    navigate("/login");
  };
  console.log("formik",formik.values);

  return (
    <div className="container-fluid signup-sec py-3">
      <div className="row">
        <div className="col-xl-5 col-md-5 d-none d-lg-block">
          <div className="signup-img">
            <img
              src="./assets/images/signup-grid.png"
              className="img-fluid"
              alt="img-grid"
            />
          </div>
        </div>

        <div className="col-xl-7 col-lg-7">
          {formik.status && showAlert ? (
            // <div className="mb-lg-15 alert alert-danger">
            <div className={`mb-lg-10 alert ${formik.status ? 'alert-success' : 'alert-danger'}`}>
              <div className="alert-text font-weight-bold">
                {formik.status}
                <button
                  className="btn p-0 close d-inline-end"
                  onClick={handleClose}
                >
                  <i className="material-symbols-outlined fs-18  px-10">
                    close
                  </i>
                </button>
              </div>
            </div>
          ) : null}
          <form onSubmit={formik.handleSubmit}>
            <div className="right-form px-5 d-flex justify-content-center flex-column">
              <div className="content-sec">
                <div className="row justify-content-center">
                  <div className="col-xl-6">
                    <div className="d-flex flex-column text-center align-items-center justify-content-center">
                      <div className="login-logo mb-4">
                        <img
                          src="./assets/images/logo.svg"
                          className="img-fluid"
                          alt="logo"
                        />
                      </div>
                      <h2 className="mb-3">Welcome Back!</h2>
                      <div className="row">
                        <div className="col-xl-12 text-start mb-4">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="small-font mb-2"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            {...formik.getFieldProps("email")}
                            className={clsx(
                              "form-control fp-input",
                              {
                                "is-invalid":
                                  formik.touched.email && formik.errors.email,
                              },
                              {
                                "is-valid":
                                  formik.touched.email && !formik.errors.email,
                              }
                            )}
                            id="exampleFormControlInput1"
                            placeholder="Enter your email"
                          />{" "}
                          {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.email}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn primary-btn px-5 text-center mb-3"
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className="indicator-label">Submit</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 pt-4 pb-3 footer-login">
                  <p className="text-center small-font mt-3">
                   Do you want to login account.{" "}
                    <a onClick={handleLogin} className="link cursorPointer">
                      Login
                    </a>{" "}
                    here
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export { ForgotPasswordUserData };
