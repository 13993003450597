import { useState } from "react";

import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ListDishes from "./files/ListDishes";
import { useAuth } from "../../auth";
import AddDish from "./files/AddDish";
import Success from "./files/Success";
import ViewDishes from "./files/ViewDish";
import DeleteDish from "./files/DeleteDish";
import CategorySuccess from "./files/CategorySuccess";
import EditDish from "./files/EditDish";
import { EditBasicDetailsWrapper } from "./files/EditWarpper";
import Edit from "./files/Edit";


const DishPage = () => {

  return (

    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
           <ListDishes />
            </>
          }
        />
        <Route
          path="/add"
          element={
            <>
              <AddDish />
            </>
          }
        />
        <Route
          path="/view/:id"
          element={
            <>
              <ViewDishes />
            </>
          }
        />
        <Route
          path="/edit/:id"
          element={
            <>
               <EditBasicDetailsWrapper />
            </>
          }
        />
        <Route
          path="/delete/:id"
          element={
            <>
              <DeleteDish />
            </>
          }
        />
        <Route
          path="/success"
          element={
            <>
              <Success />
            </>
          }
        />
        <Route
          path="/edit"
          element={
            <>
              <Edit />
            </>
          }
        />
  
        <Route
          path="/categorysuccess"
          element={
            <>
              <CategorySuccess />
            </>
          }
        />
        <Route index element={<Navigate to="list" />} />
      </Route>
    </Routes>
  );
};

export default DishPage;
