import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AuthPage, useAuth } from "../auth";
import { ErrorPage } from "../features/ErrorPage";
import App from "../App";
import { PrivateRoutes } from "./PrivateRoutes";
import { Otpverification } from "../auth/component/Otpverification";
import { Verifysuccess } from "../auth/component/Verifysuccess";
import { Login } from "../auth/component/Login";
import { OtpverificationError } from "../auth/component/OtpverificationError";
import { MobileOtpVerification } from "../auth/component/MobileOtpVerification";
import { Otpdevice } from "../auth/component/Otpdevice";
import { ChooseAddress } from "../auth/component/ChooseAddress";
import { ForgotPasswordUserData } from "../auth/component/ForgotPasswordUserData";

function AppRoutes() {
  const { currentUser } = useAuth();

  let redirect = "dashboard";
  console.log("currentUser=========", currentUser);
  console.log("currentUser=========", currentUser);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorPage />} />

          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to={redirect} />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
              <Route path="otp-verification" element={<Otpverification />} />
              <Route path="otp-device" element={<Otpdevice />} />
              <Route path="otp-verification-mobile" element={<MobileOtpVerification />} />
              <Route path="otpVerifyError" element={<OtpverificationError />} />
              <Route path="otp-success" element={<Verifysuccess />} />{" "}
              <Route path="login" element={<Login />} />
              <Route path="choose-addess" element={<ChooseAddress />} />
              <Route path="forgot-password" element={<ForgotPasswordUserData />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
