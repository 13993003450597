import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { viewBankById } from "../core/_requests";
import EditSettlements from "./EditSettlements";


export const EditBasicDetailsWrapper = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    viewBankById(id).then((res) => {
      setData(res.data);
    });
  }, []);

  if (data) {
    return <EditSettlements initialValues={data} />;
  }
  return null;
};
