import React from "react";
import axios from "axios";
import { getToken } from "../../../auth";

//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;

const getRatings = async (id: any,filterData:any) => {
  const response = await axios
    .post(`${API_URL}chefPortal/chefRatings/${id}`,filterData);
    console.log("getRatings-apii",response.data)
  return response.data;
};

export {
  getRatings,

  
};
