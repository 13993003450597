import {useEffect, useRef, FC, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {  orderList } from '../../core/_requests';
import {useAuth} from '../../core/Auth'
import {} from '../../../../.'
interface Dish {
    uuid:string;
    orderId:string;
    netValue:any;
    orderstatus: {
        name: string;
    } ;
    orderdetails: [{
        dish:{
            name:string;
        }
        quantity:string;
    }];
}
const RecentOreder = () => {
  const [data, setData] = useState<Dish[]>([]);
  const { currentUser } = useAuth();
  const navigate= useNavigate()
  const chefId =currentUser?.uuid
  const viewDetails=()=>{
    navigate('/orders/view')
  }

  const viewOrder=(id:any)=>{
    console.log("/orders/view==",id)
//    navigate('/orders/view'+id)
   navigate('/orders/view')
 }
 
 useEffect(() => {
   
    orderList(chefId).then((res: any) => {
    setData(res);
  });
}, []);



  return (
<div className="snippet-block recent-order">
    <div className="head">
        <h6 className="mb-0 fw-bold">Recent Orders</h6>
            <a href="javascript:;" className="text-link" onClick={viewDetails}>
                 View All
                <i className="material-symbols-outlined fall">
                      arrow_forward
                </i>
            </a>
    </div>
    <div className="ro-snippet-wrap">
     {data.length > 0 ? (
         data.map((item, i) => (
            <div className="card-wrap mb-3">
                <p className="order-id">
                    <span>Order {item?.orderId}</span><span>$ {item?.netValue.toFixed(2)}</span>
                </p>
                <a href="javascript:;" className="card">
                    <div className="row">
                    <div className="col-7">
                        {item?.orderdetails.map ((orderDetail, j) => (
                            <span key={j} className="item text-truncate">
                                {orderDetail.quantity} x {orderDetail.dish?.name}
                            </span>
                        ))}
                    </div>
                        <div className="col-5 d-flex align-items-center justify-content-end">
                        <span className={`status badge ${item?.orderstatus?.name?.toLowerCase()}`}>
                            {item?.orderstatus?.name}
                        </span>

                                <i className="material-symbols-outlined go-ico" 
                                onClick={()=>viewOrder(item?.uuid)}
                                >
                                     chevron_right
                                </i>
                        </div>
                    </div>
                </a>
            </div>
         ))
    ):(
     <div className="text-center my-50px">
            <div className="thumbnail ">
                <img src='../../../assets/images/noDishes.svg' style={{height:"100px"}} />
             </div>
                No Recent Orders
    </div>)
    }                                                                        
    </div>
    </div>
  )
}
export {RecentOreder}
