import React, { useEffect, useState } from "react";
import "../../../assets/custom-style.css";
import { useParams } from "react-router-dom";
import { getDishView, listIngredient } from "../core/_requests";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import Header from "../../sidebar/Header";

interface Ingredient {
  id: string;
  name: string;
}

const ViewDishes = () => {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [dish, setDish] = useState<any>([]);
  const [ingredient, setIngredient] = useState<Ingredient[]>([]);
  useEffect(() => {
    getDishView(id)
      .then((res) => {
        setDish(res.data);
        // console.log("res===res.data", res);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  const chef = {
    chefId: currentUser?.uuid || "",
  };
  console.log("dishView=>", dish);
  useEffect(() => {
    listIngredient(chef)
      .then((res) => {
        setIngredient(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching ingredient:", error);
      });
  }, []);
  const clickCancel = () => {
    navigate("/dish");
  };

  const ingiretientData: string[] = dish?.ingredients
    ? dish.ingredients.split(",")
    : [];
  console.log("ingiretientData===12345", ingiretientData);
  console.log("ingredient===12345", ingredient);
  const editDish = () => {
    navigate("/dish/edit/" + id);
  };
  const deleteDish = () => {
    navigate("/dish/delete/" + id);
  };
  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <Header />
          <div className="overlay"></div>
          <div className="drawer small add-dish">
            <div className="dish-detail-wrap">
              <div className="detail-main">
                <div className="dish-img mb-3">
                  <img src={dish?.dishImageUpdate} alt={dish?.name} />
                </div>
                <div className="row">
                  <div className="col-9">
                    <span className="type mb-2">
                      <img src={dish?.type?.icon} />
                      {dish?.type?.dishname}
                    </span>
                    <h3 className="medium mb-0">{dish?.name}</h3>
                    <span className="rating">
                      <i className="bi bi-star text-warning fs-6"></i>
                      <i className="bi bi-star-fill"></i>
                    </span>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-end">
                    <h4 className="medium mb-0 text-end">
                      {dish?.dishvariants &&
                        dish?.dishvariants.map(
                          (dishvariants: any, index: any) => (
                            <div className="text mb-2" key={index}>
                              <span className="price">
                                ${dishvariants?.finalPrice}
                              </span>
                            </div>
                          )
                        )}
                    </h4>
                  </div>
                </div>
                <div className="tags mt-3 mb-3 pb-3">
                  <span>{dish?.type?.dishname}</span>
                  <span>{dish?.category?.name}</span>
                  <span>{dish?.cuisine?.name}</span>
                </div>
                <p className="mid-gray">
                  <p className="fs-6 fw-bold mb-2">
                    <small>Description</small>
                  </p>
                  <span className="tiny">{dish?.description} </span>
                </p>

                <div className="mb-2">
                  <p className="fs-6 fw-bold mb-2">
                    <small>Ingredients</small>
                  </p>
                  {ingredient
                    .filter((item) =>
                      ingiretientData.includes(item.id.toString())
                    ) // Filter ingredients based on IDs in ingiretientData
                    .map((foundIngredient, index) => (
                      <span className="tiny" key={index}>
                        {foundIngredient.name} ,
                      </span>
                    ))}
                </div>

                <div className="addon-text">
                  <p className="fw-med mb-2">
                    <small>Addons</small>
                  </p>
                  {dish?.addons &&
                    dish?.addons.map((addon: any, index: any) => (
                      <div className="text mb-2" key={index}>
                        <p className="mid-gray mb-0 mx-2">
                          <small>{addon?.name}</small>
                        </p>
                        <span className="price">${addon?.price}</span>
                      </div>
                    ))}
                </div>
              </div>
              <div className="bottom-bar mt-3 pt-3">
                <a
                  href="javascript:;"
                  className="me-3 danger"
                  onClick={deleteDish}
                >
                  <i className="material-symbols-outlined">delete</i>
                  Delete
                </a>
                <a href="javascript:;" onClick={editDish}>
                  <i className="material-symbols-outlined">edit</i>
                  Edit
                </a>
              </div>
              <button
                className="btn btn-primary rounded-5 footer mt-5"
                onClick={clickCancel}
              >
                Go to Home
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewDishes;
