import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState, SetStateAction, useMemo, useRef } from "react";
import clsx from "clsx";
import { ChefProfile, useAuth } from "../../../auth";
import { ProfileDetails, UpdateProfile } from "../core/_requests";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../../sidebar/Header";
type Props = {
  initialValues: any;
};

const BioUpdate: FC<Props> = ({ initialValues }) => {
  const [data, setData] = useState<ChefProfile>(initialValues);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      formik.handleSubmit(); // Submit the form when Enter is pressed
    }
  };
  const chefId =currentUser?.uuid
  const clickCancel = () => {
    navigate("/settings");
  };
  useEffect(() => {
    ProfileDetails(chefId)
      .then((res) => {
        setData(res?.data);   
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const Schema = Yup.object().shape({
    chefProfile:Yup.string()
   
    .max(50, "minimum 10 letters")
    .max(500, "Maximum 500 letters")
      .required("Enter Your Bio Description"),
  });

  const formik = useFormik<ChefProfile>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, actions) => {
      setLoading(true);
      console.log("values", values);
      const updatedData = Object.assign(data, values);
      console.log("updatedData", updatedData);

      UpdateProfile(updatedData, chefId).then((res) => {
        console.log("dsdsds", res);
        if (res?.status === true) {
          navigate("/settings/bio-success", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    },
  });

  return (
    <>
      <section className="container-fluid">
        <div className="row">
             <Header />
          <section className="content-section">      
            <div className="overlay"></div>
            <div className="drawer small">
              <div className="title row">
                <h3 className="col-10 mb-0">Short Bio</h3>
                <div className="col-2 text-end">
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={clickCancel}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </button>
                </div>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="row mt-4 add-bank-form"
              >
                <div className="col-12 mb-3">
                  <label htmlFor="" className="form-label">
                    Tell about yourself & Cooking style
                  </label>
                  <textarea
                    className={clsx(
                      "form-control textarea",
                      {
                        "is-invalid":
                          formik.touched.chefProfile &&
                          formik.errors.chefProfile,
                      },
                      {
                        "is-valid":
                          formik.touched.chefProfile &&
                          !formik.errors.chefProfile,
                      }
                    )}
                    placeholder="Give a short bio..."
                    {...formik.getFieldProps(`chefProfile`)}
                    cols={30} // Change this line
                    rows={10} // Change this line
                    onKeyDown={handleKeyPress} 
                  ></textarea>
                </div>
                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-cancel rounded-5"
                        onClick={clickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={formik.isSubmitting || !formik.isValid}
                        className="btn btn-outline-primary rounded-5 me-2"
                      >
                        {!loading && (
                          <span className="indicator-label">Publish</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};
export { BioUpdate };
