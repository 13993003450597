import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ListSettings from "./files/ListSettings";
import {
  BannerWrapper,
  BioWrapper,
  BrandWrapper,
  ChangePasswordWrapper,
  ChefTimingWrapper,
  ProfilePicUpdateWrapper,
  ProfileUpdateWrapper,
  SoundUpdateWrapper,
} from "./files/BioWrapper";
import { ChangePassword } from "./files/ChangePassword";
import PasswordSuccess from "./files/PasswordSuccess";
import BannerSuccess from "./files/BannerSuccess";
import ProfileSuccess from "./files/ProfileSuccess";
import BioSuccess from "./files/BioSuccess";
import { ProfilePicUpdate } from "./files/ProfilePic";
import ProfilePicSuccess from "./files/ProfilePicSuccess";
import { UpdateBanner } from "./files/BannerPicture";

const SettingsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <ListSettings />
            </>
          }
        />
        <Route
          path="/profile-details"
          element={
            <>
              <ProfileUpdateWrapper />
            </>
          }
        />
        <Route
          path="/profile-success"
          element={
            <>
              <ProfileSuccess />
            </>
          }
        />
        <Route
          path="/sound-update"
          element={
            <>
              <SoundUpdateWrapper />
            </>
          }
        />
        <Route
          path="/update-profile"
          element={
            <>
              <ProfilePicUpdateWrapper />
            </>
          }
        />
        <Route
          path="/profile-success"
          element={
            <>
              <ProfilePicSuccess />
            </>
          }
        />
        <Route
          path="/change-timing"
          element={
            <>
              <ChefTimingWrapper />
            </>
          }
        />
        <Route
          path="/change-password"
          element={
            <>
              <ChangePasswordWrapper />
            </>
          }
        />
        <Route
          path="/password-success"
          element={
            <>
              <PasswordSuccess />
            </>
          }
        />
        <Route
          path="/bio-update"
          element={
            <>
              <BioWrapper />
            </>
          }
        />
        <Route
          path="/bio-success"
          element={
            <>
              <BioSuccess />
            </>
          }
        />
        <Route
          path="/brand-update"
          element={
            <>
              <BrandWrapper />
            </>
          }
        />
        <Route
          path="/brand-success"
          element={
            <>
              <BioSuccess />
            </>
          }
        />
        v
        <Route
          path="/change-banner"
          element={
            <>
              {/* <UpdateBanner /> */}
              <BannerWrapper />
            </>
          }
        />
        <Route
          path="/banner-success"
          element={
            <>
              <BannerSuccess />
            </>
          }
        />
        <Route index element={<Navigate to="list" />} />
      </Route>
    </Routes>
  );
};

export default SettingsPage;
