import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { useAuth } from '../../core/Auth';
import { graphData } from '../../core/_requests';

const Graph = () => {
  const { currentUser } = useAuth();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [data, setData] = useState<number[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const mode = "light";
  const chefId =currentUser?.uuid
  useEffect(() => {
    console.log("Fetching graph data...");
    graphData(chefId).then((response) => {
      console.log("Graph data response:", response);
      console.log("Graph data response.data:", response.data);
      setData(response.data);
      setCategories(response.date);
    });
  }, [chefId]);

  useEffect(() => {
    console.log("Rendering chart...");
    if (!chartRef.current) {
      return;
    }
    const height = 250;
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, categories, data));
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, categories, data]);
   
  console.log("Categories:", categories);
  console.log("Data:", data);
  
  return (
    <div className={`card border-0`}>
      <div className='card-body p-0'>
        <div ref={chartRef}></div>
      </div>
    </div>
  );
};

export { Graph };


function getChartOptions(height: number, categories: string[], data: number[]): ApexOptions {
  const options: ApexOptions = {
    series: [
      {
        name: 'Order Statistics',
        data: data,
      },
    ],
    chart: {
      height: 185,
      type: 'area',
    },
    stroke: {
      curve: 'smooth',
    },
    dataLabels: {
      enabled: false,
    },

    labels: categories, // Use categories for x-axis labels
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    xaxis: {
      type: 'category', // Change type to 'category' since you're using date strings
      labels: {
        show: true, // Show labels on x-axis
        rotate: 0, // Set the rotation angle if needed
        formatter: function (value) {
          // You can customize the date format here if needed
          return new Date(value).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
        }
      },
    },
    yaxis: [
      {
        title: {
          text: 'Order Statistics',
        },
      },
    ],
    colors: [
      '#25C79B'
    ],
  };
 
  return options;
}
