import axios from "axios";
import { getToken } from "../../../auth";
//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;
const COUPON_URL = `${API_URL}chefPortal`;

const listCouponData = async (ChefId: any, filterData: any) => {
  const response = await axios.post(`${API_URL}chefPortal/listcoupon/${ChefId}`,filterData );
  return response.data;
};

const getCategory = async () => {
  const res = await axios.get(
    `${API_URL}chefPortal/listCategory`
  );
  return res;
};

const getCuisine = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getCuisine`);
  return res;
};

const updateCoupon = async (id: any,updatedData: any) => {
  const res = await axios.put(`${COUPON_URL}/update-coupon/${id}`, updatedData);
  return res.data;
};

const deleteCoupon = async (id: any) => {
  const response = await axios.put(`${COUPON_URL}/delete-coupon/${id}`);
  return response.data;
};
const enableStatus = async (id: any, enableDisableStatus: any) => {

  const response = await axios
    .put(`${API_URL}chefPortal/enableDisableCoupon/${id}`, enableDisableStatus);
  return response.data;
};

const couponCreate = async (data:any) => {

  const res = await axios.post(`${COUPON_URL}/add-coupon`, data);
  return res.data;
};

const viewCouponById = async (id: any) => {
  const response = await axios.get(`${COUPON_URL}/view-coupon/${id}`, getToken());
  return response.data;
};

const checkDuplicateCoupon = async (name: any) => {
  console.log("name",name)
  const response = await axios
    .post(`${COUPON_URL}/find-duplicate`, { name }, getToken());
  return response.data;
};

export {
  listCouponData,
  getCategory,
  getCuisine,
  couponCreate,
  updateCoupon,
  deleteCoupon,
  viewCouponById,
  enableStatus,
  checkDuplicateCoupon,
};
