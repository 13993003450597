import { useNavigate } from "react-router-dom";
import ListCoupon from "./ListCoupon";

const EditSuccess = () => {
  const navigate = useNavigate();
  return (
    <section className="container-fluid">
      <ListCoupon />
      <div className="overlay"></div>
      <div className="drawer small">
        <div className="alert-wrap">
          <img
            className="ico mb-4"
            src="../dish_images/alert-success-ico.svg"
            alt="success"
          />
          <h1 className="mb-4">Awesome!</h1>
          <p className="mid-gray mb-2">
            <small>Your Coupon detail Edited  successfully</small>
          </p>
          <h6 className="medium mb-4">
            It will published once verification done
          </h6>
          
          <button
            className="btn btn-cancel rounded-5"
            onClick={() => navigate("/coupon")}
          >
            Back to Home
          </button>
        </div>
      </div>
    </section>
  );
};
export default EditSuccess;
