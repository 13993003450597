import axios from "axios";
import { getToken } from "../../../auth";
//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;
const SETTLEMENTS_URL = `${API_URL}chefPortal`;

const listTiming = async (id: any) => {
  return await axios
    .get(`${API_URL}chefPortal/timing-get/${id}`, getToken())
    .then((response) => {
      console.log("data======listtime-api", response.data);
      return response.data;
    });
};

const updateTiming = async (data: any) => {
  console.log("updateTiming---data--apii", data);
  return await axios
    .post(`${API_URL}chefPortal/timing-update`, data, getToken())
    .then((response) => {
      console.log("updateTiming--res---api", response.data);
      return response.data;
    });
};

const ProfileDetails = async (chefId: any) => {
  const response = await axios.get(`${API_URL}chefPortal/chef/view/${chefId}`, getToken());
  return response.data;
};

const UpdateProfile = async (data: any, chefId: any) => {
  const response = await axios.put(
    `${API_URL}chefPortal/ChefDetailsUpdate/${chefId}`,
    data, getToken()
  );
  return response.data;
};
const BannerPictureUpdate = async (data: FormData, chefId: any) => {
  const response = await axios.put(
    `${API_URL}chefPortal/ChefBannerUpdate/${chefId}`,
    data, getToken()
  );
  return response.data;
};
const UpdatePassword = async (data: any, chefId: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/password-update/${chefId}`,
    data, getToken()
  );
  return response.data;
};
const BannerDetails = async (id: any) => {
  const response = await axios.get(`${API_URL}chefPortal/chefBannerList/${id}`, getToken());
  return response.data;
};
const forgotPassword = async (data: any) => {
  console.log("data===========", data);
  const response = await axios.post(
    `${API_URL}chefPortal/forgot-password`,
    data, getToken()
  );
  return response.data;
};
const decryptData = async (userData: any) => {
  const response = await axios.post(`${API_URL}chefPortal/decryptData`, {
    data: userData
  }, getToken());
  return response.data;
};
const addressUserFetch = async (data: any) => {
  const response = await axios.post(`${API_URL}chefPortal/addressFetch`, data, getToken());
  console.log("response.data.data", response.data.data);
  return response.data.data;
};

const callGooglePlacesApi = async (address: any) => {
  const response = await axios.get(
    `${API_URL}chefPortal/locationAPIFetch?input=${address}`, getToken()
  );
  return response.data;
};

const locationLatLongAPIFetch = async (place_id: any) => {
  const response = await axios.get(
    `${API_URL}chefPortal/locationLatLongAPIFetch?input=${place_id}`, getToken()
  );
  return response.data;
};
const SoundUpdateAPI = async (data: FormData, chefId: any) => {
  const response = await axios.put(
    `${API_URL}chefPortal/ChefSoundUpdate/${chefId}`,
    data, getToken()
  );
  return response.data;
};
const getSounds = async () => {
  const res = await axios.get(`${API_URL}chefPortal/get-SoundList`, getToken());
  return res;
};
export {
  listTiming,
  updateTiming,
  UpdateProfile,
  ProfileDetails,
  BannerDetails,
  BannerPictureUpdate,
  decryptData,
  forgotPassword,
  UpdatePassword,
  addressUserFetch,
  locationLatLongAPIFetch,
  callGooglePlacesApi,SoundUpdateAPI,getSounds
};
