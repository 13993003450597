import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import "../../../assets/custom-style.css";
import { useAuth } from "../../../auth";

import {
  getCategory,
  getCuisine,
  couponCreate,
  checkDuplicateCoupon,
} from "../core/_requests";

import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";
import ListCoupon from "./ListCoupon";


interface Cuisine {
  uuid: UUID;
  name: string;
}

interface Type {
  uuid: UUID;
  name: string;
}

interface Category {
  uuid: UUID;
  name: string;
}

interface CouponAdd {
  chefId: string | undefined;
  name: string;
  amount: string;
  limit: string;
  minimumOrderValue: string;
  usagePerUser: string;
  categoryId: string;
  cuisineId: string;
  startDate: string;
  endDate: string;
  couponType: string;
  createdBy: any;
  updatedBy: any;
}

const initialValues: CouponAdd = {
  chefId: "",
  name: "",
  amount: "",
  categoryId: "",
  cuisineId: "",
  limit: "",
  minimumOrderValue: "",
  usagePerUser: "",
  startDate: "",
  endDate: "",
  createdBy: "",
  updatedBy: "",
  couponType: ""
};

const AddCoupon = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [data, setData] = useState<CouponAdd>(initialValues);
  const [loading, setLoading] = useState(false);
  const [cuisine, setCuisine] = useState<Cuisine[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [cuisineId, setCuisineId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [Duplicate, setDuplicate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentDate] = useState(new Date());
  const chefId =currentUser?.uuid
  const [placeholderText, setPlaceholderText] = useState('Enter coupon value');
  const inputs = useRef<Array<HTMLInputElement | HTMLSelectElement | null>>([]);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (index < inputs.current.length - 1) {
        // If not the last input field, focus on the next input
        const nextIndex = index + 1;
        inputs.current[nextIndex]?.focus();
      } else {
        // If the last input field, submit the form
        formik.handleSubmit();
      }
    }
  };
  useEffect(() => {
    getCuisine()
      .then((res) => {
        //  console.log(res?.data?.data);
        setCuisine(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching financial year:", error);
      });
    
  }, []);

  useEffect(() => {
    getCategory()
      .then((res) => {
        setCategory(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  
  const clickCancel = () => {
    navigate("/coupon");
  };
 
  const Schema = Yup.object().shape({
    name: Yup.string()
      .max(50, "Maximum 50 letters")
      .required("Name is required"),
    categoryId: Yup.string().required("Category is required"),
    amount: Yup.string()
      .matches(/^[1-9]\d{0,5}$/, "Amount must be up to 6 digits and greater than 0")
      .required("Coupon value is required"),
    cuisineId: Yup.string().required("Cuisine is required"),
    limit: Yup.string()
      .matches(/^[1-9]\d{0,5}$/, "Coupon Limit must be up to 6 digits and greater than  0")
      .required("Coupon Limit is required"),
    minimumOrderValue: Yup.string()
      .matches(/^[1-9]\d{0,5}$/, "Minimum Order Value must be up to 6 digits and greater than 0")
      .required("Minimum Order Value is required"),
    usagePerUser: Yup.string()
      .matches(/^[1-9]\d*$/, "Usage Per User must be a number greater than  0")
      .required("Usage Per User is required"),
    startDate: Yup.string().required("Coupon Start Date is required"),
    endDate: Yup.string().required("Coupon End Date is required"),
});

  const formik = useFormik<CouponAdd>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values,{ resetForm }) => {
      values.chefId = chefId;
      values.createdBy = currentUser?.chefId;
      values.updatedBy = currentUser?.chefId;
      const updatedData = Object.assign(data, values);
      // //Image and data Append to Form Data
      setLoading(true);
      couponCreate(updatedData).then((res) => {
        if (res?.status === true) {
          navigate("/coupon/success", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
          setErrorMessage(res?.message);
        }
      });
      resetForm();
    },
  });

  useEffect(() => {
    checkDuplicateCoupon(formik.values.name).then((response) => {
      if (response?.isDuplicate === true) {
        setDuplicate("Coupon name already exist");
      } else {
        setDuplicate("");
      }
    });
}, [formik.values.name]);


  const nextDate = new Date();
  nextDate.setDate(currentDate.getDate() );

  console.log("coupon  formik", formik.values);

  return (
    <>
      <section className="container-fluid addContainerFluid">
        <ListCoupon />
        <div className="overlay"></div>
        <div className="drawer small">
          <div className="title row">
            <h3 className="col-10 mb-0">Add New Coupon</h3>
            <div className="col-2 text-end mb-2">
              <button
                className="btn p-0 close d-inline-flex"
                onClick={clickCancel}
              >
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
               Coupon Code
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.name && formik.errors.name,
                  },
                  {
                    "is-valid": formik.touched.name && !formik.errors.name,
                  }
                )}
                ref={(el) => (inputs.current[3] = el)}
                onKeyDown={e => handleKeyPress(e, 3)}
                placeholder="Give a code to coupon"
                {...formik.getFieldProps(`name`)}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-help-block text-danger">{formik.errors.name}</div>
              )}
              <span style={{ color: "red" }}>{Duplicate ? Duplicate : ""}</span>
            </div>
            {/**Begin:  coupon value */}
            <div className="row mb-12  mb-3 mt-3">
            <label htmlFor="" className="form-label">
               Coupon Value
              </label>
              <div className="col-lg-6">
                <select
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("couponType")}
                  ref={(el) => (inputs.current[3] = el)}
                  onKeyDown={e => handleKeyPress(e, 3)}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    formik.handleChange(e);
                    
                    if (selectedValue === '1') {
                      setPlaceholderText('Enter Percentage (%)');
                    } else if (selectedValue === '2') {
                      setPlaceholderText('Enter Amount ($)');
                    } else {
                      setPlaceholderText('Enter coupon value');
                    }
                  }}
                >
                  <option value="">Select  Type...</option>
                  <option value={1}>Percentage</option>
                  <option value={2}>Amount</option>
                </select>
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  className="capitalize-text form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder={placeholderText}
                  
                  ref={(el) => (inputs.current[3] = el)}
                  onKeyDown={e => handleKeyPress(e, 3)}
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formik.errors.amount}</div>
                  </div>
                )}
              </div>
            </div>
            {/**End:  coupon value */}
            <div className="row  mb-3 mt-3 col-12">
              <label htmlFor="" className="form-label">
              Validity Perioder
              </label>
              <div className="col-lg-6 ">
                <input
                  type="datetime-local"
                  className="capitalize-text form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Enter coupon start date"
                  min={nextDate.toISOString().slice(0, 16)}
                  ref={(el) => (inputs.current[3] = el)}
                  onKeyDown={e => handleKeyPress(e, 3)}
                  {...formik.getFieldProps("startDate")}
                />
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.startDate}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <input
                  type="datetime-local"
                  className="capitalize-text form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Enter coupon validity end date"
                  min={nextDate.toISOString().slice(0, 16)}
                  ref={(el) => (inputs.current[3] = el)}
                  onKeyDown={e => handleKeyPress(e, 3)}
                  {...formik.getFieldProps("endDate")}
                />
                {formik.touched.endDate && formik.errors.endDate && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formik.errors.endDate}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
              Coupon Limit
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.limit && formik.errors.limit,
                  },
                  {
                    "is-valid": formik.touched.limit && !formik.errors.limit,
                  }
                )}
                placeholder="Give a limit to coupon"
                ref={(el) => (inputs.current[3] = el)}
                onKeyDown={e => handleKeyPress(e, 3)}
                {...formik.getFieldProps(`limit`)}
              />
               {formik.touched.limit && formik.errors.limit && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formik.errors.limit}</div>
                  </div>
                )}
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
              Minimum Order Value
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.minimumOrderValue && formik.errors.minimumOrderValue,
                  },
                  {
                    "is-valid": formik.touched.minimumOrderValue && !formik.errors.minimumOrderValue,
                  }
                )}
                placeholder="Give a  minimum order value"
                ref={(el) => (inputs.current[3] = el)}
                onKeyDown={e => handleKeyPress(e, 3)}
                {...formik.getFieldProps(`minimumOrderValue`)}
              />
               {formik.touched.minimumOrderValue && formik.errors.minimumOrderValue && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formik.errors.minimumOrderValue}</div>
                  </div>
                )}
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
              Usage Per User
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.usagePerUser && formik.errors.usagePerUser,
                  },
                  {
                    "is-valid": formik.touched.usagePerUser && !formik.errors.usagePerUser,
                  }
                )}
                placeholder="Give a  usage per user "
                ref={(el) => (inputs.current[3] = el)}
                onKeyDown={e => handleKeyPress(e, 3)}
                {...formik.getFieldProps(`usagePerUser`)}
              />
                {formik.touched.usagePerUser && formik.errors.usagePerUser && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formik.errors.usagePerUser}</div>
                  </div>
                )}
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Cuisine
              </label>
              <select
                className={clsx(
                  "form-control form-select",
                  {
                    "is-invalid":
                      formik.touched.cuisineId && formik.errors.cuisineId,
                  },
                  {
                    "is-valid":
                      formik.touched.cuisineId && !formik.errors.cuisineId,
                  }
                )}
                ref={(el) => (inputs.current[3] = el)}
                onKeyDown={e => handleKeyPress(e, 3)}
                onChange={(e) => {
                  formik.setFieldValue("cuisineId", e.target.value);
                  setCuisineId(e.target.value);
                }}
              >
                <option selected>Choose...</option>
                {cuisine.map((item, i) => {
                  return (
                    <option value={item?.uuid} key={i}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.cuisineId && formik.errors.cuisineId && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formik.errors.cuisineId}</div>
                  </div>
                )}
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Category
              </label>
              <select
                className={clsx(
                  "form-control form-select",
                  {
                    "is-invalid":
                      formik.touched.categoryId && formik.errors.categoryId,
                  },
                  {
                    "is-valid":
                      formik.touched.categoryId && !formik.errors.categoryId,
                  }
                )}
                ref={(el) => (inputs.current[3] = el)}
                onKeyDown={e => handleKeyPress(e, 3)}
                {...formik.getFieldProps(`categoryId`)}
              >
                <option selected>Choose...</option>
                {category.map((item, i) => {
                  return (
                    <option value={item?.uuid} key={i}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.categoryId && formik.errors.categoryId && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">{formik.errors.categoryId}</div>
                  </div>
                )}
            </div>
           
            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-8 text-end">
                  <button
                    className="btn btn-cancel rounded-5"
                    onClick={clickCancel}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={formik.isSubmitting || !formik.isValid}
                    className="btn btn-outline-primary rounded-5 me-2"
                  >
                    {!loading && (
                      <span className="indicator-label">Create</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                 
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddCoupon;
