import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import "../../../assets/custom-style.css";
import { useAuth } from "../../../auth";
import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";
import { Addons, initialValues } from "../core/_models";
import { CreateAddons, checkDuplicateAddon, getType } from "../core/_requests";
import ListAddons from "./ListAddon";

interface Type {
  uuid: UUID;
  name: string;
}
const AddAddons = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [data, setData] = useState<Addons>(initialValues);
  const [loading, setLoading] = useState(false);
  const [Duplicate, setDuplicate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeType, setActiveType] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [type, setType] = useState<Type[]>([]);
  const chefId = currentUser?.uuid;
  useEffect(() => {
    getType()
      .then((res) => {
        //  console.log(res?.data?.data);
        setType(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching financial year:", error);
      });
  }, []);

  const clickCancel = () => {
    navigate("/addons");
  };

  const Schema = Yup.object().shape({
    name: Yup.string()
      .max(50, "Maximum 50 letters")
      .required("Name is required"),
      typeId: Yup.string().required("Type is required"),
      amount: Yup.number()
      .required("Price is required")
      .typeError("Price must be a number")
      .test(
        "is-floating-number",
        "Price must be a floating-point number",
        (value) => {
          const stringValue = String(value);
          return (
            !isNaN(parseFloat(stringValue)) && /^\d*\.?\d*$/.test(stringValue)
          );
        }
      ),
  });

  const formik = useFormik<Addons>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      values.chefId = currentUser?.uuid;
      values.createdBy = currentUser?.chefId;
      values.updatedBy = currentUser?.chefId;
      const updatedData = Object.assign(data, values);
      // //Image and data Append to Form Data
      setLoading(true);
      CreateAddons(updatedData).then((res) => {
        if (res?.status === true) {
          navigate("/addons/success", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
          setErrorMessage(res?.message);
        }
      });
      resetForm();
    },
  });


  const handleNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    formik.setFieldValue("name", value);
    formik.validateField("name");
    if (value) {
      const response = await checkDuplicateAddon(value, chefId);
      if (response?.isDuplicate) {
        setDuplicate("Addons name already exist");
      } else {
        setDuplicate("");
      }
    } else {
      setDuplicate("");
    }
  };
  useEffect(() => {
    const emptyValues: Addons = {
      primaryId:"",
      name: "",
      chefId: "",
      typeId: "",
      amount:0,
      enableDisableStatus: "",
      createdBy: 0,
      updatedBy: 0,
     
    };
    formik.setValues(emptyValues);
    console.log("initialValues--use", initialValues);
  }, []);
  return (
    <>
      <section className="container-fluid addContainerFluid">
        <ListAddons />
        <div className="overlay"></div>
        <div className="drawer small">
          <div className="title row">
            <h3 className="col-10 mb-0">Add New Addons</h3>
            <div className="col-2 text-end mb-2">
              <button
                className="btn p-0 close d-inline-flex"
                onClick={clickCancel}
              >
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Addons Name
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.name && formik.errors.name,
                  },
                  {
                    "is-valid": formik.touched.name && !formik.errors.name,
                  }
                )}
                placeholder="Give a addon name"
                {...formik.getFieldProps(`name`)}
                onChange={handleNameChange}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-help-block text-danger">
                  {formik.errors.name}
                </div>
              )}
              <span style={{ color: "red" }}>{Duplicate ? Duplicate : ""}</span>
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Type
              </label>
              <div className="row type-snippet">
                {type.map((item: any, i) => {
                  const isActive = activeType === item?.name;
                  return (
                    <div
                      className={`mt-1 snippet ${isActive ? "active" : ""}`}
                      key={i}
                      onClick={() => {
                        formik.setFieldValue("typeId", item?.uuid);
                        setActiveType(item?.name);
                        setSelectedType(isActive ? "" : item?.name);
                      }}
                    >
                      <span>
                        <img src={item?.icon} alt={item?.name} />{" "}
                        {item?.name}
                      </span>

                      <div className="ico">
                        <img
                          className={isActive ? "tick" : "cross"}
                          src={`../dish_images/${
                            isActive ? "tick" : "cross"
                          }-circle-ico.svg`}
                          alt="ico"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {formik.touched.typeId && formik.errors.typeId && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.typeId}</span>
                </div>
              )}
            </div>
            
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Amount
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.amount && formik.errors.amount,
                  },
                  {
                    "is-valid": formik.touched.amount && !formik.errors.amount,
                  }
                )}
                placeholder="Give a addon amount"
                {...formik.getFieldProps(`amount`)}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className="fv-help-block text-danger">
                  {formik.errors.amount}
                </div>
              )}
            </div>
            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-8 text-end">
                  <button
                    className="btn btn-cancel rounded-5"
                    onClick={clickCancel}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={formik.isSubmitting || !formik.isValid}
                    className="btn btn-outline-primary rounded-5 me-2"
                  >
                    {!loading && (
                      <span className="indicator-label">Create</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddAddons;
