import React, { useEffect, useState } from "react";
import "../../../assets/custom-style.css";

import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";
import { listOrdersData, currentlyAvailable } from "../core/_requests";
import { Route, useNavigate } from "react-router-dom";
import MenuOrders from "./MenuOrders";


interface Orders {
  primaryId: number;
  orderstatusId: number;
  uuid: string;
  orderId: string;
  chefId: string;
  userId: string;
  amount: number;
  tax: number;
  deliveryCharge: number;
  deliveryType: number;
  couponApplied: boolean;
  couponAmount: number | null;
  walletDiscount: number | null;
  deliveryPersonTips: number;
  chefTips: number;
  paymentMethod: string;
  netValue: number;
  orderDate: string;
  orderTime: string;
  totalAmount: number;
  orderstatus: {
    name: string;
  };
  chef: {
    name: string;
    currentlyAvailableStatus: boolean;
    email: string;
    phoneNumber: string | null;
    phoneNumber1: string | null;
    mobileNumber: string;
    mobileNumber1: string | null;
    location: string | null;
    licence: string | null;
    chefProfile: string | null;
    approvalStatus: boolean;
    address1: string;

    city: string;
    state: string;
    country: string;
    zipCode: string;
    status: number;
    enableDisableStatus: boolean;
  };
  orderdetails: [
    {
      id: number;
      orderId: string;
      dishId: string;
      quantity: number;
      amount: number;
      dish: {
        name: string;
        dishImage: string;
        price: number;
        addons: [
          {
            id: number;
            name: string;
            quantity: number;
            price: number;
          }
        ];
      };
    }
  ];
  user: {
    id: number;
    uuid: string;
    userProfile: string;
    deviceId: string | null;
    token: string;
    verifyStatus: string | null;
    deviceType: string | null;
    deviceToken: string | null;
    loginType: string | null;
    name: string;
    email: string;
    latitude: string | null;
    longitude: string | null;
    contactNumber: string;
    plansId: string | null;
    planStartDate: string | null;
    planEndDate: string | null;
    planStatus: string | null;

    addressbooks: [
      {
        label: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        country: string;
        zipcode: any;
      }
    ];
  };
}
interface Status {
  id: number;
  name: string;
}
const Success = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [listOrders, setListOrders] = useState<Orders[]>([]);
  const [loading, setLoading] = useState(true);
  const [chefStatus, setChefStatus] = useState(true); // Default to false assuming chef is initially offline
  const [showNotification, setShowNotification] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const chefId =currentUser?.uuid
  useEffect(() => {
    setChefStatus(currentUser?.currentlyAvailableStatus ?? false); // Set the chef status based on the current user's availability status
  }, [currentUser?.currentlyAvailableStatus]);
  useEffect(() => {
    if (chefStatus) {
      setNotificationMessage("You are online now");
    } else {
      setNotificationMessage("You are offline");
    }

    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(true);
    }, 60000);
  }, [chefStatus]);

  useEffect(() => {
    listOrdersData(chefId).then((res) => {
      setListOrders(res?.data);
      setLoading(false);
    });
  }, []);

  // Redirect to "/orders/view" if there are orders
  useEffect(() => {
    if (listOrders.length > 0) {
      navigate("/orders/view");
    }
  }, [listOrders, navigate]);
  const redirectToViewOrders = () => {
    navigate("/orders/view");
  };

  return (
    <section className="container-fluid">
      <div className="row">
           {/* <Sidebar />   */}
           <Header />
          <section className="content-section">
            {/* <Header /> */}
          <section className="row main-content">
            <MenuOrders />
       
            {listOrders.length > 0 ? (
              <div className="no-data">
                <div className="img-block mb-4">
                  <img src="../media/online-concept.svg" alt="" />
                </div>
                <h3 className="medium mb-1" data-bs-toggle="modal" data-bs-target="#newOrder">You’re on Fire</h3>
                <h6 className="mid-gray">Waiting for orders</h6>
              </div>
            ) : (
              <div className="no-data">
                <div className="img-block mb-4">
                  <img src="../media/online-concept.svg" alt="" />
                </div>
                <h3 className="medium mb-1">You’re on Fire</h3>
                <h6 className="mid-gray">Waiting for orders</h6>
                <br />
                <button
                  type="button"
                  className="btn btn-primary rounded-5"
                  onClick={redirectToViewOrders}
                >
                  View Orders
                </button>
     
              </div>
            )}
      
          </section>
        </section>
      </div>


    </section>
  );
};
export default Success;
