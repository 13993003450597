import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { ChefRegister } from "../core/_models";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Modal, Button } from "react-bootstrap";
import { SetStateAction, useEffect, useState } from "react";
import {
  addressUserFetch,
  callGooglePlacesApi,
  chefSocialRegisteration,
  locationLatLongAPIFetch,
} from "../core/_requests";
import { useNavigate } from "react-router-dom";
import msgSound from "../../assets/sound/2.mp3";
import { toast } from "react-toastify";

interface LatLng {
  lat: number;
  lng: number;
}

const initialValues = {
  name: "",
  mobileNumber: "",
  email: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  address: "",
  longitude: "",
  latitude: "",

  formImage: "",
  Code: "",
  status: "",
  message: "",
  otpDevice: "",
  token: "",
  notificationSound: msgSound,
};
const libraries = ["places"];
const mapContainerStyle = {
  width: "470px",
  height: "280px",
};

const center = {
  lat: 27.994402,
  lng: -81.760254,
};

const ChooseAddress = (libraries: any) => {
  const navigate = useNavigate();
  const [data, setData] = useState<ChefRegister>(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropdownCLicked, setdropdownCLicked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [googledata, setgoogledata] = useState<any>({});
  const [mapCenter, setMapCenter] = useState(center);
  const [userLocation, setUserLocation] = useState<LatLng | null>(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [addressData, setAddressData] = useState<any>({});
  const [email, setemail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  console.log("chooseADDRESS---email", email);
  console.log("chooseADDRESS---name", name);
  console.log("chooseADDRESS---token", token);
  console.log("chooseADDRESS---addressData", addressData);
  console.log("chooseADDRESS---googledata", googledata);
  console.log("chooseADDRESS---mobileNumber", mobileNumber);
  console.log("chooseADDRESS---initialValues", initialValues);
  useEffect(() => {
    const storedData = localStorage.getItem("data");
    
    console.log("storedData->social", storedData);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      console.log("chooseADDRESS--parsedData", parsedData);
      setemail(parsedData?.email);
      setMobileNumber(parsedData?.mobileNumber);
      setName(parsedData?.name);
      setToken(parsedData?.token);
         // Update Formik values
         formik.setValues({
          ...formik.values,
          name: parsedData?.name,
          email: parsedData?.email,
          mobileNumber: parsedData?.mobileNumber,
          token: parsedData?.token,
        });
    }
   
  }, []);

  const handleClose = () => {
    setShowAlert(false);
  };


  const sigupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .test("domain", "Invalid domain name", function (value) {
        const domain = value?.split("@")[1];
        if (!domain) {
          return false;
        }
        // Define regular expression for valid domain format
        const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return domainRegex.test(domain);
      })
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    name: Yup.string()
      .matches(
        /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
        "Please enter a valid name with only letters and spaces"
      )
      .max(50, "Maximum 50 letters")
      .test(
        "has-two-names",
        "Please enter your first and last name separated by a space",
        (value: string | undefined) => {
          // Split the input value by spaces
          const names: string[] | undefined = value?.split(" ");
          // Check if there are exactly two names
          return names && names.length === 2;
        }
      )
      .required("Name is required"),
    address: Yup.string().required("Address is required"),
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, "Mobile number should contain exactly 10 numbers")
      .test({
        name: "no-repeating-digits",
        message: "Mobile number cannot have repeating 6 consecutive digits",
        test: (value: string | undefined) => {
          if (!value) return true; // Return true for undefined value (if field is not required)
          for (let i = 0; i <= value.length - 6; i++) {
            if (new Set(value.substring(i, i + 6)).size === 1) {
              return false;
            }
          }
          return true;
        },
      })
      .required("Mobile is required"),

  });
  const formik = useFormik<ChefRegister>({
    initialValues,
    validationSchema: sigupSchema,
    onSubmit: async (values, actions) => {
      values.name = name;
      values.email = email;
      values.token = token;

      if (googledata?.cityName) {
        values.address = googledata?.address1;
        values.city = googledata?.cityName;
        values.state = googledata?.stateName;
        values.country = googledata?.countryName;
        values.latitude = googledata?.latitude;
        values.longitude = googledata?.longitude;
        values.zipCode = googledata?.pincode;
      } else {
        values.address = addressData?.address1;
        values.city = addressData?.city;
        values.state = addressData?.state;
        values.country = addressData?.country;
        values.latitude = addressData?.latitude;
        values.longitude = addressData?.longitude;
        values.zipCode = addressData?.pincode;
      }

      const updatedData = Object.assign(data, values);
      localStorage.setItem("chefemail", updatedData?.email);
      localStorage.setItem("name", updatedData?.name);
      localStorage.setItem("mobileNumber", updatedData?.mobileNumber);
      console.log("chooseADDRESS-updatedData=========", updatedData);
  
      setLoading(true);
      chefSocialRegisteration(updatedData).then((res) => {
        if (res?.status === true) {
          toast.success(res?.message, { autoClose: 6000 });

          navigate("/login", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.message, { autoClose: 6000 });
          if (res?.type === "error") {
          } else {
            setErrorMessage(res?.message);
            setShowAlert(true);
          }
        }
      });
    },
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD2lTAmm-smGH6KUW24nRU864_bsFZRUpI",
    libraries,
  });
  const handleLogin = () => {
    navigate("/login");
  };
  const onClickCancel = () => {
    navigate("/");
  };

  const handleGoogleApi = (Inputvalue: any) => {
    formik.setFieldValue("address", Inputvalue);
    if (Inputvalue.length >= 5) {
      callGooglePlacesApi(Inputvalue).then((res: SetStateAction<never[]>) => {
        setSuggestions(res);
      });
    }
  };
  const handleCitySelection = (placeId: any, description: any) => {
    setdropdownCLicked(true);
    formik.setFieldValue("address", description);
    locationLatLongAPIFetch(placeId).then((res: any) => {
      setgoogledata(res);
      console.log(" googledata res", res);
      formik.setFieldValue("zipCode", res?.pincode);
    });
    setSuggestions([]); // Clear suggestions after selection
  };
  console.log(" googledata ", googledata);
  const fetchAddress = async () => {
    const data = mapCenter;
    await addressUserFetch(data).then((res: any) => {
      formik.setFieldValue("address", res?.address);
      formik.setFieldValue("zipCode", res?.pincode);
      setAddressData(res);
    });
    setShowModal(false);
  };
  const handleMapClick = (event: any) => {
    setMapCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleMarkerDragEnd = (event: any) => {
    setMapCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const openMap = () => {
    setShowModal(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setMapCenter({ lat: latitude, lng: longitude });
        setUserLocation({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error getting user location:", error);
      }
    );
  };

  const closeMap = () => {
    setShowModal(false);
  };

  return (
    <div className="container-fluid signup-sec py-3">
      <div className="row">
        <div className="col-xl-5 col-md-5 d-none d-lg-block ">
          <div className="signup-img">
            <img
              src="./assets/images/signup-grid.png"
              className="img-fluid"
              alt="img-grid"
            />
          </div>
        </div>
        <div className="col-xl-7 col-lg-7">
          <div className="right-form px-4 mt-4 d-flex justify-content-between flex-column">
            <div className="logo-wrap">
              <img
                src="./assets/images/logo.svg"
                className="img-fluid mb-5"
                alt="logo"
              />
            </div>{" "}
            <form onSubmit={formik.handleSubmit} className="custom-form">
              <div className="content-sec">
                <div className="row mb-4">
                  <h1 className="mb-5">
                    Elevate Your <span>Culinary Journey</span>
                    <span>Sign Up Now!</span>
                  </h1>
                  {showAlert ? (
                    // <div className="mb-lg-15 alert alert-danger">
                    <div
                      className={`mb-lg-10 alert ${
                        showAlert ? "alert-danger" : "alert-success"
                      }`}
                    >
                      <div className="alert-text font-weight-bold">
                        {errorMessage}
                        <button
                          className="btn p-0 close d-inline-end"
                          onClick={handleClose}
                        >
                          <i className="material-symbols-outlined fs-18  px-10">
                            close
                          </i>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="small-font mb-2"
                    >
                      Full Name
                    </label>
                    <input
                      type="name"
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.name && formik.errors.name,
                        },
                        {
                          "is-valid":
                            formik.touched.name && !formik.errors.name,
                        }
                      )}
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.name}</span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 ">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="small-font  mb-2"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.email && formik.errors.email,
                        },
                        {
                          "is-valid":
                            formik.touched.email && !formik.errors.email,
                        }
                      )}
                      placeholder="Your mail address"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.email}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="small-font mb-2"
                    >
                      Mobile No
                    </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <img
                          src="./assets/images/united-states.png"
                          alt="flag"
                        />{" "}
                        +1
                      </div>
                      <input
                        type="phone"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid":
                              formik.touched.mobileNumber &&
                              formik.errors.mobileNumber,
                          },
                          {
                            "is-valid":
                              formik.touched.mobileNumber &&
                              !formik.errors.mobileNumber,
                          }
                        )}
                        onKeyUp={(e) => {
                          // Cast the event target to HTMLInputElement
                          const target = e.target as HTMLInputElement;

                          // Remove non-numeric characters
                          formik.setFieldValue(
                            "mobileNumber",
                            target.value.replace(/[^0-9]/g, "")
                          );
                        }}
                        placeholder="(XXX) XXX-XXXX"
                        {...formik.getFieldProps("mobileNumber")}
                      />
                    </div>{" "}
                    {formik.touched.mobileNumber &&
                      formik.errors.mobileNumber && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">{formik.errors.mobileNumber}</span>
                        </div>
                      )}
                  </div>

                  <div className="col-md-6">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label small-font mb-2 me-1"
                    >
                      Address (Select your address)
                    </label>
                    <span className="text-danger">*</span>
                    <div className="input-group">
                      <input
                        type="text"
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid":
                              formik.touched.address && formik.errors.address,
                          },
                          {
                            "is-valid":
                              formik.touched.address && !formik.errors.address,
                          }
                        )}
                        placeholder="Door no., City, State,Country"
                        {...formik.getFieldProps("address")}
                        onChange={(e) => handleGoogleApi(e.target.value)}
                        
                      />
                      <div className="input-group-append">
                        <img
                          onClick={openMap}
                          src="./assets/images/map.png"
                          className="map-icon mt-2 mx-3"
                          alt="Map Icon"
                          style={{ width: "20px", height: "25px" }}
                        />
                      </div>
                    </div>
                    {suggestions.length > 0 ? (
                      <ul className="nav flex-column googleStyleUL">
                        {suggestions.map((suggestion: any) => (
                          <li
                            className="nav-item fs-12 googleStyleLI"
                            key={suggestion.place_id}
                            onClick={() =>
                              handleCitySelection(
                                suggestion.place_id,
                                suggestion.description
                              )
                            }
                          >
                            {suggestion.description}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                    {formik.touched.address && formik.errors.address && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.address}</span>
                      </div>
                    )}
                  </div>
                  <div className="row mb-4 mt-3">
                    <div className="col-md-6">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="small-font  mb-2"
                      >
                        Zip Code
                      </label>
                      <input
                        type="text"
                        readOnly
                        className={clsx(
                          "form-control",
                          {
                            "is-invalid":
                              formik.touched.zipCode && formik.errors.zipCode,
                          },
                          {
                            "is-valid":
                              formik.touched.zipCode && !formik.errors.zipCode,
                          }
                        )}
                        placeholder="Your zip code"
                        {...formik.getFieldProps("zipCode")}
                      />
                      {formik.touched.zipCode && formik.errors.zipCode && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">{formik.errors.zipCode}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="signup-action d-flex gap-3 justify-content-md-between flex-xl-row justify-content-sm-start flex-sm-column form-check form-check-inline mt-3">
                    <span className="tiny-font opacity-80  align-self-xl-center align-self-sm-start">
                      <input
                        className="form-check-input me-3"
                        type="checkbox"
                        id="addonCheck"
                        value="option1"
                        onChange={() =>
                          setIsCheckboxChecked(!isCheckboxChecked)
                        }
                      />{" "}
                      By clicking “Sign Up” you are accepting our{" "}
                      <a
                        href="https://homechef360.com/terms-conditions.html"
                        className="opacity-80"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </span>
                    <div className="d-flex gap-3 justify-content-sm-center">
                      <button
                        className="btn outline-btn px-5"
                        onClick={onClickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={
                          !isCheckboxChecked ||
                          formik.isSubmitting 
                          // !formik.isValid
                        }
                        className="btn primary-btn px-5"
                      >
                        {!loading && (
                          <span className="indicator-label">Signup</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                  {!isCheckboxChecked && ( // Conditional rendering of message when checkbox is not checked
                    <span className="text-danger">
                      Please check the confirmation button*
                    </span>
                  )}
                </div>
              </div>
            </form>
            <div className=" mt-3 pt-4 pb-3 footer-login">
              <p className="text-center small-font">
                If you have an account.{" "}
                <a onClick={handleLogin} className="link cursorPointer">
                  Login
                </a>{" "}
                here
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeMap}>
        <Modal.Header closeButton>
          <Modal.Title>Select Your Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={3}
              center={mapCenter}
              onClick={handleMapClick}
            >
              {userLocation && (
                <Marker
                  position={userLocation}
                  icon={{
                    url: "../../assets/images/locationicon.svg",
                    scaledSize: new window.google.maps.Size(40, 40), // scaled size
                  }}
                  draggable={true}
                  onDragEnd={handleMarkerDragEnd}
                />
              )}
            </GoogleMap>
            <div className="d-flex gap-3 mt-2 justify-content-end">
              <button
                type="submit"
                className="btn primary-btn px-3 py-1"
                onClick={fetchAddress}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export { ChooseAddress };
