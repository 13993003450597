import { useState } from "react";

import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ListReviews from "./files/ListReview";



const ReviewPage = () => {


  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <ListReviews />
            </>
          }
        />
        <Route index element={<Navigate to="list" />} />
      </Route>
    </Routes>
  );
};

export default ReviewPage;
