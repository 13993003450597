import axios from "axios";
import { getToken } from "../../../auth";
//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;
const ADDON_URL = `${API_URL}chefPortal`;

const listAddonData = async (ChefId: any, filterData: any) => {
  const response = await axios.post(`${API_URL}chefPortal/list-Addons/${ChefId}`,filterData , getToken());
  console.log("listAddonData-ap[i",listAddonData)
  return response.data;
};

const updateAddon = async (id: any,updatedData: any) => {
  const res = await axios.put(`${ADDON_URL}/update-Addons/${id}`, updatedData, getToken());
  return res.data;
};

const deleteAddon = async (id: any) => {
  const response = await axios.put(`${ADDON_URL}/delete-Addons/${id}`, getToken());
  return response.data;
};
const enableStatus = async (id: any, enableDisableStatus: any) => {

  const response = await axios
    .put(`${API_URL}chefPortal/enableDisable-Addons/${id}`, enableDisableStatus, getToken());
  return response.data;
};

const CreateAddons = async (data:any) => {
  const res = await axios.post(`${ADDON_URL}/add-Addons`, data, getToken());
  return res.data;
};

const viewAddonById = async (id: any) => {
  const response = await axios.get(`${ADDON_URL}/view-Addons/${id}`, getToken());
  return response.data;
};

const checkDuplicateAddon = async (name: any, chefId:any) => {
  console.log("name",name)
  const response = await axios
    .post(`${ADDON_URL}/find-duplicate-Addons`, { name , chefId}, getToken());
  return response.data;
};
const getType = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getType`, getToken());
  return res;
};
export {
  listAddonData,getType,
  CreateAddons,
  updateAddon,
  deleteAddon,
  viewAddonById,
  enableStatus,
  checkDuplicateAddon,
};
