import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FC, useEffect, useRef, useState } from "react";
import { BannerDetails, BannerPictureUpdate, } from "../core/_requests";
import { ChefRegister, useAuth } from "../../../auth";

import Header from "../../sidebar/Header";

type Props = {
  initialValues: any;
};

const UpdateBanner: FC<Props> = ({ initialValues }) => {
  console.log("initialValues-banner",initialValues)
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [formImages, setFormImages] = useState<any[]>([]);
  const chefId =currentUser?.uuid
  const fileInputRef = useRef(null);

  const clickCancel = () => {
    navigate("/settings");
  };

  const Schema = Yup.object().shape({});
  const formik = useFormik<ChefRegister>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, actions) => {
      setLoading(true);
      const formData = new FormData();
      if (formImages) {
        formImages.forEach((image, index) => {
          formData.append("bannerchef", image);
        });
      }

      BannerPictureUpdate(formData, chefId).then((res) => {
        if (res?.status === true) {
          navigate("/settings/banner-success", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
          setErrorMessage(res?.message);
        }
      });
    },
  });

  const imageHandler = (e: { target: { files: any } }) => {
    const files = e.target.files;
  
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (!allowedTypes.includes(files[i].type)) {
          setErrorMessage(
            "Invalid file type. Allowed types are JPEG, PNG, and JPG."
          );
          setFormImages([]);
          return;
        } else if (files[i].size > 1024 * 1024) {
          // Check if file size exceeds 1MB
          setErrorMessage("File size should be less than 1MB.");
          setFormImages([]);
          return;
        }
      }
  
      setErrorMessage(""); // Clear the error message
      const newImages = Array.from(files);
      setFormImages(newImages);
    }
  };
  
  
  const handleErrorMessageClose = () => {
    setErrorMessage("");
    setData({
      ...data,
      bannerchef: null,
    });
    if (fileInputRef.current) {
      // fileInputRef.current.value = "";
    }
  };
  

  const removeImage = (indexToRemove: number) => {
    setFormImages((prevImages) =>
      prevImages.filter((image, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    BannerDetails(chefId)
      .then((res) => {
        setData(res?.data);
      
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  
  return (
    <>
      <section className="container-fluid">
        <div className="row">
            {/* <Sidebar />   */}
            <Header />
          <section className="content-section">
            {/* <Header /> */}
            <div className="overlay"></div>
            <div className="drawer small">
              <div className="title row">
                <h3 className="col-10 mb-0">Banner Images</h3>
                <div className="col-2 text-end mb-2">
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={clickCancel}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </button>
                </div>
                <h6>Hey buddy, check out this banner on your profile in user app!</h6>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3 mt-3">
                  <label className="form-label fs-6">
                    Image
                  </label>

                  <div className="file-input">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    multiple  // Allow multiple file selection
                    onChange={imageHandler}
                    ref={fileInputRef}
                  />

                    <label htmlFor="file">
                      <i className="material-symbols-outlined">upload</i>
                      <span> Upload Banner Image </span>
                    </label>
                  </div>
                  <span className="file-info">
                    Supported file type (.jpg, .png, .jpeg) Max file size:1MB
                  </span>
                  {formImages.length > 0 && (
                    <div>
                      <h4>Newly Uploaded Banner</h4>
                    </div>
                  )}
                  <div className="col-12 d-flex">
                    {formImages.map((image, index) => (
                      <>
                        <div key={index} className="mt-2">
                          <img
                            src={URL.createObjectURL(image)}
                            height="100"
                            width="100"
                          />
                        </div>
                        <button
                          type="button"
                          className="btn p-0 close d-inline-flex "
                          onClick={() => removeImage(index)}
                        >
                          <i className="material-symbols-outlined fs-18">
                            close
                          </i>
                        </button>
                      </>
                    ))}
                  </div>
                  {errorMessage && (
                <div style={{ color: "red" }} className="custom-error-messages">
                  {errorMessage}
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={handleErrorMessageClose}
                  >
                    <i className="material-symbols-outlined fs-18">close</i>
                  </button>
                </div>
              )}
          

                </div>
                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-cancel rounded-5"
                        onClick={clickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={formik.isSubmitting || !formik.isValid}
                        className="btn btn-outline-primary rounded-5 me-2"
                      >
                        {!loading && (
                          <span className="indicator-label">Publish</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                      {/* <button
                    className="btn btn-primary rounded-5"
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={handlePublishAndNew}
                  >
                    Publish & New
                  </button> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};
export { UpdateBanner };
