import  { useEffect, useState } from "react";
import "../../../assets/custom-style.css";

import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";

import {
  enableStatus,
  getCategory,
  getCuisine,
  listCouponData,
} from "../core/_requests";

import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";
interface Coupon {
  primaryId: any;
  amount: string;
  name: string;
  startDate: string;
  minimumOrderValue: string;
  usagePerUse: string;
  limit: string;
  endDate: string;
  usagePerUser: string;
  enableDisableStatus:boolean;
  cuisine:{
    name:string;
  },
  category:{
    name:string;
  }
}

interface Cuisine {
  uuid: UUID;
  name: string;
}


interface Category {
  uuid: UUID;
  name: string;
}

const ListCoupon = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [listCoupon, setListCoupon] = useState<Coupon[]>([]);
  const [cuisine, setCuisine] = useState<Cuisine[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [cuisineId, setCuisineId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const chefId =currentUser?.uuid
   //pagination
   const [currentPage, setCurrentPage] = useState(1);
   const itemsPerPage = 10;

   const filterData = {
    search: search,
    cuisineId: cuisineId,
    categoryId: categoryId,
  };

  useEffect(() => {
    listCouponData(chefId, filterData).then((res) => {
      setListCoupon(res?.data);
      console.log("res?.data",res?.data)
      setLoading(false);
    });
  },[])

  useEffect(() => {
    getCuisine()
      .then((res) => {
        console.log("res?.data?.data",res?.data?.data);
        setCuisine(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching financial year:", error);
      });
  }, []);

  useEffect(() => {
    getCategory()
      .then((res) => {
        setCategory(res?.data?.data);
        console.log("res?.data?.data",res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const AddCoupon = () => {
    navigate("/coupon/add");
  };

  const deleteCoupon = (primaryId: string) => {
    navigate("/coupon/delete/" + primaryId);
  };

  const editCoupon = (primaryId: string) => {
    navigate("/coupon/edit/" + primaryId);
  };
  
  

  const clickApply = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setCurrentPage(1);
    const data = {
      search: search,
      categoryId: categoryId,
      cuisineId: cuisineId,
    };
    try {
      const res= await listCouponData(chefId, data).then((res) => {
        setListCoupon(res?.data);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching coupon:", error);
      setLoading(false);
    }
   
  };
  
  const clickCancel = () => {
    navigate("/coupon");
  };
  const handleEnableDisable = (id: any, status: any) => {
    const enableDisable = !status; // Toggle the status
    const data = { enableDisableStatus: enableDisable };
    enableStatus(id, data).then((res) => {
      const updatedListCoupon = listCoupon.map((item) => {
        if (item.primaryId === id) {
          return { ...item, enableDisableStatus: enableDisable };
        }
        return item;
      });
      setListCoupon(updatedListCoupon);
    });
  };
  //pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listCoupon.slice(indexOfFirstItem, indexOfLastItem);
  const pages = Math.ceil(listCoupon.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage;

    let pageNumberContent;

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <a
            className={`page-link cursorPointer ${
              number === currentPage ? "active" : ""
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className="page-item">
          <a
            className="page-link cursorPointer"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    }

    return pageNumberContent;
  });

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <>
      <section className="container-fluid">
        <div className="row">
            {/* <Sidebar />   */}
            <Header />
          <section className="content-section">
            {/* <Header /> */}
            <section className="row main-content">
              <div className="title mb-3">
                <h3 className="mb-0">Coupon ({listCoupon?.length})</h3>
              </div>
            <div className="col-12 title-btm">
              <div className="search">
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="material-symbols-outlined">search</i>
                  </span>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Coupon Code..."
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyUp={clickApply}
                    />
                </div>
              </div>
                <button
                  className="btn btn-primary btn-ico rounded-5"
                  onClick={AddCoupon}
                >
                  <i className="material-symbols-outlined">add_circle</i>
                  <span>Add Coupon</span>
                </button>
            </div>
            <div className="filter-sec mt-4">
                <form>
                  <div className="row gx-3">
                    <div className="col-md-2">
                      <label className="form-label">Cuisine</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setCuisineId(e.target.value)}
                      >
                        <option value="">All</option>
                        {cuisine.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">Category</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setCategoryId(e.target.value)}
                      >
                        <option value="">All</option>
                        {category.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    
                    <div className="col-md-4 d-flex align-items-center justify-content-end pt-4">
                      <button
                        className="btn btn-cancel rounded-5 small me-2 mt-1"
                        onClick={clickCancel}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-primary rounded-5 small mt-1"
                        onClick={clickApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="table-wrap mt-4">
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th>Coupon Code</th>
                      <th>Cuisine-Category</th>
                      <th>Amount</th>
                      <th>Minimum Order</th>
                      <th>Coupon Limit</th>
                      <th>Coupon User</th>
                      <th>Available</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                  {currentItems.length > 0 ? (
                    currentItems.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <p className="mb-0">{item?.name}</p>
                          </td>
                          <td>
                             <p>{item?.cuisine?.name} - {item?.category?.name}</p>
                          </td>
                          <td>
                            <p className="mb-0">{item?.amount}</p>
                          </td>
                          <td>
                            <p className="mb-0">
                              {item?.minimumOrderValue}
                            </p>
                          </td>
                          <td>
                          <p className="mb-0">{item?.limit}</p>
                          </td>
                          <td> {item?.usagePerUser}</td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input cursorPointer"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={item?.enableDisableStatus}
                                onClick={() =>
                                  handleEnableDisable(
                                    item?.primaryId,
                                    item?.enableDisableStatus
                                  )
                                }
                              />
                              <label className="yes">Yes</label>
                              <label className="no">No</label>
                            </div>
                          </td>
                          <td>
                          <p className="mb-0">{item?.startDate}</p>
                          </td>
                          <td>
                          <p className="mb-0">{item?.endDate}</p>
                          </td>
                          <td>
                            <div className="action-item">
                              <a className="success cursorPointer"  
                                onClick={() => editCoupon(item?.primaryId)}>
                                <span className="material-symbols-outlined">
                                  edit
                                </span>
                              </a>
                              <a
                                className="danger cursorPointer"
                                onClick={() => deleteCoupon(item?.primaryId)}
                              >
                                <span className="material-symbols-outlined">
                                  delete
                                </span>
                              </a>
                              {/* <a onClick={() => viewDish(item?.primaryId)}>
                                <span className="material-symbols-outlined cursorPointer">
                                  info
                                </span>
                              </a> */}
                            </div>
                          </td>
                        </tr>
                     ))
                     ) : loading ? (
                       <tr>
                         <td colSpan={8} className="text-center">
                           Loading...{" "}
                         </td>
                       </tr>
                     ) : (
                       <tr>
                         <td colSpan={8} className="text-center">
                           No data
                         </td>
                       </tr>
                     )}
                  </tbody>
                </table>
              </div>

              {currentItems.length > 0 && (
                <nav className="pagination-wrap mb-4">
                  <p className="mb-0">
                    Showing {indexOfFirstItem + 1} to{" "}
                    {Math.min(indexOfLastItem, listCoupon.length)} of{" "}
                    {listCoupon.length} items
                  </p>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(1)}
                      >
                        <i className="material-symbols-outlined">first_page</i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={prevPage}
                        aria-label="Previous"
                      >
                        <i className="material-symbols-outlined">
                          navigate_before
                        </i>
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        <i className="material-symbols-outlined">
                          navigate_next
                        </i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(pages)}
                      >
                        <i className="material-symbols-outlined">last_page</i>
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </section>
          </section>
        </div>
      </section>
      
    </>
  );
};

export default ListCoupon;
