import axios from "axios";
import { getToken } from "../../../auth";
//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;
const SETTLEMENTS_URL = `${API_URL}chefPortal`;

const getBankDetailsByRoutingNumber = async (routingNumber:any) => {
  try {
    const response = await axios.post(
      `${API_URL}chefPortal/getBankDetailsByRoutingNumber`,
      { routingNumber: routingNumber } // Pass routingNumber in the request body
    );
    console.log("response.data-api---listBankData", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching bank details by routing number:", error);
    throw error;
  }
};

const listBankData = async (ChefId: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/listChefBankDetail/${ChefId}`
  );
  console.log("response.data-api---listBankData", response.data);
  return response.data;
};
const listWalletAmount = async (ChefId: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/walletAmount/${ChefId}`
  );
  console.log("response.data-api---listWalletAmount", response.data);
  return response.data;
};
const listChefWelletDetail = async (ChefId: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/listChefWelletDetail/${ChefId}`
  );
  console.log("response.data-api---wallet", response.data);
  return response.data;
};
const listChefSettlementDetail = async (ChefId: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/listChefSettlementDetail/${ChefId}`
  );
  console.log("response.data-api---listChefSettlementDetail", response.data);
  return response.data;
};
const listChefTransactionDetail = async (ChefId: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/listChefTransactionDetail/${ChefId}`
  );
  console.log("response.data-api---listChefTransactionDetail", response.data);
  return response.data;
};
const CreateBank = async (data: any) => {
  console.log("addChefBankDetail", data);
  const res = await axios.post(`${SETTLEMENTS_URL}/addChefBankDetail`, data);
  return res.data;
};

const updateBank = async (id: any, updatedData: any) => {
  const res = await axios.put(
    `${SETTLEMENTS_URL}/updateChefBankDetail/${id}`,
    updatedData
  );
  return res.data;
};

const deleteBank = async (id: any) => {
  const response = await axios.post(
    `${SETTLEMENTS_URL}/deleteChefBankDetail/${id}`
  );
  return response.data;
};

const viewBankById = async (id: any) => {
  const response = await axios.get(
    `${SETTLEMENTS_URL}/viewChefBankDetail/${id}`,
    getToken()
  );
  return response.data;
};

// const checkDuplicateBank = async (name: any) => {
//   console.log("name",name)
//   const response = await axios
//     .post(`${SETTLEMENTS_URL}/find-duplicate`, { name }, getToken());
//   return response.data;
// };

export {
  listBankData,
  listChefWelletDetail,
  listChefSettlementDetail,
  CreateBank,
  updateBank,
  deleteBank,
  viewBankById,
  listWalletAmount,getBankDetailsByRoutingNumber,listChefTransactionDetail

  // checkDuplicateBank,
};
