import { Route, Routes } from "react-router-dom";
import { Signup } from "./component/Signup";

const AuthPage = () => (
  <Routes>
    <Route path="signup" element={<Signup />} />
    <Route index element={<Signup />} />
  </Routes>
);

export { AuthPage };
