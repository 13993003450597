import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { recentReview } from "../../core/_requests";
import { useAuth } from "../../core/Auth";
// import {} from  '../../../assets/images'
interface Dish {
  name: string;
  title: string;
  description: string;
  reviews: string;
  user: {
    userName: string;
    userProfile: string;
  };
}

const RecentRating = () => {
  const [data, setData] = useState<Dish[]>([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const chefId =currentUser?.uuid

    const viewReviews=()=>{
     
     navigate('/reviews')
   }

  useEffect(() => {
    recentReview(chefId)
      .then((res: any) => {
        setData(res);
      })
      .catch((res) => {
        console.log("res", res);
      });
  }, []);

  // console.log('recentrating',data)

  return (
    <div className="snippet-block btm-same-height">
      <div className="head">
        <h6 className="mb-0 fw-bold">Recent Ratings</h6>
        <a href="javascript:;" className="text-link" onClick={viewReviews}>
          View All
          <i className="material-symbols-outlined fall">arrow_forward</i>
        </a>
      </div>
      {data.length > 0 ? (
        data.map((item, i) => (
          <div className="snippet-content">
            <div className="dish-card mb-4">
              <div className="dish w-100">
                <div className="thumbnail">
                  {item?.user?.userProfile !== null ? (
                    <></>
                  ) : (
                    // <img src={item?.user?.userProfile} alt="DishName"/>
                    <div className="symbol symbol-75px symbol-circle ">
                      {item?.user?.userName ? (
                        <span
                          className={`symbol-label bg-light-danger text-danger fs-4 fw-bolder`}
                        >
                          {item?.user?.userName.charAt(0).toUpperCase()}
                        </span>
                      ) : (
                        // <img alt='Pic' src={toAbsoluteUrl(avatar)} />
                        <></>
                      )}
                    </div>
                  )}

                  {/* <img src="images/avatar.png" alt="DishName"/> */}
                </div>
                <div className="content">
                  <div className="rating with-name">
                    <span>{item?.user?.userName}</span>
                    <img
                      className="ico fs-3"
                      style={{ width: "23px" }}
                      src="../media/star.svg"
                      alt="Star"
                    />
                    {item?.reviews}
                  </div>
                  <span className="review">{item?.description}</span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center">
          <div className="thumbnail mt-5">
            <img
              src="../../../assets/images/noDishes.svg"
              style={{ height: "50px" }}
            />
          </div>
          No Recent Ratings
        </div>
      )}
    </div>
  );
};

export { RecentRating };
