import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditAddons from "./EditAddon";
import { viewAddonById } from "../core/_requests";



export const EditBasicDetailsWrapper = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    viewAddonById(id).then((res) => {
      setData(res.data);
    });
  }, []);


  if (data) {
    return <EditAddons initialValues={data} />;
  }
  return null;
};
