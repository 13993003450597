import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';

console.log("process.env.REACT_APP_API_KEY",process.env)

const firebaseConfig = {
  apiKey: "AIzaSyCtoXKzWeIyoLWZ-qil0z5xxLB15O5Peec",
  authDomain: "homechef360chefportal.firebaseapp.com",
  projectId: "homechef360chefportal",
  storageBucket: "homechef360chefportal.appspot.com",
  messagingSenderId: "1082431787502",
  appId: "1:1082431787502:web:4ff277fb0a5489f8183eff"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log("firebase-------app",app)
export const auth= getAuth(app)
export default app