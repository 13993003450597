import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FC, useEffect, useState, useRef } from "react";
import {
  SoundUpdateAPI,
  ProfileDetails,
  getSounds,
  UpdateProfile,
} from "../core/_requests";
import { ChefProfile, useAuth } from "../../../auth";
import Header from "../../sidebar/Header";
import { UUID } from "crypto";
import { toast } from "react-toastify";

type Props = {
  initialValues: any;
};

interface Sounds {
  uuid: UUID;
  name: string;
  tone: string;
  enableDisableStatus: string;
}

const SoundUpdate: FC<Props> = ({ initialValues }) => {
  console.log("initialValues---sound",initialValues)
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [sounds, setSounds] = useState<Sounds[]>([]);
  const [selectedSoundUUID, setSelectedSoundUUID] = useState<UUID | null>(null);
  const fileInputRef = useRef(null);
  const chefId =currentUser?.uuid
console.log("selectedSoundUUID",selectedSoundUUID)
useEffect(() => {
  setSelectedSoundUUID(initialValues.notificationSound);
}, [initialValues.notificationSound]);
  useEffect(() => {
    getSounds()
      .then((res) => {
        setSounds(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching sounds:", error);
      });
  }, []);

  const Schema = Yup.object().shape({});

  const formik = useFormik<ChefProfile>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, actions) => {
      setLoading(true);
      console.log("values", values);
   
      const updatedData = { ...values, notificationSound: selectedSoundUUID };
      console.log("updatedData", updatedData);

      UpdateProfile(updatedData, chefId).then((res) => {
        console.log("dsdsds", res);
        if (res?.status === true) {
          toast.success(res?.Message, { autoClose: 5000 });
          navigate("/settings", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.message);
        }
      });
    },
  });
  const handleCheckboxChange = (uuid: UUID) => {
    console.log("uuid----selec",uuid)
    setSelectedSoundUUID(uuid);
  };

  const handleErrorMessageClose = () => {
    setErrorMessage("");
  };

  const clickCancel = () => {
    navigate("/settings");
  };

  useEffect(() => {
    ProfileDetails(chefId)
      .then((res) => {
        setData(res?.data);
      })
      .catch((error) => {
        console.error("Error fetching profile details:", error);
      });
  }, []);
  console.log("formik---sound", formik.values.notificationSound);
  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <Header />
          <section className="content-section">
            <div className="overlay"></div>
            <div className="drawer small">
              <div className="title row">
                <h3 className="col-10 mb-0">Set Notification Tone</h3>
                <div className="col-2 text-end mb-2">
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={clickCancel}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </button>
                </div>
                <h6>
                  Hey buddy, check out this notification tone on your profile in
                  user app!
                </h6>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-4 mt-3">
                  <label className="form-label fs-6">
                    Select notification tone (You can select any one)
                  </label>
                  {sounds.map((sound) => (
                    <div key={sound.uuid} className="mt-2">
                      <div>
                        <input
                          className="form-check-input me-3"
                          type="checkbox"
                          checked={selectedSoundUUID === sound.uuid}
                          onChange={() => handleCheckboxChange(sound.uuid)}
                        />

                        <span>{sound.name}</span>
                      </div>
                      <audio controls>
                        <source src={sound.tone} type="audio/mpeg" />
                      </audio>
                    </div>
                  ))}
                  {errorMessage && (
                    <div
                      style={{ color: "red" }}
                      className="custom-error-messages"
                    >
                      {errorMessage}
                      <button
                        className="btn p-0 close d-inline-flex"
                        onClick={handleErrorMessageClose}
                      >
                        <i className="material-symbols-outlined fs-18">close</i>
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-cancel rounded-5"
                        onClick={clickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={formik.isSubmitting || !formik.isValid}
                        className="btn btn-outline-primary rounded-5 me-2"
                      >
                        {!loading && (
                          <span className="indicator-label">Publish</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export { SoundUpdate };
