import { useEffect, useState } from "react";
import { BannerDetails, ProfileDetails, listTiming } from "../core/_requests";
import { useAuth } from "../../../auth";

import { BioUpdate } from "./BioUpdate";
import { ChangePassword } from "./ChangePassword";
import { UpdateBanner } from "./BannerPicture";
import { ChangeChefTiming } from "./ChangeChefTiming";
import { ProfileUpdate } from "./ProfileUpdate";
import { ProfilePicUpdate } from "./ProfilePic";
import { BrandUpdate } from "./BrandUpdate";
import { SoundUpdate } from "./SoundUpdate";


export const ProfileUpdateWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    ProfileDetails(currentUser?.uuid).then((res) => {
      setData(res.data);
    });
  }, []);
  if (data) {
    return <ProfileUpdate initialValues={data}/>;
  }
  return null;
};
export const ProfilePicUpdateWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    ProfileDetails(currentUser?.uuid).then((res) => {
      setData(res.data);
    });
  }, []);
  if (data) {
    return <ProfilePicUpdate initialValues={data}/>;
  }
  return null;
};
export const BioWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    ProfileDetails(currentUser?.uuid).then((res) => {
      setData(res.data);
    });
  }, []);
  if (data) {
    return <BioUpdate initialValues={data}/>;
  }
  return null;
};
export const BrandWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    ProfileDetails(currentUser?.uuid).then((res) => {
      setData(res.data);
    });
  }, []);
  if (data) {
    return <BrandUpdate initialValues={data}/>;
  }
  return null;
};
export const ProfilePictureWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    ProfileDetails(currentUser?.uuid).then((res) => {
      setData(res.data);
    });
  }, []);
};

export const BannerWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);
  console.log("banner-wrapper-setData",data)
  useEffect(() => {
    BannerDetails(currentUser?.uuid).then((res) => {
      console.log("banner-wrapper-res.data",res)
      setData(res.data);
    });
  }, []);

  if (data !== null && data !== undefined) {
    return <UpdateBanner initialValues={data} />;
  }
  return null;
};

export const ChangePasswordWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    ProfileDetails(currentUser?.uuid).then((res) => {
      setData(res.data);
    });
  }, []);

  if (data) {
    return <ChangePassword initialValues={data} />;
  }
  return null;
};
export const SoundUpdateWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    ProfileDetails(currentUser?.uuid).then((res) => {
      setData(res.data);
    });
  }, []);
  if (data) {
    return <SoundUpdate initialValues={data}/>;
  }
  return null;
};
export const ChefTimingWrapper = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    listTiming(currentUser?.uuid).then((res) => {
      setData(res.data);
    });
  }, []);

  if (data) {
    return <ChangeChefTiming initialValues={data} />;
  }
  return null;
};