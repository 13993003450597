import React, { useEffect, useState } from "react";
import "../../../assets/custom-style.css";
import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";
import {
  listOrdersData,
  getStatus,
  changeStatusOrders,
} from "../core/_requests";
import { useNavigate } from "react-router-dom";
import MenuOrders from "./MenuOrders";
import Avatar from "react-avatar";

interface Orders {
  primaryId: number;
  orderstatusId: number;
  uuid: string;
  orderId: string;
  chefId: string;
  userId: string;
  amount: number;
  tax: number;
  deliveryCharge: number;
  deliveryType: number;
  couponApplied: boolean;
  couponAmount: number | null;
  walletDiscount: number | null;
  deliveryPersonTips: number;
  chefTips: number;
  paymentMethod: string;
  netValue: number;
  orderDate: string;
  orderTime: string;
  cookingInstruction: string;
  totalAmount: number;
  orderstatus: {
    name: string;
  };
  chef: {
    name: string;
    currentlyAvailableStatus: boolean;
    email: string;
    phoneNumber: string | null;
    phoneNumber1: string | null;
    mobileNumber: string;
    mobileNumber1: string | null;
    location: string | null;
    licence: string | null;
    chefProfile: string | null;
    approvalStatus: boolean;
    address1: string;

    city: string;
    state: string;
    country: string;
    zipCode: string;
    status: number;
    enableDisableStatus: boolean;
  };
  orderdetails: [
    {
      id: number;
      orderId: string;
      dishId: string;
      quantity: number;
      amount: number;
      status: number;
      createdAt: string;
      updatedAt: string;
      dish: {
        name: string;
        dishImage: string;
        price: number;
        addons: [
          {
            id: number;
            name: string;
            quantity: number;
            price: number;
          }
        ];
      };
    }
  ];
  user: {
    id: number;
    uuid: string;
    userProfile: string;
    deviceId: string | null;
    token: string;
    verifyStatus: string | null;
    deviceType: string | null;
    deviceToken: string | null;
    loginType: string | null;
    name: string;
    email: string;
    latitude: string | null;
    longitude: string | null;
    contactNumber: string;
    plansId: string | null;
    planStartDate: string | null;
    planEndDate: string | null;
    planStatus: string | null;
    status: number;
    createdBy: string | null;
    updatedBy: string | null;
    createdAt: string;
    updatedAt: string;

    addressbooks: [
      {
        label: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        country: string;
        zipcode: any;
      }
    ];
  };
}
interface Status {
  id: number;
  name: string;
  active?: boolean;
}

const ListOrders = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [listOrders, setListOrders] = useState<Orders[]>([]);

  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState<Status[]>([]);
  const chefId =currentUser?.uuid
  useEffect(() => {
    getStatus()
      .then((res) => {
        const statusData = res?.data?.data.map((s: any) => ({
          ...s,
          active: s.name === "preparing",
        }));

        setStatus(statusData);
      })
      .catch((error) => {
        console.error("Error fetching order statuses:", error);
      });
  }, []);

  useEffect(() => {
    listOrdersData(chefId).then((res) => {
      setListOrders(res?.data);
      setLoading(false);
    });
  }, []);

  const handleOrderStatusChange = async (order: Orders, action: string) => {
    try {
      let newOrderStatusId;

      // Map action to the corresponding status name
      switch (action) {
        case "Preparing":
          newOrderStatusId = 3;
          break;
        case "Order Ready":
          newOrderStatusId = 4;
          break;
        case "Cancel Order":
          newOrderStatusId = 7;
          break;

        case "Pickup":
          newOrderStatusId = 5;
          break;

        default:
          console.warn("Unknown action:", action);
          return;
      }
      console.log("newOrderStatusId", newOrderStatusId);

      await changeStatusOrders(order.uuid, { orderStatus: newOrderStatusId });

      listOrdersData(chefId).then((res) => {
        setListOrders(res?.data);
        setLoading(false);
      });
      navigate("/orders/view");
    } catch (error) {
      console.error("Error handling order status change:", error);
    }
  };
  const getButtonLabel = (name: string): string[] => {
    switch (name) {
      case "ordered":
        return ["Accept Order", "Cancel Order"];

      case "preparing":
        return ["Order Ready"];
      case "ready":
        return ["Pickup"];
      case "track":

      case "delivered":
      case "cancelled":

      default:
        return [];
    }
  };
  const formatString = (inputString: any) => {
    return inputString.toLowerCase().replace(/\s+/g, "-");
  };
  const openHistory = () => {
    console.log("history");
    navigate("/orders/history");
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          {/* <Sidebar /> */}
          <Header />
          <section className="content-section">
            <section className="row main-content">
              <MenuOrders />

              <div className="col-12 mt-md-2 mt-0">
                <ul
                  className="nav nav-pills mb-4 order-pills"
                  id="pills-tab"
                  role="tablist"
                >
                  {status.slice().map((s) => (
                    <li className="nav-item" role="presentation" key={s.id}>
                      <button
                        className={`mt-3 btn-sm nav-link ${
                          s.active ? "active" : ""
                        }`}
                        id={`pills-${formatString(s.name)}-tab`}
                        data-bs-toggle="pill"
                        data-bs-target={`#pills-${formatString(s.name)}`}
                        type="button"
                        role="tab"
                        aria-controls={`pills-${formatString(s.name)}`}
                        aria-selected={s.active}
                      >
                        {`${
                          s.name.charAt(0).toUpperCase() +
                          s.name.slice(1)
                        } (${
                          listOrders.filter((item) => item.orderstatusId === s.id)
                            .length
                        })`}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="tab-content">
                  {status.slice().map((s) => (
                    <div
                      className={`tab-pane fade show${
                        s.active ? " active" : ""
                      }`}
                      id={`pills-${s.name.toLowerCase()}`}
                      key={s.id}
                    >
                      {listOrders
                        .filter((item) => item.orderstatusId === s.id)
                        .reverse()
                        .map((item) => (
                          <div
                            className="tab-pane fade show active"
                            id={`pills-${s.name.toLowerCase()}-${
                              item.primaryId
                            }`}
                            key={item.primaryId}
                          >
                            <div className="order-snippet mb-3">
                              <div className="os-head">
                                <div>
                                  <h5 className="mb-0 medium primary-color">
                                    Order - {item?.orderId}
                                  </h5>
                                  <p className="mb-0 mid-gray">
                                    <small>{item?.orderTime}</small>
                                  </p>
                                </div>
                                <div className="order-by">
                                  <div className="avatar">
                                    <div className="thumbnail me-2">
                                      {item?.user?.userProfile ? (
                                        <Avatar
                                          src={item?.user?.userProfile}
                                          name={item?.user?.name}
                                          maxInitials={1}
                                          size="25"
                                          round
                                        ></Avatar>
                                      ) : (
                                        <Avatar
                                          name={item?.user?.name}
                                          maxInitials={1}
                                          size="25"
                                          round
                                          color="#607458"
                                          fgColor="#ffffff"
                                        ></Avatar>
                                      )}
                                    </div>
                                    <p className="mb-0">
                                      <span className="tiny mid-gray">
                                        Order by{" "}
                                      </span>
                                      <br />
                                      <small>{item?.user?.name}</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row os-body mt-3">
                                <div className="col-lg-6 col-12">
                                  <h6 className="mb-2">Item Details</h6>
                                  {item?.orderdetails.map((orderDetail, j) => (
                                    <div className="bg-blk" key={j}>
                                      <div className="row mb-3">
                                        <div className="col-8">
                                          <div className="dish">
                                            <div className="thumbnail me-2">
                                              <img
                                                src={
                                                  orderDetail.dish?.dishImage
                                                }
                                                alt={orderDetail.dish?.name}
                                              />
                                            </div>
                                            <p className="mb-0">
                                              <span className="fw-500">
                                                {orderDetail.quantity}
                                              </span>{" "}
                                              x {orderDetail.dish?.name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                          <h6 className="mb-0">
                                            ${item?.totalAmount.toFixed(2)}
                                          </h6>
                                        </div>
                                      </div>
                                      {orderDetail.dish.addons.map(
                                        (addon, k) => (
                                          <div className="row mb-3">
                                            <p className="px-0 mb-0">
                                              <small className="fw-500 px-2">
                                                Addons
                                              </small>
                                            </p>
                                            <div className="col-8 ps-0">
                                              <p className="px-2 mb-0">
                                                <span className="tiny mid-gray">
                                                  {addon.name}
                                                </span>{" "}
                                                x {addon.quantity}
                                              </p>
                                            </div>
                                            <div className="col-4 pe-0 d-flex align-items-center justify-content-end">
                                              <h6 className="mb-0">
                                                $ {addon.quantity * addon.price}
                                              </h6>
                                            </div>
                                          </div>
                                        )
                                      )}
                                      <div className="row mb-3">
                                        <p className="px-0 mb-1">
                                          <small className="fw-500 px-2">
                                            Cooking Instructions
                                          </small>
                                        </p>
                                        <p className="px-0 mb-0 px-2">
                                          <span className="tiny mid-gray">
                                            {item.cookingInstruction}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className="col-lg-6 col-12">
                                  <h6 className="mb-md-2 mb-0">&nbsp;</h6>
                                  <div
                                    className="accordion bill-toggle px-0"
                                    id="accordionExample"
                                  >
                                    <div className="accordion-item">
                                      <h2
                                        className="accordion-header"
                                        id="headingTwo"
                                      >
                                        <button
                                          className="accordion-button collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseTwo"
                                          aria-expanded="false"
                                          aria-controls="collapseTwo"
                                        >
                                          <h6 className=" mb-0">
                                            Bill Details
                                            <span>
                                              $ {item?.totalAmount.toFixed(2)}
                                              <span className="badge bg-primary ms-1">
                                                PAID
                                              </span>
                                            </span>
                                          </h6>
                                        </button>
                                      </h2>
                                      <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                      >
                                        <div className="accordion-body">
                                          <div className="row mb-2">
                                            <p className="col-7 mid-gray mb-0">
                                              <small>Item Total</small>
                                            </p>
                                            <h6 className="col-5 mid-gray medium mb-0 amount">
                                              $ {item?.totalAmount.toFixed(2)}
                                            </h6>
                                          </div>
                                          <div className="row mb-2">
                                            <p className="col-7 mid-gray mb-0">
                                              <small>Delivery Fee</small>
                                            </p>
                                            <h6 className="col-5 mid-gray medium mb-0 amount">
                                              $ {item?.deliveryCharge}
                                            </h6>
                                          </div>
                                          <div className="row mb-2">
                                            <p className="col-7 mid-gray mb-0">
                                              <small>Tip Charge</small>
                                            </p>
                                            <h6 className="col-5 mid-gray medium mb-0 amount">
                                              $ {item?.deliveryPersonTips}
                                            </h6>
                                          </div>
                                          <div className="row mb-2">
                                            <p className="col-7 mid-gray mb-0">
                                              <small>Taxes</small>
                                            </p>
                                            <h6 className="col-5 mid-gray medium mb-0 amount">
                                              $ {item?.tax}
                                            </h6>
                                          </div>
                                          <div className="row mb-1 mt-1">
                                            <p className="col-7 mb-0">
                                              Grand Total
                                            </p>
                                            <h6 className="col-5 medium mb-0 amount">
                                              $ {item?.netValue.toFixed(2)}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 text-end mt-3">
                                  {getButtonLabel(
                                    item.orderstatus?.name
                                  ).map((label, index) => (
                                    <button
                                      key={index}
                                      className={`btn btn-${
                                        label === "Cancel Order"
                                          ? "danger"
                                          : "primary"
                                      } rounded-5 small me-3`}
                                      onClick={() =>
                                        handleOrderStatusChange(item, label)
                                      }
                                    >
                                      {label}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export default ListOrders;
