import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { viewCouponById } from "../core/_requests";
import EditCoupon from "./EditCoupon";


export const EditBasicDetailsWrapper = () => {
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    viewCouponById(id).then((res) => {
      setData(res.data);
    });
  }, []);


  if (data) {
    return <EditCoupon initialValues={data} />;
  }
  return null;
};
