import React, { useEffect, useRef, useState } from "react";

import "../../../assets/custom-style.css";

import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";

import { getRatings } from "../core/_requests";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
interface Review {
  date: string;
  title: string;
  description: string;
  reviews: string;
  user: {
    userName: string;
    userProfile: string;
  };
}
const ListReviews = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState<Review[]>([]);
  const id = currentUser?.uuid;
  const [search, setSearch] = useState("");

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filterData = {
    search: search, // Include the search parameter

  
  };
  
  useEffect(() => {
    getRatings(id, filterData).then((res) => {
      console.log("getReports-filterData", res?.data);
      setRatings(res?.data);
      setLoading(false);
    });
  }, []);

  const clickApply = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setCurrentPage(1);
    const data = {
      search: search,
    };
    try {
      await getRatings(id, data).then((res) => {
        setRatings(res?.data);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching coupon:", error);
      setLoading(false);
    }
  };

  const clickCancel = () => {
    navigate("/report");
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ratings.slice(indexOfFirstItem, indexOfLastItem);
  const pages = Math.ceil(ratings.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage;

    let pageNumberContent;

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <a
            className={`page-link cursorPointer ${
              number === currentPage ? "active" : ""
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className="page-item">
          <a
            className="page-link cursorPointer"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    }

    return pageNumberContent;
  });

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
           {/* <Sidebar />   */}
           <Header />
          <section className="content-section">
            {/* <Header /> */}
            <section className="row main-content">
              <div className="title mb-3">
                <h3 className="mb-0">Reviews </h3>
              </div>

              <div className="col-12 title-btm">
                <div className="search">
                  {/* <div className="input-group">
                    <span className="input-group-text">
                      <i className="material-symbols-outlined">search</i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Dishes.."
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyUp={clickApply}
                    />
                  </div> */}
                </div>
              </div>

              <div className="table-wrap mt-4">
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>User Name</th>
                      <th className="text-green">Date</th>
                      <th>Title</th>
                      <th>Reviews</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>

                          <td>
                            <div className="dish">
                              <div className="thumbnail">
                                {item?.user?.userProfile ? (
                                  <Avatar
                                    src={item?.user?.userProfile}
                                    name={item?.user?.userName}
                                    maxInitials={1}
                                    size="25"
                                    round
                                  ></Avatar>
                                ) : (
                                  <Avatar
                                    name={item?.user?.userName}
                                    maxInitials={1}
                                    size="25"
                                    round
                                    color="#607458"
                                    fgColor="#ffffff"
                                  ></Avatar>
                                )}
                              </div>
                              <p className="ms-2 mb-0">
                                {item?.user?.userName}
                              </p>
                            </div>
                          </td>

                          <td>{item?.date}</td>
                          <td>{item?.title}</td>
                          <td>{item?.reviews}</td>
                          <td>{item?.description}</td>
                        </tr>
                      ))
                    ) : loading ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {currentItems.length > 0 && (
                <nav className="pagination-wrap mb-4">
                  <p className="mb-0">
                    Showing {indexOfFirstItem + 1} to{" "}
                    {Math.min(indexOfLastItem, ratings.length)} of{" "}
                    {ratings.length} items
                  </p>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(1)}
                      >
                        <i className="material-symbols-outlined">first_page</i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={prevPage}
                        aria-label="Previous"
                      >
                        <i className="material-symbols-outlined">
                          navigate_before
                        </i>
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        <i className="material-symbols-outlined">
                          navigate_next
                        </i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(pages)}
                      >
                        <i className="material-symbols-outlined">last_page</i>
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export default ListReviews;
