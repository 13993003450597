import { useEffect, useState } from "react";
import "../../../assets/custom-style.css";

import Header from "../../sidebar/Header";
import { useAuth } from "../../../auth";
import "../../../assets/custom-style.scss";
import {
  listBankData,
  listChefSettlementDetail,
  listWalletAmount,
} from "../core/_requests";

import { Link, useNavigate } from "react-router-dom";
import { UUID } from "crypto";


const ViewSettlements = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [wallet, setWallet] = useState<any[]>([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const chefId =currentUser?.uuid
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filterData = {
    search: search,
  };

  useEffect(() => {
    listChefSettlementDetail(chefId).then((res) => {
      setWallet(res?.data); // Set wallet state with data from the response or an empty array
    });
  }, []);


  const AddSettlements = () => {
    navigate("/settlements/add");
  };
  const EditSettlements = (primaryId: string) => {
    navigate("/settlements/edit/" + primaryId);
  };

  const deleteSettlements = (primaryId: string) => {
    navigate("/settlements/delete/" + primaryId);
  };


  //pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = wallet.slice(indexOfFirstItem, indexOfLastItem);
  const pages = Math.ceil(wallet.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage;

    let pageNumberContent;

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <a
            className={`page-link cursorPointer ${
              number === currentPage ? "active" : ""
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className="page-item">
          <a
            className="page-link cursorPointer"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    }

    return pageNumberContent;
  });

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const listTransaction = () => {
    navigate("/settlements/all-transaction");
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
           {/* <Sidebar />   */}
           <Header />
          <section className="content-section">
            {/* <Header /> */}
            <section className="row main-content settlement-content">
              <div className="title mb-3 d-flex align-items-center justify-content-between">
                <h3 className="mb-0">Settlement</h3>
                <button className="btn btn-primary btn-ico rounded-5 btn-empty small" onClick={listTransaction}>
                  <i className="material-symbols-outlined">history</i>
                  <span>Transaction History</span>
                </button>
              </div>

              <div className="col-12">
                <div className="row">
               

                  <div className="col-12 mt-4 mb-4">
                    <div className="snippet-block">
                      <div className="head">
                        <h6 className="mb-0">Settlement List</h6>
                       
                      </div>
                      {/* Check if wallet array is empty */}
                      {wallet.length === 0 ? (
                        <div className="snippet-content">
                          <div className="no-data">
                            <img
                              className="img-fluid mb-3"
                              src="../assets/images/no-data-img.svg"
                              alt="No Data"
                            />
                            <p className="mb-0 mid-gray">
                              <small>Still you don’t get any settlements</small>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="snippet-content">
                          <table className="table custom-table mb-0">
                            <thead>
                              <tr>
                              <th>S.No</th>
                                <th>Settlement Id</th>
                                <th>Date</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {wallet.map((walletDetail: any, index: any) => (
                                <tr key={index}>
                                  <td>{index+1}</td>
                                  <td>{walletDetail.settlementId}</td>
                                  <td>{walletDetail.date}</td>
                                  <td>{walletDetail.amount}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {currentItems.length > 0 && (
                <nav className="pagination-wrap mb-4">
                  <p className="mb-0">
                    Showing {indexOfFirstItem + 1} to{" "}
                    {Math.min(indexOfLastItem, wallet.length)} of{" "}
                    {wallet.length} items
                  </p>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(1)}
                      >
                        <i className="material-symbols-outlined">first_page</i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={prevPage}
                        aria-label="Previous"
                      >
                        <i className="material-symbols-outlined">
                          navigate_before
                        </i>
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        <i className="material-symbols-outlined">
                          navigate_next
                        </i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(pages)}
                      >
                        <i className="material-symbols-outlined">last_page</i>
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export default ViewSettlements;
