import {useEffect, useRef, FC, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {  topRatedDish } from '../../core/_requests';
import {useAuth} from '../../core/Auth';

interface Dish {
    dishImage: string ;
    name:string;
    uuid:string;
    images:string;
    mrpPrice:string;
    type:{
        typeIcon:string;
    };
    dishreviews: {
        reviews: number; 
      }[];
      dishvariants: {
        price: number;
        finalPrice: number;
      }[];
}

const TopRatedDish = () => {
  const [data, setData] = useState<Dish[]>([]);
  const { currentUser } = useAuth();
  const navigate= useNavigate()
  const chefId =currentUser?.uuid
  const viewDetails=()=>{
    navigate('/dish/list')
  }

 useEffect(() => {
    topRatedDish(chefId)
    .then((res: any) => {
     setData(res);
     })
    .catch((res)=>{
        console.log("res",res)
    });
}, []);


  return (
    <div className="snippet-block btm-same-height">
    <div className="head">
        <h6 className="mb-0 fw-bold">Most Rated Dishes</h6>
        <a href="javascript:;" className="text-link" onClick={viewDetails}> 
            View All
            <i className="material-symbols-outlined fall">
                arrow_forward
            </i>
        </a>
    </div>
    <div className="snippet-content">
      {data.length > 0 ? (
          data.map((item, i) => (
          <div className="dish-card mb-4">
              <div className="dish">
                  <div className="thumbnail">
                      <img src={item?.dishImage} alt={item?.name}/>
                  </div>
                  {/* <div className="content">
                      <img className="category-ico" src={item?.type?.typeIcon} alt={item?.name}/>
                      <div className="rating">
                          <img className="ico"  alt="Star"/>
                          {renderStars(item?.dishreviews[0]?.reviews)}
                      </div>
                  </div> */}
                  <div className="content">
                      <img className="category-ico" src={item?.type?.typeIcon} alt={item?.name}/>
                      <p className="mb-0 text-truncate"><small>{item?.name}</small></p>
                      <i className="bi bi-star"></i>
                      <div className="rating">
                      <img className="ico fs-3" style={{width:"23px"}} src="../media/star.svg" alt="Star"/>
                          {item?.dishreviews[0]?.reviews}
                      </div>
                  </div>
              </div>
              <p className="price mb-0 text-end pe-1 mid-gray">       {item.dishvariants && item.dishvariants.length > 0 ? ( // Check if dishvariants is defined and not empty
          <small>$ {item.dishvariants[0].finalPrice}</small>
        ) : (
          <small>$ 0</small> // Provide a default value when dishvariants is undefined or empty
        )}</p>
          </div>
        ))
      ):(
    <div className="text-center">
    <div className="thumbnail mt-5">
        <img src='../../../assets/images/noDishes.svg' style={{height:"50px"}} />
      </div>
      No Rated Dishes
  </div>
      )} 
      
    </div>
</div>
  )
}



export {TopRatedDish}
