import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import "../../../assets/custom-style.css";
import { useAuth } from "../../../auth";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  CreateAddons,
  checkDuplicateAddon,
  enableStatus,
  listAddonData,
} from "../core/_requests";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UUID } from "crypto";
import Header from "../../sidebar/Header";
import AddAddon from "./AddAddon";
import EditAddons from "./EditAddon";

interface Addons {
  chefId: string | undefined;
  name: string;
  primaryId: any;
  amount: any;
  type: any;
  enableDisableStatus: boolean;
  createdBy: any;
  updatedBy: any;
}

const initialValues: Addons = {
  primaryId: "",
  chefId: "",
  name: "",
  amount: "",
  type: "",
  enableDisableStatus: true,
  createdBy: "",
  updatedBy: "",
};
const ListAddons = () => {
  console.log("ListAddons");

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Addons>(initialValues);
  const [listType, setListType] = useState<Addons[]>([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [Duplicate, setDuplicate] = useState("");
  const chefId = currentUser?.uuid;

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dataSearch = searchParams.get("search");
  const [showEditAddons, setShowEditAddons] = useState(false);

  const [selectedPrimaryId, setSelectedPrimaryId] = useState<
    string | undefined
  >("");

  const [selectedData, setSelectedData] = useState<Addons | null>(null);
  const [showAddon, setShowAddon] = useState(false);
  

  const AddAddons = () => {
    navigate("/addons/add");
  };

  const openEditAddons = (primaryId: string) => {
    setSelectedPrimaryId(primaryId);
    const selected = listType.find((item) => item.primaryId === primaryId);
    if (selected) {
      setSelectedData(selected);
      setShowEditAddons(prevState => !prevState); // Toggle the state
    } else {
      console.error("Item not found with primaryId:", primaryId);
    }
  };
  
  const closeEditAddons = () => {
    setShowEditAddons(false);
  };
  

  const filterData = {
    search: dataSearch,
  };
  useEffect(() => {
    if (dataSearch !== undefined) {
      console.log("filterData", filterData);
      setSearch(search);
      listAddonData(chefId, filterData)
        .then((res: any) => {
          console.log("res?.data-", res?.data);
          setListType(res?.data);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error("Error fetching dishes:", error);
          setLoading(false);
        });
    }

    return () => {
      // Cleanup function to reset dish when component unmounts
      setSearch(""); // Reset dish to empty string
    };
  }, [search]);
  const formik = useFormik<Addons>({
    initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, "Only letters are allowed in the Type Name")
        .max(50, "Maximum 50 letters")
        .required("Type Name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      values.chefId = chefId;
      values.createdBy = currentUser?.chefId;
      values.updatedBy = currentUser?.chefId;
      const updatedData = Object.assign(data, values);
      console.log("updatedData", updatedData);
      setLoading(true);
      CreateAddons(updatedData)
        .then((res) => {
          console.log("res?.status", res?.status);
          if (res?.status === true) {
            toast.success(res?.message, { autoClose: 5000 });
            // toast.success("Your added ingredient has to be approved by admin. Please wait for some time.");
            navigate("/addons", { replace: true });
            setLoading(false);
            // onHide();
          } else {
            toast.error("Failed to add addons. Please try again later."); // Failure toaster notification
            setLoading(false);
          }
        })
        .catch((err) => {
          // setLoading(false);
          // setErrorMessage("Something Went Wrong");
        });
      resetForm();
    },
  });

  useEffect(() => {
    checkDuplicateAddon(formik.values.name,chefId).then((response) => {
      if (response?.isDuplicate === true) {
        setDuplicate("Addons name already exist");
      } else {
        setDuplicate("");
      }
    });
  }, [formik.values.name]);

  const clickApply = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    //pagination
    setCurrentPage(1);
    const data = {
      search: search,
    };

    try {
      const res = await listAddonData(chefId, data);
      setListType(res?.data); // Update the listType state with the new data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dishes:", error);
      setLoading(false);
    }
  };
  const editAddons = (primaryId: string) => {
    navigate("/addons/edit/" + primaryId);
  };
  
  const deleteDish = (primaryId: any) => {
    navigate("/addons/delete/" + primaryId);
  };

  const handleEnableDisable = (id: any, status: any) => {
    if (status === true) {
      var enableDisable = false;
    } else {
      var enableDisable = true;
    }
    const data = { enableDisableStatus: enableDisable };
    enableStatus(id, data).then((res) => {
      toast.success(res?.message, { autoClose: 5000 });
      navigate("/addons");
      setLoading(false);
    });
  };
  const AddType = () => {
    setShowModal(true);
  };
  const closeType = () => {
    setShowModal(false);
  };
  //pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listType.slice(indexOfFirstItem, indexOfLastItem);
  const pages = Math.ceil(listType.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage;

    let pageNumberContent;

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <a
            className={`page-link cursorPointer ${
              number === currentPage ? "active" : ""
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className="page-item">
          <a
            className="page-link cursorPointer"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    }

    return pageNumberContent;
  });

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <Header />
          <section className="content-section">
            <section className="row main-content">
              <div className="title mb-3">
                <h3 className="mb-0">Addons ({listType?.length})</h3>
              </div>
              <div className="col-12 title-btm">
                <div className="search">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="material-symbols-outlined">search</i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type..."
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyUp={clickApply}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end title-btm-btn-sec">
                  <button
                    className="btn btn-primary btn-ico rounded-5 ms-auto me-2"
                    onClick={AddAddons}
                  >
                    <i className="material-symbols-outlined">add_circle</i> Add
                    Addons
                  </button>
                 
                </div>
              </div>

              <div className="table-wrap mt-4">
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Amount</th>
                      <th>Type</th>
                      <th>Available</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>

                          <td>
                            <p className="mb-0">{item?.name}</p>
                          </td>
                          <td>
                            <p className="mb-0">{item?.amount}</p>
                          </td>
                          <td>
                            <p className="mb-0 type">
                              <img src={item?.type?.iconUrl} alt="Veg" />{" "}
                              {item?.type?.dishTypeName}
                            </p>
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input cursorPointer"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={item?.enableDisableStatus}
                                onClick={() =>
                                  handleEnableDisable(
                                    item?.primaryId,
                                    item?.enableDisableStatus
                                  )
                                }
                              />
                              <label className="yes">Yes</label>
                              <label className="no">No</label>
                            </div>
                          </td>
                          <td>
                            <div className="action-item">
                            <a className="success cursorPointer"  
                                onClick={() => editAddons(item?.primaryId)}>
                                <span className="material-symbols-outlined">
                                  edit
                                </span>
                              </a>
                              <a
                                className="danger cursorPointer"
                                onClick={() => deleteDish(item?.primaryId)}
                              >
                                <span className="material-symbols-outlined">
                                  delete
                                </span>
                              </a>
                          
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          Loading...{" "}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {currentItems.length > 0 && (
                <nav className="pagination-wrap mb-4">
                  <p className="mb-0">
                    Showing {indexOfFirstItem + 1} to{" "}
                    {Math.min(indexOfLastItem, listType.length)} of{" "}
                    {listType.length} items
                  </p>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(1)}
                      >
                        <i className="material-symbols-outlined">first_page</i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={prevPage}
                        aria-label="Previous"
                      >
                        <i className="material-symbols-outlined">
                          navigate_before
                        </i>
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        <i className="material-symbols-outlined">
                          navigate_next
                        </i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(pages)}
                      >
                        <i className="material-symbols-outlined">last_page</i>
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export default ListAddons;
