import { FC, ReactNode, useEffect, useState } from "react";

import { AlertContext } from "./index";
type WithChildren = {
  children?: ReactNode;
};
const AlertProvider: FC<WithChildren> = ({ children }) => {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (alert.show) {
      timeout = setTimeout(() => {
        hideAlert(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [alert.show]);
  const showAlert = (message: string, type: any) => {
    setAlert({ show: true, message: message, type: type });
  };

  const hideAlert = (hide: boolean) => {
    const btn = document.getElementById("alertBox");
    if (btn) {
      btn.setAttribute("style", "display:none!important;");
    }
    setAlert({ show: false, message: " ", type: " " });
  };
  return (
    <AlertContext.Provider
      value={{
        show: alert.show,
        message: alert.message,
        type: alert.type,
        showAlert,
        hideAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
