import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { verifyChefEmail } from "../core/_requests";

const OtpverificationError = () => {
  return (
    <div className="container-fluid signup-sec py-3">
      <div className="row">
        <div className="col-xl-5 col-md-5 d-none d-lg-block ">
          <div className="signup-img">
            <img
              src="./assets/images/signup-grid.png"
              className="img-fluid"
              alt="img-grid"
            />
          </div>
        </div>
        <div className="col-xl-7 col-lg-7 ">
          <div className="right-form verification-content px-4 d-flex  mt-5 flex-column ">
            <div className="logo-wrap mb-5">
              <img
                src="./assets/images/logo.svg"
                className="img-fluid"
                alt="logo"
              />
            </div>

            <div className="mt-5 ">
              <div className="row mb-4">
                <div className="col-xl-7">
                  <h2>Warning</h2>
                  <p className="small-font mb-5 opacity-80 custom-error-messages">
                    Before proceeding, please complete the signup process to
                    create your account. Thank you.
                  </p>

                  <p className="text-start small-font mt-4">
                    Didn’t SignUp ?{" "}
                    <Link className="link" to="/signup">
                      Signup here
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { OtpverificationError };
