import axios from "axios";
import { getToken } from "../../../auth";
//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;
const ORDERS_URL = `${API_URL}chefPortal`;

const listOrdersData = async (ChefId: any) => {
  const response = await axios.get(`${API_URL}chefPortal/listChefOrder/${ChefId}`)
  return response.data;
};

const getStatus = async () => {
  const res = await axios.get(
    `${API_URL}chefPortal/getOrderStatus`
  );
  return res;
};
const changeStatusOrders = async (id: any,orderStatus: any) => {
  console.log("api-orderstatus",orderStatus)
  const res = await axios.post(`${ORDERS_URL}/changeChefOrderStatus/${id}`, orderStatus);
  console.log("api-res.data-changeStatusOrders",res.data)
  return res.data;
};
const currentlyAvailable = async (id: any, currentlyAvailableStatus: any) => {
  const response = await axios
    .put(`${API_URL}chefPortal/chefAvailable/${id}`, currentlyAvailableStatus);
  return response.data;
};


const viewOrdersById = async (id: any) => {
  const response = await axios.get(`${ORDERS_URL}/viewChefOrder/${id}`, getToken());
  return response.data;
};

const getHistory = async (ChefId: any,filterData:any) => {
   console.log("filterData",filterData)
  const response = await axios.post(`${API_URL}chefPortal/orderHistory/${ChefId}`,filterData)
  return response.data;
};

const getCategory = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getChefCategory/`);
  return res;
};

const getCuisine = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getCuisine`);
  return res;
};
const getOrderStatus = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getOrderStatus`);
  return res;
};
const getUser = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getUser`, getToken());
  return res;
};
export {
  listOrdersData,
  getStatus,
  currentlyAvailable,
  changeStatusOrders,
  getHistory,
  viewOrdersById,
  getCategory,
  getCuisine,
  getOrderStatus,
  getUser

};
