import { useNavigate } from "react-router-dom";

import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase/FirebaseConfig";
const SocialSignup = (libraries: any) => {
  const navigate = useNavigate();
  const handleGoogle = async (e: any) => {
    const googleProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      const chef = result?.user;
      console.log("chef=====1223", chef);
      if (chef) {
        const data = {
          email: chef.email,
          name: chef.displayName,
          mobileNumber: chef?.phoneNumber || "",
          token: token,
        };
        console.log("data=====1223", data);
        localStorage.setItem("data", JSON.stringify(data));
        if (chef.email) {
          navigate("/choose-addess");
        }
      }
    } catch (error: any) {
      if (error.code === "auth/cancelled-popup-request") {
        console.log("Popup request cancelled");
      } else {
        console.error("Error signing in with Google:", error);
      }
    }
  };

  const handleFacebook = async () => {
    console.log("handleFacebook--entery check");
    const facebookProvider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log("FACEBOOK TRY TO GET CHEF --result", result);
      const chef = result.user;
      console.log("result.user---facebook", result.user);
      console.log("chef---facebook", chef);
      console.log("chef.displayName---facebook", chef?.displayName);
      console.log(
        "FACEBOOK CHEF chef?.providerData?.[0]",
        chef?.providerData?.[0]
      );
      console.log("FACEBOOK CHEF email", chef?.providerData?.[0]?.email);
      console.log(
        "FACEBOOK CHEF phoneNumber",
        chef?.providerData?.[0]?.phoneNumber
      );
      const email = chef?.providerData?.[0]?.email;
      // const credential = FacebookAuthProvider.credentialFromResult(result);
      // const accessToken = credential ? credential.accessToken : null;
      if (chef) {
        console.log("if checking---chef---facebook", chef);

        const data = {
          email: email,
          name: chef.displayName,
          mobileNumber: chef?.phoneNumber || "",
        };
        console.log("data---if-signup", data);
        localStorage.setItem("data", JSON.stringify(data));
        if (email) {
          console.log("EMAILLLLL--if con-social signup", email);
          navigate("/choose-addess");
        }
      }
    } catch (error) {
      // Handle errors...
      console.error("Error during Facebook sign-in:", error);
    }
  };

  const handleApple = async () => {
    console.log("Apple WORKING");
    const provider = new OAuthProvider("apple.com");
    try {
      const email = provider.addScope("email");
      const name = provider.addScope("name");
      console.log("email", email, "name", name);
      // provider.setCustomParameters({
      //   locale: 'us'
      // });

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const chef = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential?.accessToken;
          const idToken = credential?.idToken;
          if (chef) {
            const data = {
              email: chef?.email,
              name: chef?.displayName,
              mobileNumber: chef?.phoneNumber || "",
              accessToken: accessToken,
            };
            localStorage.setItem("data", JSON.stringify(data));
            if (chef.email) {
              navigate("/choose-addess");
            }
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          // ...
        });
    } catch (error) {
      // Handle errors...
      console.error("Error during Facebook sign-in:", error);
    }
  };
  return (
    <div>
      <div className="d-flex w-100 align-items-center justify-content-center mt-4 ss-ico">
        <a className="btn" onClick={handleGoogle}>
          <img
            alt="Google Logo"
            src="./assets/images/google-icon.svg"
            className="ico google"
          />
        </a>

        <a className="btn" onClick={handleFacebook}>
          <img
            alt="Facebook Logo"
            src="./assets/images/facebook.png"
            className="ico fb"
          />
        </a>

        <a className="btn" onClick={handleApple}>
          <img
            alt="apple Logo"
            src="./assets/images/apple.png"
            className="ico"
          />
        </a>
      </div>
    </div>
  );
};
export { SocialSignup };
