import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import "../../../assets/custom-style.css";
import { useAuth } from "../../../auth";
import { useParams } from "react-router-dom";

// import { checkDuplicateBank, updateBank } from "../core/_requests";

import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";
import ListSettlements from "./ListSettlements";
import { updateBank } from "../core/_requests";

interface BankAdd {
  primaryId: string | undefined;
  chefId: string | undefined;
  bankId: string | undefined;
  routingNumber: string;
  accountName: string;
  accountNumber: string;
  phoneNumber: string;
  address: string;
  createdBy: any;
  updatedBy: any;
}

type Props = {
  initialValues: BankAdd;
};

const EditSettlements: FC<Props> = ({ initialValues }) => {
  console.log("initialValues",initialValues);
  
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [data, setData] = useState<BankAdd>(initialValues);
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [Duplicate, setDuplicate] = useState("");
  const [currentDate] = useState(new Date());

  const { id } = useParams();

  const clickCancel = () => {
    navigate("/settlements");
  };

  const Schema = Yup.object().shape({
    routingNumber: Yup.string()
    .matches(/^\d{1,10}$/, 'Routing number must be numeric and between 1 to 10 digits')
      .required('Routing number is required'),
    accountName: Yup.string()
      .max(50, 'Maximum 50 characters')
      .matches(/^[a-zA-Z\s]*$/, 'Only letters and spaces are allowed')
      .required('Account name is required'),
    accountNumber: Yup.string()
      .matches(/^\d{1,10}$/, 'Account number must be numeric and between 1 to 10 digits')
      .required('Account number is required'),
    bankId: Yup.string()
      .max(50, 'Maximum 50 characters')
      .matches(/^[a-zA-Z\s]*$/, 'Only letters and spaces are allowed')
      .required('Bank name is required'),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, 'Phone number must be 10 digits')
      .required('Phone number is required'),
 
  });

  const formik = useFormik<BankAdd>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, actions) => {
      values.chefId = currentUser?.uuid;
      values.createdBy = currentUser?.chefId;
      values.updatedBy = currentUser?.chefId;
      const updatedData = Object.assign(data, values);
      setLoading(true);
      updateBank(id, updatedData).then((res) => {
        if (res?.status === true) {
          navigate("/settlements/editsuccess", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
          setErrorMessage(res?.message);
        }
      });
    },
  });

  // useEffect(() => {
  //   checkDuplicateBank(formik.values.routingNumber).then((response) => {
  //     if (response?.isDuplicate === true) {
  //       setDuplicate("Routing Number already exist");
  //     } else {
  //       setDuplicate("");
  //     }
  //   });
  // }, [formik.values.routingNumber]);

  const nextDate = new Date();
  nextDate.setDate(currentDate.getDate());
  console.log("formik.values", formik.values);

  return (
    <>
      <section className="container-fluid addContainerFluid">
        <ListSettlements />
        <div className="overlay"></div>
        <div className="drawer small">
          <div className="title row">
            <h3 className="col-10 mb-0">Edit Bank Details</h3>
            <div className="col-2 text-end mb-2">
              <button
                className="btn p-0 close d-inline-flex"
                onClick={clickCancel}
              >
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Routing Number
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.routingNumber &&
                      formik.errors.routingNumber,
                  },
                  {
                    "is-valid":
                      formik.touched.routingNumber &&
                      !formik.errors.routingNumber,
                  }
                )}
                placeholder="Routing Number"
                {...formik.getFieldProps(`routingNumber`)}
              />
              {formik.touched.routingNumber && formik.errors.routingNumber && (
                <div className="fv-help-block text-danger">
                  {formik.errors.routingNumber}
                </div>
              )}
              {/* <span style={{ color: "red" }}>{Duplicate ? Duplicate : ""}</span> */}
            </div>

            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                A/c Name
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.accountName && formik.errors.accountName,
                  },
                  {
                    "is-valid":
                      formik.touched.accountName && !formik.errors.accountName,
                  }
                )}
                placeholder="Account Name"
                {...formik.getFieldProps(`accountName`)}
              />
              {formik.touched.accountName && formik.errors.accountName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.accountName}
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                A/c Number
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber,
                  },
                  {
                    "is-valid":
                      formik.touched.accountNumber &&
                      !formik.errors.accountNumber,
                  }
                )}
                placeholder="Account Number"
                {...formik.getFieldProps(`accountNumber`)}
              />
              {formik.touched.accountNumber && formik.errors.accountNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.accountNumber}
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Bank Name
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.bankId && formik.errors.bankId,
                  },
                  {
                    "is-valid": formik.touched.bankId && !formik.errors.bankId,
                  }
                )}
                placeholder="Bank Name"
                {...formik.getFieldProps(`bankId`)}
              />
              {formik.touched.bankId && formik.errors.bankId && (
                <div className="fv-help-block text-danger">
                  {formik.errors.bankId}
                </div>
              )}
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.phoneNumber && formik.errors.phoneNumber,
                  },
                  {
                    "is-valid":
                      formik.touched.phoneNumber && !formik.errors.phoneNumber,
                  }
                )}
                placeholder="Phone Number "
                {...formik.getFieldProps(`phoneNumber`)}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.phoneNumber}
                  </div>
                </div>
              )}
            </div>

            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Address
              </label>
              <textarea
                className={clsx(
                  "form-control textarea",
                  {
                    "is-invalid":
                      formik.touched.address && formik.errors.address,
                  },
                  {
                    "is-valid":
                      formik.touched.address && !formik.errors.address,
                  }
                )}
                id=""
                cols={30}
                rows={10}
                placeholder=" Address"
                {...formik.getFieldProps(`address`)}
              ></textarea>
            </div>

            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-8 text-end">
                  <button
                    className="btn btn-cancel rounded-5"
                    onClick={clickCancel}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={formik.isSubmitting || !formik.isValid}
                    className="btn btn-outline-primary rounded-5 me-2"
                  >
                    {!loading && (
                      <span className="indicator-label">Update</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default EditSettlements;
