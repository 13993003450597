import { useNavigate } from "react-router-dom";
const Verifysuccess = () => {
  const navigate = useNavigate();

  const doneHandle = () => {
    navigate("/login", { replace: true });
  };

  const SettedChefName = localStorage.getItem("name");
  const capitalizedChefName = SettedChefName.charAt(0).toUpperCase() + SettedChefName.slice(1);
  console.log("SettedChefName",capitalizedChefName)
  return (
    <div className="container-fluid signup-sec py-3">
      <div className="row">
        <div className="col-xl-5 col-md-5 d-none d-lg-block ">
          <div className="signup-img">
            <img
              src="./assets/images/signup-grid.png"
              className="img-fluid"
              alt="img-grid"
            />
          </div>
        </div>
        <div className="col-xl-7 col-lg-7 ">
          <div className="right-form px-4 d-flex justify-content-center flex-column vh-100">
            <div className="content-sec">
              <div className="row">
                <div className="col-xl-12">
                  <div className="d-flex flex-column text-center align-items-center justify-content-center">
                    <div className="success-icon d-flex justify-content-center mb-4">
                      <img
                        src="./assets/images/sheild-icon.svg"
                        alt="success-icon"
                      />
                    </div>
                    <h2 className="mb-3">Great!</h2>
                    <p className="small-font opacity-80 mb-2">
                     Hi! {capitalizedChefName},Your email has been verified successfully.
                    </p>
                    <h6 className="mb-5 medium">
                      After verifying your profile, we'll email you the login
                      details.
                    </h6>
                    <button
                      className="btn primary-btn px-5 text-center"
                      onClick={doneHandle}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Verifysuccess };
