import React, { useEffect, useState } from "react";
import "../../../assets/custom-style.css";
import "../../../assets/custom-style.scss";
import { useAuth } from "../../../auth";
import {
  listOrdersData,
  currentlyAvailable,
  getStatus,
} from "../core/_requests";
import { useNavigate } from "react-router-dom";


interface Orders {
  primaryId: number;
  orderstatusId: number;
  uuid: string;
  orderId: string;
  chefId: string;
  userId: string;
  amount: number;
  tax: number;
  deliveryCharge: number;
  deliveryType: number;
  couponApplied: boolean;
  couponAmount: number | null;
  walletDiscount: number | null;
  deliveryPersonTips: number;
  chefTips: number;
  paymentMethod: string;
  netValue: number;
  orderDate: string;
  orderTime: string;
  totalAmount: number;
  orderstatus: {
    name: string;
  };
  chef: {
    name: string;
    currentlyAvailableStatus: boolean;
    email: string;
    phoneNumber: string | null;
    phoneNumber1: string | null;
    mobileNumber: string;
    mobileNumber1: string | null;
    location: string | null;
    licence: string | null;
    chefProfile: string | null;
    approvalStatus: boolean;
    address1: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    status: number;
    enableDisableStatus: boolean;
  };
  orderdetails: [
    {
      id: number;
      orderId: string;
      dishId: string;
      quantity: number;
      amount: number;
      dish: {
        name: string;
        dishImage: string;
        price: number;
        addons: [
          {
            id: number;
            name: string;
            quantity: number;
            price: number;
          }
        ];
      };
    }
  ];
  user: {
    id: number;
    uuid: string;
    userProfile: string;
    deviceId: string | null;
    token: string;
    verifyStatus: string | null;
    deviceType: string | null;
    deviceToken: string | null;
    loginType: string | null;
    name: string;
    email: string;
    latitude: string | null;
    longitude: string | null;
    contactNumber: string;
    plansId: string | null;
    planStartDate: string | null;
    planEndDate: string | null;
    planStatus: string | null;

    addressbooks: [
      {
        label: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        country: string;
        zipcode: any;
      }
    ];
  };
}
interface Status {
  id: number;
  name: string;
  active?: boolean;
}

const MenuOrders = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [chefStatus, setChefStatus] = useState<boolean>(
    currentUser?.currentlyAvailableStatus ?? false
  );
  // const [chefStatus, setChefStatus] = useState<boolean>(false);
  const [listOrders, setListOrders] = useState<Orders[]>([]);
  const [status, setStatus] = useState<Status[]>([]);
  // const [statusId, setStatusId] = useState("");
  // const [serialNo, setSerialNo] = useState("");
  const [loading, setLoading] = useState(true);
  // const [search, setSearch] = useState("");
  const chefId =currentUser?.uuid
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  // const [showOfflineModal, setShowOfflineModal] = useState(false); // State for showing offline modal

  useEffect(() => {
    getStatus()
      .then((res) => {
        const statusData = res?.data?.data.map((s: any) => ({
          ...s,
          active: s.name === "ordered", // Set active to true for "ordered" status
        }));
        setStatus(statusData);
      })
      .catch((error) => {
        console.error("Error fetching order statuses:", error);
      });
  }, []);
  useEffect(() => {
    listOrdersData(chefId).then((res) => {
      setListOrders(res?.data);
      setLoading(false);
    });
  }, [chefId]);
  useEffect(() => {
    if (chefStatus) {
      setNotificationMessage("You are online now");
    } else {
      setNotificationMessage("You are offline");
    }

    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(true);
    }, 60000);
  }, [chefStatus]);

  useEffect(() => {
    const currentStatus = false;
    setChefStatus(currentStatus);
  }, []);

  useEffect(() => {
    const storedStatus = localStorage.getItem("chefStatus");
    if (storedStatus !== null) {
      setChefStatus(JSON.parse(storedStatus));
    }
  }, []);

  const handleChefStatusChange = async () => {
    const newStatus = !chefStatus;
    const data = {
      currentlyAvailableStatus: newStatus,
    };
    await currentlyAvailable(chefId, data);
    localStorage.setItem("chefStatus", JSON.stringify(newStatus));
    setChefStatus(newStatus);

    // Redirect only within the Orders module
    if (newStatus) {
      navigate("/orders/success");
    } else {
      navigate("/orders/list");
    }
  };

  const openHistory = () => {
    console.log("history");
    navigate("/orders/history");
  };

  return (
    <>
     
      <div className="col-12 title-btm mt-2 flex-row align-items-center">
      <div className="title">
        <h3 className="mb-0">Orders</h3>
      </div>
        {/* <form action="#" className="search">
           <div className="input-group">
            <span className="input-group-text">
              <i className="material-symbols-outlined">search</i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
            />
          </div> 
        </form> */}
        <div className="d-flex align-items-center">
          <button
            className="btn btn-primary btn-ico rounded-5 btn-empty"
            onClick={openHistory}
          >
            <i className="material-symbols-outlined">history</i>
            <span>History</span>
          </button>
      
        </div>
      </div>
    </>
  );
};

export default MenuOrders;
