import React, { FC, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import "../../../assets/custom-style.css";
import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";
import ListDishes from "./ListDishes";
import { useAuth } from "../../../auth";
import { useParams } from "react-router-dom";

import {
  getCategory,
  getCuisine,
  getType,
  DishUpdate,
  listIngredient,
  getMeasurement,
  checkDuplicateDish,
  getDishAddons,
  getAddonsType,
} from "../core/_requests";
import Select from "react-select";
import AvatarEditor from "react-avatar-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIngredient from "./AddIngredient";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
interface Cuisine {
  uuid: UUID;
  name: string;
}

interface Ingredient {
  id: string;
  name: string;
}

interface Type {
  uuid: UUID;
  name: string;
}

interface Category {
  uuid: UUID;
  name: string;
}

interface EditDish {
  chefId: any;
  name: string;
  categoryId: string;
  typeId: string;
  cuisineId: string;
  description: string;
  specialInstruction: string;
  ingredients: string;
  dishImage: any;
  formImage: any;
  tags: string;
  createdBy: any;
  updatedBy: any;
  dishvariants: [
    {
      unit: string;
      measurementId: number;
      price: any;
      discount: any;
      finalPrice: any;
    }
  ];
  addons: [
    {
      name: string;
      quantity: string;
      price: string;
    }
  ];
  dish_addons: [
    {
      addonsTypeId: string;
      addonsId: string;
      createdBy: number;
    }
  ];
}

const initialValues: EditDish = {
  chefId: "",
  name: "",
  categoryId: "",
  typeId: "",
  cuisineId: "",
  description: "",
  specialInstruction: "",
  ingredients: "",
  dishImage: "",
  formImage: "",
  tags: "",
  createdBy: "",
  updatedBy: "",
  dishvariants: [
    {
      unit: "",
      measurementId: 2,
      price: "",
      discount: "",
      finalPrice: "",
    },
  ],
  addons: [
    {
      name: "",
      quantity: "",
      price: "",
    },
  ],
  dish_addons: [
    {
      addonsTypeId: "",
      addonsId: "",
      createdBy: 0,
    },
  ],
};

interface Measurement {
  id: string;
  name: string;
  measurementName: string;
}
interface AddonsType {
  uuid: UUID;
  typeName: string;
  enableDisableStatus: boolean;
}
interface dish_addons {
  uuid: UUID;
  addonsName: string;
  amount: number;
  enableDisableStatus: boolean;
  type: any;
}
type Props = {
  initialValues: EditDish;
};
const Schema = Yup.lazy((values) => {
  const measurementId = values.dishvariant?.[0]?.measurementId || "";
  const isSizeMeasurement = measurementId === 3; // Change to the actual measurement ID for sizes

  return Yup.object().shape({
    name: Yup.string()
      .max(50, "Maximum 50 characters")
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "Only letters, numbers, and spaces are allowed"
      )
      .required("Name is required"),
    categoryId: Yup.string().required("Category is required"),
    typeId: Yup.string().required("Type is required"),
    cuisineId: Yup.string().required("Cuisine is required"),
    ingredients: Yup.string().required("Ingredient is required"),

    dishvariant: Yup.array().of(
      Yup.object().shape({
        unit: isSizeMeasurement
          ? Yup.string().required("Unit is required")
          : // .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed")
            Yup.number()
              // .typeError("Must be a number")
              .required("Unit is required"),
        measurementId: Yup.number().required("Measurement ID is required"),
        price: Yup.number()
          .required("Price is required")
          .typeError("Price must be a number")
          .test(
            "is-floating-number",
            "Price must be a floating-point number",
            (value) => {
              const stringValue = String(value);
              return (
                !isNaN(parseFloat(stringValue)) &&
                /^\d*\.?\d*$/.test(stringValue)
              );
            }
          ),
        discount: Yup.number()
          .typeError("Discount must be a number")
          .test(
            "is-floating-number",
            "Discount must be a floating-point number",
            (value) => {
              const stringValue = String(value);
              return (
                !isNaN(parseFloat(stringValue)) &&
                /^\d*\.?\d*$/.test(stringValue)
              );
            }
          ),
        finalPrice: Yup.number()
          .required("Final Price is required")
          .typeError("Final Price must be a number")
          .test(
            "is-floating-number",
            "Final Price must be a floating-point number",
            (value) => {
              const stringValue = String(value);
              return (
                !isNaN(parseFloat(stringValue)) &&
                /^\d*\.?\d*$/.test(stringValue)
              );
            }
          ),
      })
    ),
  });
});
const EditDish: FC<Props> = ({ initialValues }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [formImage, setFormImage] = useState<File | null>(null);
  const [data, setData] = useState<EditDish>(initialValues);
  console.log("initialValues---edit dish", initialValues);
  const [loading, setLoading] = useState(false);
  const [cuisine, setCuisine] = useState<Cuisine[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [ingredient, setIngredient] = useState<Ingredient[]>([]);
  const [type, setType] = useState<Type[]>([]);
  const [selectedType, setSelectedType] = useState("");
  const [activeType, setActiveType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAddons, setShowAddons] = useState(true);
  const fileInputRef = useRef(null);
  const [editImage, setEditImage] = useState(false);
  const [scale, setScale] = useState(1);
  const [Duplicate, setDuplicate] = useState("");
  const [rotation, setRotation] = useState(0);
  const [editWidth, setEditWidth] = useState(250); // Initial width value
  const [editHeight, setEditHeight] = useState(250); // Initial height value
  const editorRef = useRef<AvatarEditor>(null);
  const [showIngredient, setShowIngredient] = useState(false);
  const [measurement, setMeasurement] = useState<Measurement[]>([]);
  const [addonsType, setAddonsType] = useState<AddonsType[]>([]);
  const chefId =currentUser?.uuid
  const [selectedAddons, setSelectedAddons] = useState<{
    [key: string]: string[];
  }>({});
  const handleCheckboxChangeDishaddon = (addonTypeUuid: any, itemUuid: any) => {
    setSelectedAddons((prevState) => {
      const selected = new Set(prevState[addonTypeUuid] || []);
      if (selected.has(itemUuid)) {
        selected.delete(itemUuid);
      } else {
        selected.add(itemUuid);
      }
      return {
        ...prevState,
        [addonTypeUuid]: Array.from(selected),
      };
    });
  };

  useEffect(() => {
    // Fetch cuisines and types
    getAddonsType(chefId)
      .then((res) => {
        console.log("setAddonsType-res-add dish", res?.data?.data);
        console.log("setAddonsType-res-add dish-------res.data", res?.data);
        setAddonsType(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching cuisines:", error);
      });
      getDishAddons(chefId)
      .then((res) => {
        console.log("setAddons-res-add dish", res?.data?.data);
        console.log("setAddons-res-add dish-------res.data", res?.data);
        setdish_addons(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching cuisines:", error);
      });
  }, []);
  useEffect(() => {
    const dish_addonsArray = Object.keys(selectedAddons).flatMap((addonType) => {
      return selectedAddons[addonType].map((addonId) => ({
        addonsTypeId: addonType,
        addonsId: addonId,
      }));
    });

    formik.setFieldValue("dish_addons", dish_addonsArray);
  }, [selectedAddons]);

  console.log("addonsType", addonsType);

  const [dish_addons, setdish_addons] = useState<dish_addons[]>([]);
  console.log("dish_addons", dish_addons);
  const [selectedMeasurementName, setSelectedMeasurementName] =
    useState<string>("");
  const handleMeasurementChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMeasurementId = e.target.value; // Assuming IDs are strings
    const selectedMeasurement = measurement.find((item) => {
      return String(item.id).trim() === selectedMeasurementId.trim();
    });
    if (selectedMeasurement) {
      setSelectedMeasurementName(selectedMeasurement.measurementName);
    } else {
      setSelectedMeasurementName("Unit");
    }
  };

  const modalIngredient = () => {
    setShowIngredient(!showIngredient);
    listIngredent();
  };
  const [selectedCategory, setSelectedCategory] = useState<{
    label: string;
    value: string;
  } | null>(null);
  console.log("initialValues-edit-dish", initialValues);
  console.log("initialValues-edit-dish", initialValues?.dishvariants);
  const [selectedCuisine, setSelectedCuisine] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [addons, setAddons] = useState([{ name: "", quantity: "", price: "" }]);
  const [dishvariant, setdishvariant] = useState([
    { unit: 0, measurementId: 0, price: 0, discount: 0, finalPrice: 0 },
  ]);
  console.log("data", data);

  const handlePriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newPrice = e.target.value;
    const currentVariant = formik.values.dishvariants[index];
    if (currentVariant) {
      const newFinalPrice = parseFloat(newPrice) - currentVariant.discount;
      formik.setFieldValue(`dishvariants[${index}].price`, newPrice);
      formik.setFieldValue(`dishvariants[${index}].finalPrice`, newFinalPrice);
    }
  };

  const handleDiscountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newDiscount = e.target.value;
    const currentVariant = formik.values.dishvariants[index];
    const newFinalPrice = currentVariant.price - parseFloat(newDiscount);
    formik.setFieldValue(`dishvariants[${index}].discount`, newDiscount);
    formik.setFieldValue(`dishvariants[${index}].finalPrice`, newFinalPrice);
  };

  const addDishPriceRow = () => {
    const newRow = {
      unit: 0,
      measurementId: 0,
      price: 0,
      discount: 0,
      finalPrice: 0,
    };
    setdishvariant((dishvariant: any) => [...dishvariant, newRow]);
    // Update Formik field value along with local state update
    formik.setFieldValue("dishvariants", [
      ...formik.values.dishvariants,
      newRow,
    ]);
  };
  const DishPricRemove = (i: number) => {
    const newForm = [...dishvariant];
    newForm.splice(i, 1);
    setdishvariant(newForm);
  };
  const inputs = useRef<Array<HTMLInputElement | HTMLSelectElement | null>>([]);

  const toggleEditImage = (e: any) => {
    e.preventDefault();
    setEditImage(!editImage);
  };

  const handleImageEdit = () => {
    if (editorRef.current) {
      const canvas = (editorRef.current as any).getImageScaledToCanvas();
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              setData({
                ...data,
                dishImage: reader.result,
              });
            }
          };
          reader.readAsDataURL(blob);
        }
      });
    }
    setEditImage(false); // Close the edit image section after saving
  };
  const handleErrorMessageClose = () => {
    setErrorMessage("");
    setData({
      ...data,
      dishImage: null,
    });
    // Reset the file input value
    if (fileInputRef.current) {
      (fileInputRef.current as any).value = "";
    }
  };

  const addRow = () => {
    const newRow = {
      quantity: "",
      price: "",
      name: "",
    };
    // Update the state immutably by creating a new array with the new row appended
    setAddons((prevAddons) => [...prevAddons, newRow]);
  };

  const addRemove = (i: number) => {
    const newForm = [...addons];
    newForm.splice(i, 1);
    setAddons(newForm);
  };

  useEffect(() => {
    getCuisine()
      .then((res) => {
        setCuisine(res?.data?.data);
        setSelectedCuisine(res?.data?.data);
        // Set the initial value for the Category dropdown
        const initialCuisine = res?.data?.data.find(
          (cus: any) => cus.uuid === initialValues.cuisineId
        );
        if (initialCuisine) {
          setSelectedCuisine({
            label: initialCuisine.name,
            value: initialCuisine.uuid,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
    // get type
    getType()
      .then((res) => {
        setType(res?.data?.data);
        // Set the initial value for the Type dropdown
        const initialType = res?.data?.data.find(
          (type: any) => type.uuid === initialValues.typeId
        );
        if (initialType) {
          setActiveType(initialType.name);
          setSelectedType(initialType.name);
        }
      })
      .catch((error) => {
        console.error("Error fetching types:", error);
      });
    // get ingiredient

    getMeasurement()
      .then((res) => {
        setMeasurement(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching types:", error);
      });
    listIngredent();
    getCategory(!chefId)
      .then((res) => {
        setCategory(res?.data?.data);
        // Set the initial value for the Category dropdown
        const initialCategory = res?.data?.data.find(
          (cat: any) => cat.uuid === initialValues.categoryId
        );
        if (initialCategory) {
          setSelectedCategory({
            label: initialCategory.name,
            value: initialCategory.uuid,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  const listIngredent = () => {
    listIngredient(chefId)
      .then((res) => {
        console.log("integredents--", res?.data?.data);
        setIngredient(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching ingredients:", error);
      });
  };
  const clickCancel = () => {
    navigate("/dish");
  };

  const handleRemoveStrength = (indexToRemove: any) => {
    const updatedtags = formik.values.tags
      .split(",")
      .filter((_, index) => index !== indexToRemove)
      .join(",");
    formik.setFieldValue("tags", updatedtags);
  };

  // Add a function to handle changes in the textarea
  const handleTagInputChange = (inputValue: any) => {
    formik.setFieldValue("tags", inputValue);
  };

  const handleCheckboxChangeaddon = (value: string) => {
    if (value === "option1") {
      setShowAddons(!showAddons);
    }
  };

  const compressImage = async (file: File) => {
    return new Promise<Blob>((resolve) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800; // Max width for compressed image
        const maxHeight = 600; // Max height for compressed image
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(image, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              }
            },
            file.type,
            0.7
          ); // Adjust the compression quality here (0.7 means 70% quality)
        }
      };
    });
  };

  const imageHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const files = e.target.files;

    if (files && files[0]) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/webp",
      ];
      const maxFileSize = 1024 * 1024; // 1MB
      const maxAllowedSize = 500 * 1024; // 500KB

      if (!allowedTypes.includes(files[0].type)) {
        setErrorMessage(
          "Invalid file type. Allowed types are JPEG, PNG, JPG, and WEBP."
        );
        setFormImage(null);
        return;
      } else if (files && files[0].size > 1024 * 1024) {
        // Check if file size exceeds 1MB
        setErrorMessage("File size should be less than 1MB.");
        setFormImage(null);
      }
      if (files[0].size > maxFileSize) {
        const compressedImage = await compressImage(files[0]);
        const compressedFile = new File([compressedImage], files[0].name, {
          type: files[0].type,
          lastModified: Date.now(),
        });

        reader.onload = () => {
          if (reader.readyState === 2) {
            setData({
              ...data,
              dishImage: reader.result,
            });
          }
        };

        setFormImage(compressedFile);
        reader.readAsDataURL(compressedFile);
      } else {
        setErrorMessage("");
        reader.onload = () => {
          if (reader.readyState === 2) {
            setData({
              ...data,
              dishImage: reader.result,
            });
          }
        };
        setFormImage(files[0]);
        reader.readAsDataURL(files[0]);
      }
    }
  };
  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditWidth(parseInt(e.target.value));
  };

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditHeight(parseInt(e.target.value));
  };

  const formik = useFormik<EditDish>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      values.chefId = currentUser?.uuid;
      values.updatedBy = currentUser?.chefId;
      const formData = new FormData();
      if (formImage) formData.append("dishImage", formImage);
      formData.append("name", values.name);
      formData.append("categoryId", values.categoryId);
      formData.append("chefId", values.chefId);
      formData.append("typeId", values.typeId);
      formData.append("cuisineId", values.cuisineId);
      formData.append("specialInstruction", values.specialInstruction);
      formData.append("description", values.description);
      formData.append("ingredients", values.ingredients);
      formData.append("tags", values.tags);
      values.dishvariants.forEach((dishvariants, index) => {
        formData.append(
          `dishvariants[${index}][unit]`,
          String(dishvariants.unit)
        );
        formData.append(
          `dishvariants[${index}][measurementId]`,
          String(dishvariants.measurementId)
        );
        formData.append(
          `dishvariants[${index}][price]`,
          String(dishvariants.price)
        );
        formData.append(
          `dishvariants[${index}][discount]`,
          String(dishvariants.discount)
        );
        formData.append(
          `dishvariants[${index}][finalPrice]`,
          String(dishvariants.finalPrice)
        );
      });
      values.addons.forEach((addon, index) => {
        formData.append(`addons[${index}][name]`, addon.name);
        formData.append(`addons[${index}][quantity]`, addon.quantity);
        formData.append(`addons[${index}][price]`, addon.price);
      });
      values.dish_addons.forEach((dish_addons, index) => {
        formData.append(
          `dish_addons[${index}][addonsTypeId]`,
          dish_addons.addonsTypeId
        );
        formData.append(`dish_addons[${index}][addonsId]`, dish_addons.addonsId);
        formData.append(`dish_addons[${index}][createdBy]`, values.createdBy);
      });
      setLoading(true);
      DishUpdate(id, formData)
        .then((res) => {
          if (res?.status === true) {
            navigate("/dish/edit", { replace: true });
            setLoading(false);
          } else {
            setLoading(false);
            setErrorMessage(res?.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage("Something Went Wrong");
        });
      resetForm();
    },
  });

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index < inputs.current.length - 1) {
        // If not the last input field, focus on the next input
        const nextIndex = index + 1;
        inputs.current[nextIndex]?.focus();
      } else {
        // If the last input field, submit the form
        event.currentTarget.form?.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    }
  };
  console.log("Edit DISH ->>>formik", formik);

  return (
    <>
      <section className="container-fluid addContainerFluid">
        <ListDishes />
        <div className="overlay"></div>
        <div className="drawer small add-dish">
          <div className="title row">
            <h3 className="col-10 mb-0">Edit Your Dish</h3>
            <div className="col-2 text-end mb-2">
              <button
                className="btn p-0 close d-inline-flex"
                onClick={clickCancel}
              >
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Dish Name
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.name && formik.errors.name,
                  },
                  {
                    "is-valid": formik.touched.name && !formik.errors.name,
                  }
                )}
                ref={(el) => (inputs.current[2] = el)}
                onKeyDown={(e) => handleKeyPress(e, 2)}
                placeholder="Give a name to dish"
                {...formik.getFieldProps(`name`)}
                onChange={(e) => {
                  formik.handleChange(e);
                  // Perform duplicate check here
                  checkDuplicateDish(
                    currentUser?.uuid,
                    e.target.value
                  ).then((response) => {
                    if (response?.isDuplicate === true) {
                      setDuplicate("Dish name already exists");
                    } else {
                      setDuplicate("");
                    }
                  });
                }}
              />

              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              )}
              <span style={{ color: "red" }}>{Duplicate ? Duplicate : ""}</span>
            </div>

            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Cuisine
              </label>
              <Select
                className={clsx(
                  "",
                  {
                    "is-invalid":
                      formik.touched.cuisineId && formik.errors.cuisineId,
                  },
                  {
                    "is-valid":
                      formik.touched.cuisineId && !formik.errors.cuisineId,
                  }
                )}
                options={cuisine.map((item) => ({
                  label: item.name,
                  value: item.uuid,
                }))}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedCuisine(selectedOption);
                    formik.setFieldValue("cuisineId", selectedOption.value);
                  }
                }}
                value={selectedCuisine}
                placeholder="Select cuisine"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "20px", // Change the border radius as needed
                  }),
                }}
              />
              {formik.touched.cuisineId && formik.errors.cuisineId && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.cuisineId}</span>
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Category
              </label>
              <Select
                className={clsx(
                  "",
                  {
                    "is-invalid":
                      formik.touched.categoryId && formik.errors.categoryId,
                  },
                  {
                    "is-valid":
                      formik.touched.categoryId && !formik.errors.categoryId,
                  }
                )}
                options={category.map((item) => ({
                  label: item.name,
                  value: item.uuid,
                }))}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedCategory(selectedOption);
                    formik.setFieldValue("categoryId", selectedOption.value);
                  }
                }}
                value={selectedCategory}
                placeholder="Select category"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "20px", // Change the border radius as needed
                  }),
                }}
              />
            </div>
            {formik.touched.categoryId && formik.errors.categoryId && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.categoryId}</span>
              </div>
            )}
            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Type
              </label>
              <div className="row type-snippet">
                {type.map((item: any, i) => {
                  const isActive = activeType === item?.name;
                  return (
                    <div
                      className={`mt-1 snippet ${isActive ? "active" : ""}`}
                      key={i}
                      onClick={() => {
                        formik.setFieldValue("typeId", item?.uuid);
                        setActiveType(item?.name);
                        setSelectedType(isActive ? "" : item?.name);
                      }}
                    >
                      <span>
                        <img src={item?.icon} alt={item?.name} /> {item?.name}
                      </span>

                      <div className="ico">
                        <img
                          className={isActive ? "tick" : "cross"}
                          src={`../../dish_images/${
                            isActive ? "tick" : "cross"
                          }-circle-ico.svg`}
                          alt="ico"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {formik.touched.typeId && formik.errors.typeId && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.typeId}</span>
                </div>
              )}
            </div>

            <div className="col-12 mb-3">
              {/* // Dish Price Division */}
              <div className="addon-wrap">
                {formik.values.dishvariants.map((dish, index) => (
                  <div className="row gx-2 mb-2 position-relative" key={index}>
                    <div className="col-md-3 col-6">
                      <label
                        htmlFor=""
                        className="form-label me-3 d-flex align-items-center"
                      >
                        Measurement
                        <span className="mb-0 d-flex align-items-center opacity-40 mt-0">
                          <i
                            className="material-symbols-outlined info-ico me-2"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={`For Eg: 1.Size(Small, Medium, Large), 2. Piece (1,2,3), 3.Ounce (4,8,12,16)`}
                          >
                            info
                          </i>
                        </span>
                      </label>
                      <select
                        className="form-select form-control mb-2"
                        {...formik.getFieldProps(
                          `dishvariants[${index}].measurementId`
                        )}
                        onChange={(e) => {
                          formik.handleChange(e); // Ensure Formik handles the change event
                          handleMeasurementChange(e); // Handle custom logic for measurement change
                        }}
                      >
                        <option value="">Choose</option>
                        {measurement.map((item: Measurement, id: number) => (
                          <option value={item?.id} key={id}>
                            {item?.measurementName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2 col-6">
                      <label htmlFor="" className="form-label">
                        {selectedMeasurementName || "Unit"}
                      </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="unit..."
                        ref={(el) => (inputs.current[2] = el)}
                        onKeyDown={(e) => handleKeyPress(e, 2)}
                        {...formik.getFieldProps(`dishvariants[${index}].unit`)}
                      />
                      {formik.touched.dishvariants &&
                        formik.touched.dishvariants[index]?.unit &&
                        formik.errors.dishvariants &&
                        (formik.errors.dishvariants[index] as { unit?: string })
                          ?.unit && (
                          <div className="fv-plugins-message-container">
                            <span role="alert">
                              {
                                (
                                  formik.errors.dishvariants[index] as {
                                    unit?: string;
                                  }
                                )?.unit
                              }
                            </span>
                          </div>
                        )}
                    </div>

                    <div className="col-md-2 col-6">
                      <label htmlFor="" className="form-label">
                        Price ($)
                      </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder=" $ 00.00"
                        ref={(el) => (inputs.current[2] = el)}
                        onKeyDown={(e) => handleKeyPress(e, 2)}
                        {...formik.getFieldProps(
                          `dishvariants[${index}].price`
                        )}
                        onChange={(e) => handlePriceChange(e, index)}
                      />
                      {formik.touched.dishvariants &&
                        formik.touched.dishvariants[index]?.price &&
                        formik.errors.dishvariants &&
                        (formik.errors.dishvariants as any[])[index]?.price && (
                          <div className="fv-plugins-message-container">
                            <span role="alert">
                              {
                                (formik.errors.dishvariants[index] as any)
                                  ?.price
                              }
                            </span>
                          </div>
                        )}
                    </div>
                    <div className="col-md-2 col-6">
                      <label htmlFor="" className="form-label">
                        Discount
                      </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="$ 00.00"
                        ref={(el) => (inputs.current[2] = el)}
                        onKeyDown={(e) => handleKeyPress(e, 2)}
                        {...formik.getFieldProps(
                          `dishvariants[${index}].discount`
                        )}
                        onChange={(e) => handleDiscountChange(e, index)}
                      />
                    </div>

                    <div className="col-md-3 col-12">
                      <label htmlFor="" className="form-label">
                        Final Price ($)
                      </label>
                      <input
                        type="text"
                        readOnly
                        className="form-control mb-2"
                        placeholder="$ 00.00"
                        ref={(el) => (inputs.current[2] = el)}
                        onKeyDown={(e) => handleKeyPress(e, 2)}
                        {...formik.getFieldProps(
                          `dishvariants[${index}].finalPrice`
                        )}
                      />
                      {formik.touched.dishvariants &&
                        formik.touched.dishvariants[index]?.finalPrice &&
                        formik.errors.dishvariants &&
                        (formik.errors.dishvariants as any[])[index]
                          ?.finalPrice && (
                          <div className="fv-plugins-message-container">
                            <span role="alert">
                              {
                                (formik.errors.dishvariants[index] as any)
                                  ?.finalPrice
                              }
                            </span>
                          </div>
                        )}
                    </div>

                    <a
                      className="danger cursorPointer mt-4"
                      onClick={() => DishPricRemove(index)}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </a>
                  </div>
                ))}
              </div>

              <p className="text-end mb-0">
                <a
                  className="text-link tiny cursorPointer"
                  onClick={addDishPriceRow}
                >
                  + Add New
                </a>
              </p>

              {/* addons Start */}
              <div className="row os-body">
                <div className="col-12">
                  <h6 className="mb-2">&nbsp;</h6>
                  <div
                    className="accordion bill-toggle px-0"
                    id="accordionExample"
                  >
                    {addonsType.map((addonType, addonIndex) => (
                      <div
                        className="accordion-item"
                        key={`addonType-${addonIndex}`}
                      >
                        <h2
                          className="accordion-header"
                          id={`heading-${addonIndex}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${addonIndex}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${addonIndex}`}
                          >
                            <h6 className="mb-0">
                              {addonType?.typeName}
                              <span>({dish_addons.length})</span>
                            </h6>
                          </button>
                        </h2>
                        <div
                          id={`collapse-${addonIndex}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading-${addonIndex}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {dish_addons.map((item, i) => (
                              <div className="row mb-2" key={i}>
                                <p className="col-5 mid-gray mb-0 ">
                                  <input
                                    type="checkbox"
                                    id={item.uuid}
                                    value={item.uuid}
                                    checked={(
                                      selectedAddons[addonType.uuid] || []
                                    ).includes(item.uuid)}
                                    onChange={() =>
                                      handleCheckboxChangeDishaddon(
                                        addonType.uuid,
                                        item.uuid
                                      )
                                    }
                                  />
                                  <small className="px-3">
                                    {item.addonsName}
                                  </small>
                                </p>
                                <h6 className="col-4 mid-gray medium mb-0 amount">
                                  {item.type.dishTypeName}
                                </h6>
                                <h6 className="col-3 mid-gray medium mb-0 amount">
                                  $ {item.amount}
                                </h6>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* end? */}
              {/* // Addons Division */}
              {/* <div className="form-check form-check-inline mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="addonCheck"
                  value="option1"
                  ref={(el) => (inputs.current[2] = el)}
                  onKeyDown={(e) => handleKeyPress(e, 2)}
                  onChange={(e) => handleCheckboxChangeaddon(e.target.value)}
                />
                <label className="form-check-label ms-2" htmlFor="addonCheck">
                  Add-on
                </label>
                <p className="mb-1 mid-gray">
                  <span className="tiny">
                    If there's a charge for any food add-ons, provide details
                    here
                    <a href="#" className="text-link">
                      {" "}
                    </a>
                  </span>
                </p>
              </div> */}

              {/* {showAddons && (
                <div className="addon-wrap">
                  {formik.values.addons.map((addon, index) => (
                    <div
                      className="row gx-2 mb-2 position-relative"
                      key={index}
                    >
                      <div className="col-md-6 col-12">
                        <input
                          type="text"
                          className="form-control mb-2"
                          placeholder="Addons..."
                          ref={(el) => (inputs.current[2] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 2)}
                          {...formik.getFieldProps(`addons[${index}].name`)}
                        />
                      </div>
                      <div className="col-md-3 col-6">
                        <input
                          type="text"
                          className="form-control mb-2"
                          placeholder="Quantity"
                          ref={(el) => (inputs.current[2] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 2)}
                          {...formik.getFieldProps(`addons[${index}].quantity`)}
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                        />
                      </div>
                      <div className="col-md-3 col-6">
                        <input
                          type="text"
                          className="form-control mb-2"
                          placeholder="$ 00.00"
                          ref={(el) => (inputs.current[2] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 2)}
                          {...formik.getFieldProps(`addons[${index}].price`)}
                          onKeyPress={(e) =>
                            !/^\d*\.?\d*$/.test(e.key) && e.preventDefault()
                          }
                        />
                      </div>
                      <a
                        className="danger cursorPointer"
                        onClick={() => addRemove(index)}
                      >
                        <i className="material-symbols-outlined">delete</i>
                      </a>
                    </div>
                  ))}
                </div>
              )} */}

              {/* {showAddons && (
                <p className="text-end mb-0">
                  <a className="text-link tiny cursorPointer" onClick={addRow}>
                    + Add New
                  </a>
                </p>
              )} */}
            </div>

            <div className="col-12 mb-3 mt-1">
              <label htmlFor="" className="form-label">
                Tags (Optional)
              </label>
              <div className="strengths-tags mt-1 mb-1">
                {formik.values.tags &&
                  formik.values.tags.split(",").map((tag, index) => (
                    <span key={index} className="tag-wrapper">
                      <span className="tag-text">{tag.trim()}</span>
                      <button
                        type="button"
                        className="btn-close btn-sm btn-close-gray ms-2"
                        onClick={() => handleRemoveStrength(index)}
                        style={{ fontSize: "0.45rem" }}
                      ></button>
                    </span>
                  ))}
              </div>

              <input
                type="text"
                ref={(el) => (inputs.current[2] = el)}
                onKeyDown={(e) => handleKeyPress(e, 2)}
                value={formik.values.tags}
                onChange={(e) => handleTagInputChange(e.target.value)}
                className={clsx(
                  "form-control h-auto",
                  {
                    "is-invalid": formik.touched.tags && formik.errors.tags,
                  },
                  {
                    "is-valid": formik.touched.tags && !formik.errors.tags,
                  }
                )}
              />
              <p className="text-success">
                {" "}
                <small>Separate using comma (,)</small>
              </p>
            </div>

            <div className="col-12 mb-3 row">
              <label className="form-label">Ingredients</label>
              <div className="d-flex align-items-center">
                <Select
                  isMulti={true}
                  className="col-12"
                  options={ingredient.map((item: any) => ({
                    label: item.name,
                    value: item.id.toString(), // Convert ID to string
                  }))}
                  name="ingredients"
                  placeholder="Select ingredient"
                  value={
                    formik.values.ingredients
                      ? formik.values.ingredients
                          .split(",")
                          .map((item: string) => ({
                            label: ingredient.find(
                              (i) => i.id.toString() === item
                            )?.name,
                            value: item,
                          }))
                      : []
                  }
                  onChange={(selectedOptions) => {
                    if (selectedOptions.length <= 30) {
                      const selectedValues = selectedOptions
                        .map((option) => option.value)
                        .join(",");
                      formik.setFieldValue("ingredients", selectedValues);
                    }
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "20px", // Change the border radius as needed
                    }),
                  }}
                />
                <button
                  className="btn btn-outline-primary btn-sm rounded-4 mt-2 col-1 mb-2 ms-3 ingrd-add-btn"
                  style={{ width: "32px", height: "32px", padding: "0" }}
                  onClick={modalIngredient}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <AddIngredient show={showIngredient} onHide={modalIngredient} />
              </div>
            </div>
            {formik.touched.ingredients && formik.errors.ingredients && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.ingredients}</span>
              </div>
            )}
            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Special Instruction (Optional)
              </label>
              <textarea
                className={clsx(
                  "form-control textarea",
                  {
                    "is-invalid":
                      formik.touched.specialInstruction &&
                      formik.errors.specialInstruction,
                  },
                  {
                    "is-valid":
                      formik.touched.specialInstruction &&
                      !formik.errors.specialInstruction,
                  }
                )}
                id=""
                cols={30}
                rows={10}
                placeholder="Give a special instruction for your dish..."
                {...formik.getFieldProps(`specialInstruction`)}
              ></textarea>
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Description (Optional)
              </label>
              <textarea
                className={clsx(
                  "form-control textarea",
                  {
                    "is-invalid":
                      formik.touched.description && formik.errors.description,
                  },
                  {
                    "is-valid":
                      formik.touched.description && !formik.errors.description,
                  }
                )}
                id=""
                cols={30}
                rows={10}
                placeholder="Give a description for your dish..."
                {...formik.getFieldProps(`description`)}
              ></textarea>
            </div>
            <div className="col-12 mb-3">
              <p className="mb-1 mid-gray">
                <span className="tiny">
                  We have a photo for your dish{" "}
                  <a href="#" className="text-link">
                    {" "}
                  </a>
                </span>
              </p>
              <div className="file-input">
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={imageHandler}
                  ref={fileInputRef}
                />
                <label htmlFor="file">
                  <i className="material-symbols-outlined">upload</i>
                  <span> Upload Dish Image </span>
                </label>
              </div>
              <span className="file-info">
                Supported file types (.jpg, .png, .jpeg) Max file size: 1MB
              </span>
              {data?.dishImage && (
                <>
                  <img
                    src={data?.dishImage}
                    height="100"
                    width="100"
                    alt="Uploaded Dish"
                  />
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={(e) => toggleEditImage(e)}
                  >
                    <i className="material-symbols-outlined fs-18">edit</i>
                  </button>
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={handleErrorMessageClose}
                  >
                    <i className="material-symbols-outlined fs-18">close</i>
                  </button>
                </>
              )}
              {errorMessage && (
                <div style={{ color: "red" }} className="custom-error-messages">
                  {errorMessage}
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={handleErrorMessageClose}
                  >
                    <i className="material-symbols-outlined fs-18">close</i>
                  </button>
                </div>
              )}
            </div>
            {/* Edit image section */}
            {editImage && (
              <div className="col-12">
                <AvatarEditor
                  ref={editorRef}
                  image={data?.dishImage}
                  width={editWidth}
                  height={editHeight}
                  border={50}
                  color={[255, 255, 255, 0.6]}
                  scale={scale}
                  rotate={rotation}
                />
                <div>
                  <button
                    className="btn btn-outline-primary rounded-5 me-2"
                    onClick={toggleEditImage}
                  >
                    Cancel Editing
                  </button>
                  <button
                    className="btn btn-outline-primary rounded-5"
                    onClick={handleImageEdit}
                  >
                    Save Image
                  </button>
                  <div className="col-12 mt-3">
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="width">Width:</label>
                        <input
                          id="width"
                          type="number"
                          className="form-control"
                          min="1"
                          ref={(el) => (inputs.current[2] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 2)}
                          value={editWidth}
                          onChange={handleWidthChange}
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="height">Height:</label>
                        <input
                          id="height"
                          className="form-control"
                          type="number"
                          min="1"
                          ref={(el) => (inputs.current[2] = el)}
                          onKeyDown={(e) => handleKeyPress(e, 2)}
                          value={editHeight}
                          onChange={handleHeightChange}
                        />
                      </div>
                    </div>

                    <input
                      className="col-6 mt-3"
                      type="range"
                      min="1"
                      max="2"
                      step="0.01"
                      ref={(el) => (inputs.current[2] = el)}
                      onKeyDown={(e) => handleKeyPress(e, 2)}
                      value={scale}
                      onChange={(e) => setScale(parseFloat(e.target.value))}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-12 text-end">
                  <button
                    className="btn btn-cancel rounded-5"
                    onClick={clickCancel}
                  >
                    Cancel
                  </button>

                  <button
                    disabled={formik.isSubmitting || !formik.isValid}
                    className="btn btn-outline-primary rounded-5 me-2"
                  >
                    {!loading && (
                      <span className="indicator-label">Publish</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default EditDish;
