import { useEffect, useState } from "react";
import "../../../assets/custom-style.css";
import "../../../assets/custom-style.scss";
import Header from "../../sidebar/Header";

const Help = () => {
  const [activeTab, setActiveTab] = useState<string>("signup");

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    const tabButtons = document.querySelectorAll(".nav-link");

    tabButtons.forEach((button) => {
      button.addEventListener("click", () => {
        tabButtons.forEach((btn) => {
          btn.classList.remove("active");
        });

        button.classList.add("active");
      });
    });
  }, []);

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <Header />
          <section className="content-section">
            <section className="row main-content">
              <div className="col-12 title-btm mt-2 flex-row align-items-center">
                <div className="title">
                  <h3 className="mb-0">Help</h3>
                  <div>
                    <h6 className="mb-12 mt-3">
                      Designed to streamline the order management process and
                      enhance Chef Efficiency.
                    </h6>
                    <p className="col-12 mid-gray mt-3 mb-1">
                      <small>
                        Provides a centralized platform for receiving and
                        managing orders, improving customer satisfaction.
                      </small>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2">
                <ul
                  className="nav nav-pills mb-4"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "signup" ? "active" : ""
                      }`}
                      id={`pills-signup-tab`}
                      onClick={() => handleTabClick("signup")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-signup`}
                    >
                      Sign Up
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "verify" ? "active" : ""
                      }`}
                      id={`pills-verify-tab`}
                      onClick={() => handleTabClick("verify")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-verify`}
                    >
                      Verify
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "dashboard" ? "active" : ""
                      }`}
                      id={`pills-dashboard-tab`}
                      onClick={() => handleTabClick("dashboard")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-dashboard`}
                    >
                      Dashboard
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "orders" ? "active" : ""
                      }`}
                      id={`pills-orders-tab`}
                      onClick={() => handleTabClick("orders")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-orders`}
                    >
                      Orders
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "dish" ? "active" : ""
                      }`}
                      id={`pills-dish-tab`}
                      onClick={() => handleTabClick("dish")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-dish`}
                    >
                      Dish
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "coupons" ? "active" : ""
                      }`}
                      id={`pills-coupons-tab`}
                      onClick={() => handleTabClick("coupons")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-coupons`}
                    >
                      Coupons
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "settlement" ? "active" : ""
                      }`}
                      id={`pills-settlement-tab`}
                      onClick={() => handleTabClick("settlement")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-settlement`}
                    >
                      Settlement
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "reports" ? "active" : ""
                      }`}
                      id={`pills-reports-tab`}
                      onClick={() => handleTabClick("reports")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-reports`}
                    >
                      Reports
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`mt-3 nav-link ${
                        activeTab === "profile" ? "active" : ""
                      }`}
                      id={`pills-profile-tab`}
                      onClick={() => handleTabClick("profile")}
                      type="button"
                      role="tab"
                      aria-controls={`pills-profile`}
                    >
                      Profile
                    </button>
                  </li>
                </ul>

                {/* Add tab content */}
                <div className="tab-content">
                  <div
                    className={`tab-pane fade ${
                      activeTab === "signup" ? "show active" : ""
                    }`}
                  >
                    <div className="order-snippet mb-3">
                      <div className="os-head">
                        <div>
                          <h5 className="mb-0 medium primary-color">
                            Getting Started
                          </h5>
                        </div>
                      </div>
                      <div className="row os-body mt-3">
                        <div className="col-12">
                          <div className="col-8">
                            <img
                              className="img-fluid-help mt-3 mb-4"
                              src="../media/help/signup.webp"
                              alt="signup"
                            />
                          </div>
                          <div className="bg-blk mb-3 mt-1">
                            <div className="row mb-2 mt-3">
                              <div className="col-12">
                                <h6 className="medium primary-color">
                                  Signing up for the HomeChef360 app :
                                </h6>
                                <div className="dish">
                                  <ul>
                                    {" "}
                                    <li className="mt-2">
                                      {" "}
                                      Launch the app and tap "Sign Up".
                                    </li>
                                    <li className="mt-2">
                                      {" "}
                                      Fill in name, contact details, and
                                      location.
                                    </li>
                                    <li className="mt-2">
                                      {" "}
                                      Read and accept the privacy policy.
                                    </li>
                                    <li className="mt-2">
                                      {" "}
                                      Tap "Sign Up" to complete the process.
                                    </li>
                                    <li className="mt-2">
                                      {" "}
                                      Alternatively, you can also sign up using
                                      your existing social media accounts such
                                      as Facebook, Google or Apple Account.
                                    </li>
                                    <li className="mt-2">
                                      {" "}
                                      Simply tap on the relevant social media
                                      button, grant necessary permissions, and
                                      proceed with the signup process.
                                    </li>
                                    <li className="mt-2">
                                      {" "}
                                      This allows for a quicker and more
                                      convenient registration experience.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Verify */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "verify" ? "show active" : ""
                      }`}
                    >
                      <div className="tab-pane fade show active">
                        <div className="order-snippet mb-3">
                          <div className="os-head">
                            <div>
                              <h5 className="mb-0 medium primary-color">
                                Identity Verification
                              </h5>
                            </div>
                          </div>
                          <div className="row os-body mt-3">
                            <div className="col-12">
                              <img
                                className="img-fluid-help mt-3 mb-4"
                                src="../media/help/verification.webp"
                                alt="signup"
                              />

                              <div className="bg-blk mb-3 mt-3">
                                <div className="row mb-2 mt-1">
                                  <div className="col-12">
                                    <h6 className="medium primary-color">
                                      Chefs need to verify their identity
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <li className="mt-2">
                                          {" "}
                                          Verification through email or mobile
                                          number.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Account undergoes admin approval.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Login credentials sent via email upon
                                          approval.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Dashboard */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "dashboard" ? "show active" : ""
                      }`}
                    >
                      <div className="tab-pane fade show active">
                        <div className="order-snippet mb-3">
                          <div className="os-head">
                            <div>
                              <h5 className="mb-0 medium primary-color">
                                Dashboard
                              </h5>
                            </div>
                          </div>
                          <div className="row os-body mt-3">
                            <div className="col-12">
                              <img
                                className="img-fluid-help mt-3 mb-4"
                                src="../media/help/dashboard.webp"
                                alt="dashboard"
                              />

                              <div className="bg-blk mb-3 mt-3">
                                <div className="row mb-2 mt-1">
                                  <div className="col-12">
                                    <h6 className="medium primary-color">
                                      Main hub for overseeing orders and sales
                                      activities:
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <li className="mt-2">
                                          {" "}
                                          Provides vital metrics (total orders,
                                          sales, rejected orders).
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Graphs offer insights on order trends.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Orders */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "orders" ? "show active" : ""
                      }`}
                    >
                      <div className="tab-pane fade show active">
                        <div className="order-snippet mb-3">
                          <div className="os-head">
                            <div>
                              <h5 className="mb-0 medium primary-color">
                                Orders
                              </h5>
                            </div>
                          </div>
                          <div className="row os-body mt-3">
                            <div className="col-12">
                              <img
                                className="img-fluid-help mt-3 mb-4"
                                src="../media/help/orders.webp"
                                alt="Orders"
                              />

                              <div className="bg-blk mb-3 mt-3">
                                <div className="row mb-2 mt-2">
                                  <div className="col-12">
                                    <h6 className="medium primary-color mb-3">
                                      Managing incoming orders:
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <li className="mt-2">
                                          {" "}
                                          Search options for finding specific
                                          orders.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Provides Real-time order tracking and
                                          progress updates.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Dish Management */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "dish" ? "show active" : ""
                      }`}
                    >
                      <div className="tab-pane fade show active">
                        <div className="order-snippet mb-3">
                          <div className="os-head">
                            <div>
                              <h5 className="mb-0 medium primary-color">
                                Dish Management
                              </h5>
                            </div>
                          </div>
                          <div className="row os-body mt-3">
                            <div className="col-12">
                              <img
                                className="img-fluid-help mt-3 mb-4"
                                src="../media/help/dish.webp"
                                alt="Orders"
                              />

                              <div className="bg-blk mb-3 mt-3">
                                <div className="row mb-2 mt-1">
                                  <div className="col-12">
                                    <h6 className="medium primary-color mb-2">
                                      Managing dishes in the app:
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <li className="mt-2">
                                          {" "}
                                          Add, view, edit, and delete dishes
                                          from menus.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Organize menu items with categories.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Add new dishes with details (names,
                                          prices, ingredients, etc.).
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Coupons  */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "coupons" ? "show active" : ""
                      }`}
                    >
                      <div className="tab-pane fade show active">
                        <div className="order-snippet mb-3">
                          <div className="os-head">
                            <div>
                              <h5 className="mb-0 medium primary-color">
                                Coupons Option
                              </h5>
                            </div>
                          </div>
                          <div className="row os-body mt-3">
                            <div className="col-12">
                              <img
                                className="img-fluid-help mt-3 mb-4"
                                src="../media/help/coupons.webp"
                                alt="Coupons"
                              />

                              <div className="bg-blk mb-3 mt-3">
                                <div className="row mb-2 mt-2">
                                  <div className="col-12">
                                    <h6 className="medium primary-color mb-3">
                                      Coupons Option
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <li className="mt-2">
                                          {" "}
                                          Users can access this section to find
                                          exclusive discounts and special deals.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          The coupons section provides savings
                                          and discounts on various dishes or
                                          services.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Coupons can be applied during the
                                          checkout process to avail of the
                                          discounts.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Settlements */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "settlement" ? "show active" : ""
                      }`}
                    >
                      <div className="tab-pane fade show active">
                        <div className="order-snippet mb-3">
                          <div className="os-head">
                            <div>
                              <h5 className="mb-0 medium primary-color">
                                Settlement
                              </h5>
                            </div>
                          </div>
                          <div className="row os-body mt-3">
                            <div className="col-12">
                              <img
                                className="img-fluid-help mt-3 mb-4"
                                src="../media/help/settlements.webp"
                                alt="settlement"
                              />

                              <div className="bg-blk mb-3 mt-3">
                                <div className="row mb-2 mt-1">
                                  <div className="col-12">
                                    <h6 className="medium primary-color mb-3">
                                      Managing payment preferences:
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <li className="mt-2">
                                          {" "}
                                          Settlement options for receiving
                                          payments.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Reports */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "reports" ? "show active" : ""
                      }`}
                    >
                      <div className="tab-pane fade show active">
                        <div className="order-snippet mb-3">
                          <div className="os-head">
                            <div>
                              <h5 className="mb-0 medium primary-color">
                                Reports
                              </h5>
                            </div>
                          </div>
                          <div className="row os-body mt-3">
                            <div className="col-12">
                              <img
                                className="img-fluid-help mt-3 mb-4"
                                src="../media/help/report.webp"
                                alt="Reports"
                              />

                              <div className="bg-blk mb-3 mt-3">
                                <div className="row mb-2 mt-1">
                                  <div className="col-12">
                                    <h6 className="medium primary-color mb-3">
                                      Insights into Business Performance:
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <li className="mt-2">
                                          {" "}
                                          Detailed reports on sales, order
                                          trends, and customer feedback.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Profile Settings */}
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade ${
                        activeTab === "profile" ? "show active" : ""
                      }`}
                    >
                      <div className="tab-pane fade show active">
                        <div className="order-snippet mb-3">
                          <div className="os-head">
                            <div>
                              <h5 className="mb-0 medium primary-color">
                                Profile Settings
                              </h5>
                            </div>
                          </div>
                          <div className="row os-body mt-3">
                            <div className="col-12">
                              <img
                                className="img-fluid-help mt-3 mb-4"
                                src="../media/help/settings.webp"
                                alt="settings"
                              />

                              <div className="bg-blk mb-3 mt-3">
                                <div className="row mb-2 mt-1">
                                  <div className="col-12">
                                    <h6 className="mb-0 medium primary-color mt-0">
                                      Customizing personal information and
                                      preferences:
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <h6 className="medium primary-color mt-3">
                                          Business Days Timing:
                                        </h6>
                                        <li className="mt-2">
                                          {" "}
                                          The HomeChef360 app allows users to
                                          set their operation timings for each
                                          day of the week.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          This feature enables Chefs to specify
                                          their availability and working hours
                                          on different days.
                                        </li>
                                        <h6 className="medium primary-color mt-3">
                                          Auto Enable/Disable Feature:
                                        </h6>
                                        <li className="mt-2">
                                          {" "}
                                          The Auto Enable/Disable feature in the
                                          HomeChef360 app is designed to
                                          optimize order management and
                                          availability.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Chefs can utilize this feature to
                                          manage their availability for
                                          receiving orders.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          By enabling this feature, the app
                                          automatically enables or disables the
                                          ability to receive orders based on the
                                          specified availability.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <h6 className="medium primary-color">
                                      Update personal information.
                                    </h6>
                                    <div className="dish">
                                      <ul>
                                        {" "}
                                        <h6 className=" medium primary-color mt-3">
                                          Change Password Feature:
                                        </h6>
                                        <li className="mt-2">
                                          {" "}
                                          The Chef portal provides a convenient
                                          Change Password feature.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Enter your current password followed
                                          by your new password. Ensure your new
                                          password meets the platform's
                                          requirements (e.g., length, special
                                          characters).
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Confirm your new password by entering
                                          it again.
                                        </li>
                                        <li className="mt-2">
                                          {" "}
                                          Save or update your changes using the
                                          designated button.
                                        </li>
                                      </ul>
                                    </div>
                                    {/* <h6 className="medium primary-color mt-3">
                                          Manage notifications.
                                        </h6>
                                        <h6 className="medium primary-color mt-3">
                                          Customize the app experience.
                                        </h6> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export default Help;
