import "../../../assets/custom-style.css";

import { useAuth } from "../../../auth";
import { useParams } from "react-router-dom";

import {
  deleteAddon,
} from "../core/_requests";
import { useNavigate } from "react-router-dom";
import Header from "../../sidebar/Header";

const DeleteAddon = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const deleteItem = () => {
    console.log("id===", id);
    deleteAddon(id)
      .then((res) => {
        console.log("res", res); // Add this line
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
    navigate("/addons");
  };

  const clickCancel = () => {
    navigate("/addons");
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          <Header />
          <div className="overlay"></div>
          <div className="drawer small">
            <div className="alert-wrap">
              <img
                className="ico mb-4"
                src="../../dish_images/alert-warning-ico.svg"
                alt="success"
              />
              <h1 className="mb-3">Are you sure?</h1>
              <p className="mid-gray mb-4 text-center">
                <small>
                  Do you want delete this Addon. You can n't retrieve
                  <br />
                  this Addon if you delete
                </small>
              </p>
              <button
                className="btn btn-danger rounded-5 px-5 mt-2 mb-2"
                onClick={deleteItem}
              >
                Yes, Delete
              </button>
              <button
                className="btn btn-cancel rounded-5"
                onClick={clickCancel}
              >
                No, I don't want to delete
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteAddon;
