import React, { FC, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChefBasicProfile, useAuth } from "../../../auth";
import { UpdatePassword } from "../core/_requests";

import Header from "../../sidebar/Header";

type Props = {
  initialValues: any;
};

const ChangePassword: FC<Props> = ({ initialValues }) => {
  const [showAlert, setShowAlert] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const chefId =currentUser?.uuid
  const inputs = useRef<Array<HTMLInputElement | HTMLSelectElement | null>>([]);
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index < inputs.current.length - 1) {
        // If not the last input field, focus on the next input
        const nextIndex = index + 1;
        inputs.current[nextIndex]?.focus();
      } else {
        // If the last input field, submit the form
        formik.handleSubmit();
      }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const clickCancel = () => {
    navigate("/settings");
  };

  const Schema = Yup.object().shape({
    currentpassword: Yup.string().required("Current password is required"),
    newpassword: Yup.string()
      .required("New password is required")
      .notOneOf(
        [Yup.ref("currentpassword"), null],
        "New password must be different from current password"
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        "New password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 6 characters long"
      ),
    confirmpassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("newpassword")],
        "Confirm password must match the new password"
      ),
  });

  const formik = useFormik<ChefBasicProfile>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      setLoading(true);
      const updatedData = Object.assign(data, values);
      UpdatePassword(updatedData, chefId).then((res) => {
        if (res) {
          setLoading(false);
          toast.success(res?.Message, { autoClose: 5000 });
          navigate("/settings/password-success", { replace: true });
        } else {
          setLoading(false);
          setErrorMessage(res?.message);

          if (updatedData.currentpassword !== updatedData.newpassword) {
            if (updatedData.newpassword === updatedData.confirmpassword) {
              UpdatePassword(updatedData, chefId).then(
                (res) => {
                  if (res) {
                    setLoading(false);
                    toast.success(res?.Message, { autoClose: 5000 });
                    navigate("/profile-success", { replace: true });
                  } else {
                    setLoading(false);
                    toast.error(res?.message);
                  }
                }
              );
              resetForm();
            } else {
              toast.error(
                "new Password and confirm Password are not same please enter both are same"
              );
              setLoading(false);
              setShowAlert(true);
            }
          } else {
            toast.error(
              "current Password and new Password are same please enter different one "
            );
            setLoading(false);
            setShowAlert(true);
          }
        }
      }); // This is where the misplaced parenthesis should be
    },
  });

  return (
    <>
      <section className="container-fluid">
        <div className="row">
          {/* <Sidebar />   */}
          <Header />
          <section className="content-section">
            {/* <Header /> */}
            <div className="overlay"></div>
            <div className="drawer small">
              <div className="title row">
                <h3 className="col-10 mb-0">Change Password</h3>
                <div className="col-2 text-end mb-2">
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={clickCancel}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </button>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="col-12 text-start mb-4 mt-3">
                  <label htmlFor="newpassword" className="small-font mb-2">
                    Current Password
                  </label>
                  <div className="input-group">
                    <input
                      placeholder="Give your current password"
                      type={showPassword ? "text" : "password"}
                      className={clsx("form-control", {
                        "is-invalid":
                          formik.touched.currentpassword &&
                          formik.errors.currentpassword,
                      })}
                      ref={(el) => (inputs.current[3] = el)}
                      onKeyDown={(e) => handleKeyPress(e, 3)}
                      {...formik.getFieldProps("currentpassword")}
                    />
                  </div>
                  {formik.touched.currentpassword &&
                    formik.errors.currentpassword && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">
                          {formik.errors.currentpassword}
                        </span>
                      </div>
                    )}
                </div>
                <div className="col-12 text-start mb-4">
                  <label htmlFor="newpassword" className="small-font mb-2">
                    New Password
                  </label>
                  <div className="input-group">
                    <input
                      placeholder="Give your change password"
                      type={showPassword ? "text" : "password"}
                      className={clsx("form-control", {
                        "is-invalid":
                          formik.touched.newpassword &&
                          formik.errors.newpassword,
                      })}
                      ref={(el) => (inputs.current[3] = el)}
                      onKeyDown={(e) => handleKeyPress(e, 3)}
                      {...formik.getFieldProps("newpassword")}
                    />
                  </div>
                  {formik.touched.newpassword && formik.errors.newpassword && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.newpassword}</span>
                    </div>
                  )}
                </div>
                <div className="col-12 text-start mb-4">
                  <label htmlFor="newpassword" className="small-font mb-2">
                    Confirm Password
                  </label>
                  <div className="input-group">
                    <input
                      placeholder="Give your confirm password"
                      type={showPassword ? "text" : "password"}
                      className={clsx("form-control", {
                        "is-invalid":
                          formik.touched.confirmpassword &&
                          formik.errors.confirmpassword,
                      })}
                      ref={(el) => (inputs.current[3] = el)}
                      onKeyDown={(e) => handleKeyPress(e, 3)}
                      {...formik.getFieldProps("confirmpassword")}
                    />
                  </div>
                  {formik.touched.confirmpassword &&
                    formik.errors.confirmpassword && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.newpassword}</span>
                      </div>
                    )}
                </div>
                <div className="col-12 mb-3 mt-4 pt-3">
                  <p className="fw-med mb-3">
                    <small>Password must have</small>
                  </p>
                  <ul className="pwd-criteria-list">
                    <li>Lower & Upper case</li>
                    <li>At least 1 number or symbol</li>
                    <li>At least 6 characters</li>
                    <li>Cannot contain spaces and “|” symbol</li>
                  </ul>
                </div>
                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-cancel rounded-5"
                        onClick={clickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={formik.isSubmitting || !formik.isValid}
                        className="btn btn-outline-primary rounded-5 me-2"
                      >
                        {!loading && (
                          <span className="indicator-label">Publish</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export { ChangePassword };
