
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Success from "./files/Success";
import Availability from "./files/Availability";
import ListAddon from "./files/ListAddon";
import DeleteAddon from "./files/DeleteAddon";

import EditSuccess from "./files/EditSuccess";
import AddAddons from "./files/AddAddon";
import { EditBasicDetailsWrapper } from "./files/EditWarpper";

const AddonPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <ListAddon />
            </>
          }
        />
    <Route
          path="/add"
          element={
            <>
              <AddAddons />
            </>
          }
        />
         <Route
          path="/delete/:id"
          element={
            <>
              <DeleteAddon />
            </>
          }
        />
        <Route
          path="/success"
          element={
            <>
              <Success />
            </>
          }
        />
         <Route
          path="/edit/:id"
          element={
            <>
              <EditBasicDetailsWrapper />
           
            </>
          }
        />
         <Route
          path="/editsuccess"
          element={
            <>
              <EditSuccess />
            </>
          }
        />
        <Route
          path="/available/:id/:status"
          element={
            <>
              <Availability />
            </>
          }
        />
        <Route index element={<Navigate to="list" />} />
      </Route>
    </Routes>
  );
};

export default AddonPage;
