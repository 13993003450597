import { useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAuth } from "../../auth";
import Success from "./files/Success";
import Availability from "./files/Availability";
import ListCoupon from "./files/ListCoupon";
import AddCoupon from "./files/AddCoupon";
import DeleteCoupon from "./files/DeleteCoupon";
import { EditBasicDetailsWrapper } from "./files/EditWarpper";
import EditSuccess from "./files/EditSuccess";

const CouponPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <ListCoupon />
            </>
          }
        />
        <Route
          path="/add"
          element={
            <>
              <AddCoupon />
            </>
          }
        />
       
        <Route
          path="/edit/:id"
          element={
            <>
              <EditBasicDetailsWrapper />
           
            </>
          }
        />
         <Route
          path="/delete/:id"
          element={
            <>
              <DeleteCoupon />
            </>
          }
        />
        <Route
          path="/success"
          element={
            <>
              <Success />
            </>
          }
        />
         <Route
          path="/editsuccess"
          element={
            <>
              <EditSuccess />
            </>
          }
        />
        <Route
          path="/available/:id/:status"
          element={
            <>
              <Availability />
            </>
          }
        />
        <Route index element={<Navigate to="list" />} />
      </Route>
    </Routes>
  );
};

export default CouponPage;
