import React, { Suspense } from "react";
import { SplashScreen } from "./features/SplashScreen";
import AlertProvider from "./auth/component/alert-context/alertContextProvider";
import { AuthInit } from "./auth";
import { Outlet } from "react-router-dom";
function App() {
  return (
    <Suspense fallback={<SplashScreen />}>
      <AlertProvider>
        <AuthInit>
          <Outlet />
        </AuthInit>
      </AlertProvider>
    </Suspense>
  );
}

export default App;
