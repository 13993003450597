import { useFormik } from "formik";
import * as Yup from "yup";
import { SetStateAction, useEffect, useState } from "react";
import { sendOtpData } from "../core/_requests";
import { useNavigate } from "react-router-dom";
import { ChefRegister } from "../core/_models";
import { toast } from "react-toastify";

const initialValues = {
  name: "",
  mobileNumber: "",
  email: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  address: "",
  longitude: "",
  latitude: "",

  formImage: "",
  Code: "",
  status: "",
  message: "",
  otpDevice: "",
  token: "",
};

const Otpdevice = (libraries: any) => {
  const navigate = useNavigate();
  const [data, setData] = useState<ChefRegister>(initialValues);
  const [email, setemail] = useState(localStorage.getItem("chefemail"));
  const [mobileNumber, setMobileNumber] = useState(
    localStorage.getItem("mobileNumber")
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const sigupSchema = Yup.object().shape({
    otpDevice: Yup.string().required("Select Your Otp  verification device"),
  });

  const formik = useFormik<ChefRegister>({
    initialValues,
    validationSchema: sigupSchema,
    onSubmit: async (values, { resetForm }) => {
      const updatedData = Object.assign(data, values);
      // //Image and data Append to Form Data
      setLoading(true);
      if (updatedData?.otpDevice === "1") {
        const data1 = {
          otpDevice: updatedData?.otpDevice,
          mobileNumber: mobileNumber,
        };
        console.log("otp-data1", data1);
        sendOtpData(data1).then((res) => {
          if (res?.status === true) {
            navigate("/otp-verification-mobile", { replace: true });
            toast.success(res?.message, { autoClose: 5000 });
            setLoading(false);
          }
        });
      }

      if (updatedData?.otpDevice === "2") {
        const data1 = {
          otpDevice: updatedData?.otpDevice,
          email: email,
        };
        sendOtpData(data1).then((res) => {
          if (res?.status === true) {
            navigate("/otp-verification", { replace: true });
            toast.success(res?.message, { autoClose: 5000 });
            setLoading(false);
          }
        });
      }
      resetForm();
    },
  });

  const onClickCancel = () => {
    navigate("/");
  };

  const handleCheckboxChange = (option: string) => {
    setSelectedOption(option);
    formik.setFieldValue("otpDevice", option);
  };
  const handleClose = () => {
    setShowAlert(false);
  };
  const onClickPre = () => {
    navigate("/auth");
  };
  return (
    <div className="container-fluid signup-sec py-3">
      <div className="row">
        <div className="col-xl-5 col-md-5 d-none d-lg-block ">
          <div className="signup-img">
            <img
              src="./assets/images/signup-grid.png"
              className="img-fluid"
              alt="img-grid"
            />
          </div>
        </div>
        <div className="col-xl-7 col-lg-7 ">
          <div className="right-form px-4  d-flex justify-content-between flex-column">
            <form onSubmit={formik.handleSubmit} className="custom-form">
              <div className="content-sec ">
                <div className="choose-device">
                  <div className="login-logo mb-4">
                    <img
                      src="./assets/images/logo.svg"
                      className="img-fluid"
                      alt="logo"
                    />
                  </div>

                  <div>
                    <p className="fw-bold fs-6">
                      Do you want to proceed verification through
                    </p>
                    {showAlert ? (
                      // <div className="mb-lg-15 alert alert-danger">
                      <div
                        className={`mb-lg-10 alert ${
                          showAlert ? "alert-danger" : "alert-success"
                        }`}
                      >
                        <div className="alert-text font-weight-bold">
                          {errorMessage}
                          <button
                            className="btn p-0 close d-inline-end"
                            onClick={handleClose}
                          >
                            <i className="material-symbols-outlined fs-18  px-10">
                              close
                            </i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    <div className="snippet-block mb-3">
                      <div className="left-wrap">
                        <div className="ico-wrap">
                          <span className="material-symbols-outlined  otp-style">
                            Email
                          </span>
                        </div>
                        <p className="mb-0">Email</p>
                      </div>

                      <div className="form-check form-check-inline me-0">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="inlineCheckbox2"
                          value="2"
                          style={{ borderRadius: "9px" }}
                          checked={selectedOption === "2"}
                          onChange={() => handleCheckboxChange("2")}
                        />
                      </div>
                    </div>
                    <div className="snippet-block mb-3">
                      <div className="left-wrap">
                        <div className="ico-wrap">
                          <span className="material-symbols-outlined  otp-style">
                            phone
                          </span>
                        </div>
                        <p className="mb-0"> Mobile Number</p>
                      </div>
                      <div className="form-check form-check-inline me-0">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="inlineCheckbox1"
                          value="1"
                          style={{ borderRadius: "9px" }}
                          checked={selectedOption === "1"}
                          onChange={() => handleCheckboxChange("1")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row w-100 m-0">
                    <div className="d-flex justify-content-md-end justify-content-center px-0">
                      <div className="d-flex gap-3">
                        <button
                          className="btn outline-btn px-5"
                          onClick={onClickPre}
                        >
                          Previous
                        </button>
                        <button
                          className="btn outline-btn px-5"
                          onClick={onClickCancel}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          disabled={formik.isSubmitting || !formik.isValid}
                          className="btn primary-btn px-5"
                        >
                          {!loading && (
                            <span className="indicator-label">Verify</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Otpdevice };
