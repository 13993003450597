import { useNavigate, useParams } from "react-router-dom";
// import { updateDish } from "../core/_requests";
import ListAddon from "./ListAddon";

const Availability = () => {
  const navigate = useNavigate();
  const { id, status } = useParams();
  const clickCancel = () => {
    navigate("/addons");
  };
  const yesDelete = () => {
    console.log(id, status);
    if (status === "true") {
      var enableDisable = false;
    } else {
      var enableDisable = true;
    }
    const data = { enableDisableStatus: enableDisable };
    // updateDish(id, data).then((res) => {
    //   navigate("/addons/");
    // });
  };
  return (
    <section className="container-fluid addContainerFluid">
      <ListAddon />
      <div className="overlay"></div>
      <div className="drawer small">
        <div className="alert-wrap">
          <img
            className="ico mb-4"
            src="../../../dish_images/alert-warning-ico.svg"
            alt="success"
          />
          <h1 className="mb-3">Are you sure?</h1>
          <p className="mid-gray mb-4 text-center">
            <small>
              Do you want {status == "true" ? "disable" : "enable"} this dish.
            </small>
          </p>
          <button
            className="btn btn-danger rounded-5 px-5 mt-2 mb-2"
            onClick={yesDelete}
          >
            Yes, Disable
          </button>
          <button className="btn btn-cancel rounded-5" onClick={clickCancel}>
            No, I don’t want to delete
          </button>
        </div>
      </div>{" "}
    </section>
  );
};
export default Availability;
