import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import "../../../assets/custom-style.css";
import { useAuth } from "../../../auth";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  getCategory,
  getCuisine,
  listDishes,
  getType,
  updateDish,
  createCategory,
  checkDuplicateCategory,
} from "../core/_requests";

import { useLocation, useNavigate } from "react-router-dom";
import { UUID } from "crypto";
import Header from "../../sidebar/Header";

interface Dish {
  primaryId: any;
  dishImage: string;
  name: string;
  cuisine: any;
  category: any;
  type: any;
  tags: string;
  offerPrice: any;
  enableDisableStatus: boolean;
  addons: Addon[];
  dishvariants: DishVariant[];
}
interface Addon {
  id: number;
  name: string;
  quantity: number;
  price: number;
}

interface DishVariant {
  id: number;
  unit: number;
  measurementId: number;
  price: number;
  discount: number;
  finalPrice: number;
}
interface Cuisine {
  uuid: UUID;
  name: string;
}
interface Type {
  uuid: UUID;
  name: string;
}
interface Category {
  uuid: UUID;
  name: string;
}

interface CategoryAdd {
  chefId: string | undefined;
  name: string;
  description: string;
  Image: any;
  formImage: any;
  createdBy: any;
  updatedBy: any;
}

const initialValues: CategoryAdd = {
  chefId: "",
  name: "",
  description: "",
  Image: "",
  formImage: "",
  createdBy: "",
  updatedBy: "",
};

const ListDishes = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CategoryAdd>(initialValues);
  const [listDish, setListDish] = useState<Dish[]>([]);
  const [cuisine, setCuisine] = useState<Cuisine[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
  const [type, setType] = useState<Type[]>([]);
  const [cuisineId, setCuisineId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [search, setSearch] = useState("");
  const [selectedTypes, setSelectedTypes] = useState<Type[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [formImage, setFormImage] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);
  const [Duplicate, setDuplicate] = useState("");
  const [duplicateCheck, setDuplicateCheck] = useState(false);
  const chefId =currentUser?.uuid
  const [showAlert, setShowAlert] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dataSearch = searchParams.get("search");

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showAllTags, setShowAllTags] = useState<{ [key: number]: boolean }>(
    {}
  );

  const toggleShowAllTags = (index: number) => {
    setShowAllTags((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const filterData = {
    search: dataSearch,
    cuisineId: cuisineId,
    categoryId: categoryId,
    typeId: selectedTypes,
  };
  useEffect(() => {
    if (dataSearch !== undefined) {
      console.log("filterData", filterData);
      setSearch(search);
      listDishes(chefId, filterData)
        .then((res) => {
          console.log("res?.data-", res?.data);
          setListDish(res?.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching dishes:", error);
          setLoading(false);
        });
    }

    getCuisine()
      .then((res) => {
        setCuisine(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching cuisine:", error);
      });

    getType()
      .then((res) => {
        setType(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching type:", error);
      });

    return () => {
      // Cleanup function to reset dish when component unmounts
      setSearch(""); // Reset dish to empty string
    };
  }, [search]);

  // Second useEffect to fetch category data
  useEffect(() => {
    
    getCategory(!chefId)
      .then((res) => {
        console.log("getCategory-res-List dish", res?.data?.data);
        setCategory(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [chefId]);

  const AddDish = () => {
    navigate("/dish/add");
  };

  const handleCheckboxChange = (value: any) => {
    setSelectedTypes((prevSelectedTypes) =>
      prevSelectedTypes.includes(value)
        ? prevSelectedTypes.filter((type) => type !== value)
        : [...prevSelectedTypes, value]
    );
  };

  const Schema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]*$/, "Only letters are allowed in the Category Name")
      .max(50, "Maximum 50 letters")
      .required("Category Name is required"),
    description: Yup.string(),
  });

  const formik = useFormik<CategoryAdd>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, actions) => {
      if (duplicateCheck) {
        return;
      }

      values.chefId = chefId;
      values.createdBy = currentUser?.chefId;

      actions.resetForm({
        values: initialValues,
      });
      console.log("currentUser==========", currentUser);
      const updatedData = Object.assign(data, values);
      console.log("updatedData==========", updatedData);
      //
      const formData = new FormData();
      if (formImage) formData.append("icon", formImage);
      Object.entries(updatedData).forEach(([key, value]) =>
        formData.append(key, value)
      );

      setLoading(true);
      createCategory(formData).then((res) => {
        if (res?.status === true) {
          closeCategory();
          setLoading(false);
          toast.success(res?.message, { autoClose: 5000 });
          navigate("/dish/categorysuccess", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    },
  });

  useEffect(() => {
    if (formik.values.name.trim() !== "") {
      checkDuplicateCategory(formik.values.name).then((response) => {
        if (response?.isDuplicate === true) {
          setDuplicate("Category name already exists");
          setDuplicateCheck(true);
        } else {
          setDuplicate("");
          setDuplicateCheck(false);
        }
      });
    }
  }, [formik.values.name]);

  const imageHandler = (e: { target: { files: any } }) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setData({
          ...data,
          Image: reader.result,
        });
      }
    };

    const files = e.target.files;
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (files && !allowedTypes.includes(files[0].type)) {
      setErrorMessage(
        "Invalid file type. Allowed types are JPEG, PNG and JPG."
      );
      setFormImage(null);
    } else if (files && files[0].size > 1024 * 1024) {
      // Check if file size exceeds 1MB
      setErrorMessage("File size should be less than 1MB.");
      setFormImage(null);
    } else {
      setErrorMessage(""); // Clear the error message
      if (files) {
        setFormImage(files[0]);
        reader.readAsDataURL(files[0]);
      }
    }
  };

  const handleErrorMessageClose = () => {
    setErrorMessage("");
    setData({
      ...data,
      Image: null,
    });
    // Reset the file input value
    if (fileInputRef.current) {
      (fileInputRef.current as any).value = "";
    }
  };

  const clickApply = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    //pagination
    setCurrentPage(1);
    const data = {
      search: search,
      typeId: selectedTypes,
      categoryId: categoryId,
      cuisineId: cuisineId,
    };

    try {
      const res = await listDishes(chefId, data);
      setListDish(res?.data); // Update the listDish state with the new data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dishes:", error);
      setLoading(false);
    }
  };

  const clickCancel = () => {
    navigate("/dish");
  };

  const viewDish = (primaryId: string) => {
    navigate("/dish/view/" + primaryId);
  };

  const editDish = (primaryId: string) => {
    navigate("/dish/edit/" + primaryId);
  };
  const deleteDish = (primaryId: string) => {
    navigate("/dish/delete/" + primaryId);
  };

  const handleEnableDisable = (id: any, status: any) => {
    if (status === true) {
      var enableDisable = false;
    } else {
      var enableDisable = true;
    }
    const data = { enableDisableStatus: enableDisable };
    console.log("updateDish-res", data);

    updateDish(id, data).then((res) => {
      navigate("/dish");
      toast.success(res?.message, { autoClose: 5000 });
      setLoading(false);
    });
  };
  const AddCategory = () => {
    setShowModal(true);
  };
  const closeCategory = () => {
    setShowModal(false);
    setFormImage(null);
    setDuplicate("");
    setDuplicateCheck(false);
    formik.resetForm(); // Reset the form when the modal is closed
  };
  //pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listDish.slice(indexOfFirstItem, indexOfLastItem);
  const pages = Math.ceil(listDish.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers.map((number) => {
    const isActive = number === currentPage;

    let pageNumberContent;

    if (
      number === 1 ||
      number === pages ||
      (number >= currentPage - 1 && number <= currentPage + 1)
    ) {
      pageNumberContent = (
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <a
            className={`page-link cursorPointer ${
              number === currentPage ? "active" : ""
            }`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 2 || number === pages - 1) {
      // Add links for second and second-to-last pages
      pageNumberContent = (
        <li key={number} className="page-item">
          <a
            className="page-link cursorPointer"
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </a>
        </li>
      );
    } else if (number === 3 && currentPage > 4) {
      // Add ellipsis before the link to the second page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    } else if (number === pages - 2 && currentPage < pages - 3) {
      // Add ellipsis before the link to the second-to-last page
      pageNumberContent = (
        <li key={number} className="page-item">
          <span className="ellipsis">...</span>
        </li>
      );
    }

    return pageNumberContent;
  });

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <section className="container-fluid">
        <div className="row">
        
          <Header />
          <section className="content-section">
            <section className="row main-content">
              <div className="title mb-3">
                <h3 className="mb-0">Dishes ({listDish?.length})</h3>
              </div>
              <div className="col-12 title-btm">
                <div className="search">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="material-symbols-outlined">search</i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Dishes..."
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyUp={clickApply}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end title-btm-btn-sec">
                  <button
                    className="btn btn-primary btn-ico rounded-5 ms-auto me-2"
                    onClick={AddCategory}
                  >
                    <i className="material-symbols-outlined">add_circle</i>{" "}
                    Category
                  </button>

                  <button
                    className="btn btn-primary btn-ico rounded-5 ms-auto me-2"
                    onClick={AddDish}
                  >
                    <i className="material-symbols-outlined">add_circle</i>Add
                    Dish
                  </button>
                </div>
              </div>

              <div className="filter-sec mt-4">
                <form>
                  <div className="row gx-3">
                    <div className="col-xl-2 col-lg-6">
                      <label className="form-label">Cuisine</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setCuisineId(e.target.value)}
                      >
                        <option value="">All</option>
                        {cuisine.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-xl-2 col-lg-6">
                      <label className="form-label">Category</label>
                      <select
                        className="form-select form-control"
                        onChange={(e) => setCategoryId(e.target.value)}
                      >
                        <option value="">All</option>
                        {category.map((item, i) => {
                          return (
                            <option value={item?.uuid} key={i}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-xl-4 col-lg-6 mt-lg-2 mt-xl-0">
                      <label className="form-label">Type</label>
                      {type.map((item: any, i) => {
                        return (
                          <div
                            className="form-check form-check-inline me-2 mt-2"
                            key={i}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`inlineCheckbox${i + 1}`}
                              value={item?.uuid}
                              checked={selectedTypes.includes(item?.uuid)}
                              onChange={() => handleCheckboxChange(item?.uuid)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`inlineCheckbox${i + 1}`}
                            >
                              {item?.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-xl-4 col-lg-6 d-flex align-items-center justify-content-lg-end justify-content-between pt-4 filter-btn-sec">
                      <button
                        className="btn btn-cancel rounded-5 small me-2 mt-1"
                        onClick={clickCancel}
                      >
                        Clear
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary rounded-5 small mt-1 clickedbutton"
                        onClick={clickApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="table-wrap mt-4">
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Dish Name</th>
                      <th>Cuisine-Category</th>
                      <th>Type</th>
                      <th>Tags</th>
                      <th>Unit - Price ($)</th>
                      <th>Rating</th>
                      <th>Available</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((item, i) => {
                        const tags =
                          item?.tags?.split(",").map((tag) => tag.trim()) || []; // Split and trim tags
                        const displayTags = showAllTags[i]
                          ? tags
                          : tags.slice(0, 3); // Show all or first 3 tags
                        const excessCount =
                          tags.length > 3 ? tags.length - 3 : 0; // Calculate excess count

                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              <div className="dish">
                                <div className="thumbnail">
                                  <img src={item?.dishImage} alt="Dish Name" />
                                </div>
                                <p className="ms-2 mb-0">{item?.name}</p>
                              </div>
                            </td>
                            <td>
                              <p className="mb-0">
                                {item?.cuisine?.name} -{" "}
                                {item?.category?.name}
                              </p>
                            </td>
                            <td>
                              <p className="mb-0 type">
                                <img src={item?.type?.iconUrl} alt="Veg" />{" "}
                                {item?.type?.dishname}
                              </p>
                            </td>
                            <td>
                              {displayTags.map((tag, index) => (
                                <span
                                  key={index}
                                  className="badge tag me-1 mt-1"
                                >
                                  {tag}
                                </span>
                              ))}
                              {excessCount > 0 && !showAllTags[i] && (
                                <span
                                  className="count cursorPointer"
                                  onClick={() => toggleShowAllTags(i)}
                                >
                                  +{excessCount}
                                </span>
                              )}
                            </td>
                            <td>
                              {item.dishvariants?.length > 0 && (
                                <div>
                                  <p>
                                    {item.dishvariants[0]?.unit} - $
                                    {item.dishvariants[0]?.finalPrice}
                                  </p>
                                </div>
                              )}
                            </td>
                            <td>
                              <span className="rating">
                                <img
                                  src="../dish_images/star.svg"
                                  alt="Rating"
                                />
                                0
                              </span>
                            </td>
                            <td>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input cursorPointer"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={item?.enableDisableStatus}
                                  onClick={() =>
                                    handleEnableDisable(
                                      item?.primaryId,
                                      item?.enableDisableStatus
                                    )
                                  }
                                />
                                <label className="yes">Yes</label>
                                <label className="no">No</label>
                              </div>
                            </td>
                            <td>
                              <div className="action-item">
                                <a onClick={() => editDish(item?.primaryId)}>
                                  <span className="material-symbols-outlined">
                                    edit
                                  </span>
                                </a>
                                <a
                                  className="danger cursorPointer"
                                  onClick={() => deleteDish(item?.primaryId)}
                                >
                                  <span className="material-symbols-outlined">
                                    delete
                                  </span>
                                </a>
                                <a onClick={() => viewDish(item?.primaryId)}>
                                  <span className="material-symbols-outlined cursorPointer">
                                    info
                                  </span>
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {currentItems.length > 0 && (
                <nav className="pagination-wrap mb-4">
                  <p className="mb-0">
                    Showing {indexOfFirstItem + 1} to{" "}
                    {Math.min(indexOfLastItem, listDish.length)} of{" "}
                    {listDish.length} items
                  </p>
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(1)}
                      >
                        <i className="material-symbols-outlined">first_page</i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={prevPage}
                        aria-label="Previous"
                      >
                        <i className="material-symbols-outlined">
                          navigate_before
                        </i>
                      </a>
                    </li>
                    {renderPageNumbers}
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={nextPage}
                        aria-label="Next"
                      >
                        <i className="material-symbols-outlined">
                          navigate_next
                        </i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link cursorPointer"
                        onClick={() => setCurrentPage(pages)}
                      >
                        <i className="material-symbols-outlined">last_page</i>
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </section>

            {/* Modal for Add Category */}
            <Modal show={showModal} onHide={closeCategory}>
              <Modal.Header closeButton>
                <Modal.Title>Add Category</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Add your category form or content here */}
                <form onSubmit={formik.handleSubmit}>
                  <div className="col-12 mb-3 mt-3">
                    <label htmlFor="" className="form-label">
                      Category Image (optional)
                    </label>
                    <div className="file-input mt-1">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        onChange={imageHandler}
                        ref={fileInputRef}
                      />
                      <label htmlFor="file">
                        <i className="material-symbols-outlined">upload</i>
                        <span> Upload Category Image </span>
                      </label>
                    </div>
                    <span className="file-info">
                      Supported file type (.jpg, .png, .jpeg) Max file size:1MB
                    </span>
                    {data?.Image && (
                      <>
                        {" "}
                        <img src={data?.Image} height="100" width="100" />
                        <button
                          className="btn p-0 close d-inline-flex "
                          onClick={handleErrorMessageClose}
                        >
                          <i className="material-symbols-outlined fs-18">
                            close
                          </i>
                        </button>
                      </>
                    )}
                    {errorMessage && (
                      <div
                        style={{ color: "red" }}
                        className="custom-error-messages"
                      >
                        {errorMessage}

                        <button
                          className="btn p-0 close d-inline-flex "
                          onClick={handleErrorMessageClose}
                        >
                          <i className="material-symbols-outlined fs-18">
                            close
                          </i>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="col-12 mb-3">
                    <label htmlFor="" className="form-label require ">
                      Category
                    </label>
                    <input
                      type="text"
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.name && formik.errors.name,
                        },
                        {
                          "is-valid":
                            formik.touched.name && !formik.errors.name,
                        }
                      )}
                      placeholder="Enter Your Category"
                      {...formik.getFieldProps(`name`)}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.name}</span>
                      </div>
                    )}
                    <span style={{ color: "red" }}>
                      {Duplicate ? Duplicate : ""}
                    </span>
                  </div>
                  <div className="col-12 mb-3">
                    <label htmlFor="" className="form-label">
                      Description (optional)
                    </label>
                    <input
                      type="textarea"
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.description &&
                            formik.errors.description,
                        },
                        {
                          "is-valid":
                            formik.touched.description &&
                            !formik.errors.description,
                        }
                      )}
                      placeholder="Enter Your Description"
                      {...formik.getFieldProps(`description`)}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">{formik.errors.description}</span>
                        </div>
                      )}
                  </div>
                  <Modal.Footer className="pe-0 pb-0 mt-4">
                    <Button
                      className="btn btn-cancel rounded-5 small me-2 mt-1"
                      onClick={closeCategory}
                    >
                      Close
                    </Button>
                    <button
                      disabled={
                        formik.isSubmitting || !formik.isValid || duplicateCheck
                      }
                      className="btn btn-outline-primary rounded-5 me-2"
                    >
                      {!loading && (
                        <span className="indicator-label">Submit</span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </Modal.Footer>
                </form>
              </Modal.Body>
            </Modal>
            {/* Modal for Add Category */}
          </section>
        </div>
      </section>
    </>
  );
};

export default ListDishes;
