export type Addons = {
  primaryId: string;
  chefId: any;
  name?: string;
  typeId?: string;
  amount?: number;
  enableDisableStatus: any;
  createdBy: any;
  updatedBy: any;
  message?: string;
  status?: string;
};
export const initialValues: Addons = {
  primaryId:"",
  name: "",
  chefId: "",
  typeId: "",
  amount:0,
  enableDisableStatus: "",
  createdBy: 0,
  updatedBy: 0,
};
