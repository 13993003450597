import { useNavigate } from "react-router-dom";

import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

import { FC, useEffect, useState, SetStateAction, useMemo, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Modal } from "react-bootstrap";
import { ChefProfile, useAuth } from "../../../auth";
import { ProfileDetails, UpdateProfile } from "../core/_requests";
import { useFormik } from "formik";
import * as Yup from "yup";

import Header from "../../sidebar/Header";
const mapContainerStyle = {
  width: "470px",
  height: "280px",
};
const libraries = ["places"];

type Props = {
  initialValues: any;
};

const BrandUpdate: FC<Props> = ({ initialValues }) => {
  const [data, setData] = useState<ChefProfile>(initialValues);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const chefId =currentUser?.uuid
  const inputs = useRef<Array<HTMLInputElement | HTMLSelectElement | null>>([]);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (index < inputs.current.length - 1) {
        // If not the last input field, focus on the next input
        const nextIndex = index + 1;
        inputs.current[nextIndex]?.focus();
      } else {
        // If the last input field, submit the form
        formik.handleSubmit();
      }
    }
  };
  const clickCancel = () => {
    navigate("/settings");
  };
  useEffect(() => {
    ProfileDetails(chefId)
      .then((res) => {
        setData(res?.data);   
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const Schema = Yup.object().shape({
    brandName:Yup.string()
   
    .max(50, "minimum 10 letters")
    .max(500, "Maximum 500 letters")
      .required("Enter Your Brand"),
  });

  const formik = useFormik<ChefProfile>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, actions) => {
      setLoading(true);
      console.log("values", values);
      const updatedData = Object.assign(data, values);
      console.log("updatedData", updatedData);

      UpdateProfile(updatedData, chefId).then((res) => {
    
        if (res?.status === true) {
          navigate("/settings/bio-success", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    },
  });

  return (
    <>
      <section className="container-fluid">
        <div className="row">
             {/* <Sidebar />   */}
             <Header />
          <section className="content-section">
            {/* <Header /> */}
            <div className="overlay"></div>

            <div className="drawer small">
              <div className="title row">
                <h3 className="col-10 mb-0">Brand Name</h3>
                <div className="col-2 text-end">
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={clickCancel}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </button>
                </div>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="row mt-4 add-bank-form"
              >
                <div className="col-12 mb-3">
                  <label htmlFor="" className="form-label">
                   Brand Name
                  </label>
                  <input
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.brandName && formik.errors.brandName,
                      },
                      {
                        "is-valid": formik.touched.brandName && !formik.errors.brandName,
                      }
                    )}
                    placeholder="Give your brand name"
                    {...formik.getFieldProps(`brandName`)}
                    ref={(el) => (inputs.current[3] = el)}
                    onKeyDown={e => handleKeyPress(e, 3)}
                  />
                </div>
                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-cancel rounded-5"
                        onClick={clickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={formik.isSubmitting || !formik.isValid}
                        className="btn btn-outline-primary rounded-5 me-2"
                      >
                        {!loading && (
                          <span className="indicator-label">Publish</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};
export { BrandUpdate };
