import { useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAuth } from "../../auth";
import Success from "./files/Success";
import ViewOrders from "./files/ViewOrders";
import OfflineOrders from "./files/offline";
import OrderHistory from "./files/History";
const OrdersPage = () => {
  const { currentUser } = useAuth();
  const [data, setData] = useState<any>(null);

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <OfflineOrders />
            </>
          }
        />

        <Route
          path="/view"
          element={
            <>
              <ViewOrders />
            </>
          }
        />

        <Route
          path="/success"
          element={
            <>
              <Success />
            </>
          }
        />
          <Route
          path="/history"
       
          element={
            <>
              <OrderHistory/>
            </>
          }
        />
        <Route index element={<Navigate to="list" />} />
      </Route>
    </Routes>
  );
};

export default OrdersPage;
