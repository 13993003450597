import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import "../../../assets/custom-style.css";
import { useAuth } from "../../../auth";

import { CreateBank, getBankDetailsByRoutingNumber } from "../core/_requests";

import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";
import ListSettlements from "./ListSettlements";

interface BankAdd {
  chefId: string | undefined;
  bankId: string | undefined;
  routingNumber: string;
  accountName: string;
  accountNumber: string;
  phoneNumber: string;
  address: string;
  createdBy: any;
  updatedBy: any;
}

const initialValues: BankAdd = {
  chefId: "",
  bankId: "",
  routingNumber: "",
  accountName: "",
  accountNumber: "",
  address: "",
  phoneNumber: "",

  createdBy: "",
  updatedBy: "",
};

const AddSettlements = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [data, setData] = useState<BankAdd>(initialValues);
  const [loading, setLoading] = useState(false);
  const [Duplicate, setDuplicate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentDate] = useState(new Date());
  const [routingNumber, setRoutingNumber] = useState("");
  const [routingNumberError, setRoutingNumberError] = useState("");
  const chefId =currentUser?.uuid
  const handleClear = () => {
    setRoutingNumber(""); // Clear the input field
  };
  const handleChange = (event:any) => {
    setRoutingNumber(event.target.value);
  };
  const clickCancel = () => {
    navigate("/Settlements");
  };

  const Schema = Yup.object().shape({
    routingNumber: Yup.string()
      .matches(
        /^\d{1,10}$/,
        "Routing number must be numeric and between 1 to 10 digits"
      )
      .required("Routing number is required"),
    accountName: Yup.string()
      .max(50, "Maximum 50 characters")
      .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed")
      .required("Account name is required"),
    accountNumber: Yup.string()
      .matches(
        /^\d{1,10}$/,
        "Account number must be numeric and between 1 to 10 digits"
      )
      .required("Account number is required"),
    bankId: Yup.string()
      .max(50, "Maximum 50 characters")
      .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed")
      .required("Bank name is required"),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
  });

  const formik = useFormik<BankAdd>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      values.chefId = chefId;
      values.createdBy = currentUser?.chefId;
      values.updatedBy = currentUser?.chefId;
      const updatedData = Object.assign(data, values);
      // //Image and data Append to Form Data
      setLoading(true);
      CreateBank(updatedData).then((res) => {
        if (res?.status === true) {
          navigate("/settlements/success", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
          setErrorMessage(res?.message);
        }
      });
      resetForm();
    },
  });
  
  
  useEffect(() => {
    formik.setFieldValue("routingNumber", routingNumber);
  }, [routingNumber]);
  // Function to handle input change for routing number
  const handleRoutingNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setRoutingNumber(value);
    formik.handleChange(event);
  };
  const fetchBankDetails = async () => {
    setLoading(true);
    try {
      const res = await getBankDetailsByRoutingNumber(
        formik.values.routingNumber
      );
      if (res?.status === "success") {
        formik.setValues({
          ...formik.values,
          bankId: res.data.name,
          phoneNumber: res.data.phone,
          address: res.data.addressFull,
        });
        setRoutingNumberError(""); // Clear routing number error if fetched successfully
      } else {
        setRoutingNumberError("Invalid routing number");
      }
    } catch (error) {
      console.error("Error fetching bank details:", error);
      setRoutingNumberError("Error fetching bank details");
    }
    setLoading(false);
  };

  const nextDate = new Date();
  nextDate.setDate(currentDate.getDate());

  console.log("Settlements  formik", formik.values);

  return (
    <>
      <section className="container-fluid addContainerFluid">
        <ListSettlements />
        <div className="overlay"></div>
        <div className="drawer small">
          <div className="title row">
            <h3 className="col-10 mb-0">Add Bank Details</h3>
            <div className="col-2 text-end mb-2">
              <button
                className="btn p-0 close d-inline-flex"
                onClick={clickCancel}
              >
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Routing Number
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className={clsx(
                    "form-control",
                    {
                      "is-invalid":
                        formik.touched.routingNumber &&
                        formik.errors.routingNumber,
                    },
                    {
                      "is-valid":
                        formik.touched.routingNumber &&
                        !formik.errors.routingNumber,
                    }
                  )}
                  placeholder="Routing Number"
                  value={routingNumber}
                  // {...formik.getFieldProps("routingNumber")}
                  onChange={handleChange}
                />
                <span className="input-group-text px-1 input-btn" onClick={handleClear}>
                  <i className="material-symbols-outlined">close</i>
                </span>
                <span
                  className="input-group-text input-btn"
                  onClick={fetchBankDetails}
                >
                  <i className="material-symbols-outlined">search</i>
                </span>
              </div>
              {/* Display routing number error */}
              {formik.touched.routingNumber && formik.errors.routingNumber && (
                <div className="fv-help-block text-danger">
                  {formik.errors.routingNumber}
                </div>
              )}
              {/* Display routing number fetch error */}
              {routingNumberError && (
                <div className="fv-help-block text-danger">
                  {routingNumberError}
                </div>
              )}
            </div>

            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                A/c Name
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.accountName && formik.errors.accountName,
                  },
                  {
                    "is-valid":
                      formik.touched.accountName && !formik.errors.accountName,
                  }
                )}
                placeholder="Account Name"
                {...formik.getFieldProps(`accountName`)}
              />
              {formik.touched.accountName && formik.errors.accountName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.accountName}
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                A/c Number
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber,
                  },
                  {
                    "is-valid":
                      formik.touched.accountNumber &&
                      !formik.errors.accountNumber,
                  }
                )}
                placeholder="Account Number"
                {...formik.getFieldProps(`accountNumber`)}
              />
              {formik.touched.accountNumber && formik.errors.accountNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.accountNumber}
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Bank Name
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.bankId && formik.errors.bankId,
                  },
                  {
                    "is-valid": formik.touched.bankId && !formik.errors.bankId,
                  }
                )}
                placeholder="Bank Name"
                {...formik.getFieldProps(`bankId`)}
              />
              {formik.touched.bankId && formik.errors.bankId && (
                <div className="fv-help-block text-danger">
                  {formik.errors.bankId}
                </div>
              )}
            </div>
            <div className="col-12 mb-3 mt-3">
              <label htmlFor="" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.phoneNumber && formik.errors.phoneNumber,
                  },
                  {
                    "is-valid":
                      formik.touched.phoneNumber && !formik.errors.phoneNumber,
                  }
                )}
                placeholder="Phone Number "
                {...formik.getFieldProps(`phoneNumber`)}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.phoneNumber}
                  </div>
                </div>
              )}
            </div>

            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label">
                Address
              </label>
              <textarea
                className={clsx(
                  "form-control textarea",
                  {
                    "is-invalid":
                      formik.touched.address && formik.errors.address,
                  },
                  {
                    "is-valid":
                      formik.touched.address && !formik.errors.address,
                  }
                )}
                id=""
                cols={30}
                rows={10}
                placeholder=" Address"
                {...formik.getFieldProps(`address`)}
              ></textarea>
            </div>

            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-4"></div>
                <div className="col-8 text-end">
                  <button
                    className="btn btn-cancel rounded-5"
                    onClick={clickCancel}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={formik.isSubmitting || !formik.isValid}
                    className="btn btn-outline-primary rounded-5 me-2"
                  >
                    {!loading && (
                      <span className="indicator-label">Create</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddSettlements;
