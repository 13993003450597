import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FC, useEffect, useState, SetStateAction, useMemo, useRef } from "react";
import {
  ProfileDetails,
  UpdateProfile,
  addressUserFetch,
  callGooglePlacesApi,
  locationLatLongAPIFetch,
} from "../core/_requests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Modal } from "react-bootstrap";
import { ChefProfile, useAuth } from "../../../auth";

import Header from "../../sidebar/Header";

const mapContainerStyle = {
  width: "470px",
  height: "280px",
};
const libraries = ["places"];

type Props = {
  initialValues: any;
};

const ProfileUpdate: FC<Props> = ({ initialValues }) => {
  const [data, setData] = useState<ChefProfile>(initialValues);
  const [showMarker, setShowMarker] = useState(false);
  const LatLng = {
    lat: Number(data.latitude),
    lng: Number(data.longitude),
  };
  const center = useMemo(
    () => ({ lat: Number(data.latitude), lng: Number(data.longitude) }),
    []
  );

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFileMessage, setShowFileMessage] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [formImage, setFormImage] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [mapCenter, setMapCenter] = useState(center);
  const [userLocation, setUserLocation] = useState(LatLng);
  const [addressData, setAddressData] = useState<any>({});
  const [suggestions, setSuggestions] = useState<any>([]);
  const [googledata, setgoogledata] = useState<any>({});
  const [dropdownCLicked, setdropdownCLicked] = useState(false);
  const inputs = useRef<Array<HTMLInputElement | HTMLSelectElement | null>>([]);
  const chefId =currentUser?.uuid
  const clickCancel = () => {
    navigate("/settings");
  };

  useEffect(() => {
    ProfileDetails(chefId)
      .then((res) => {
        setData(res?.data);
        setImagePreview(res?.data?.profile);
        setUserLocation({
          lat: res?.data?.latitude,
          lng: res?.data?.longitude,
        });
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const Schema = Yup.object().shape({
    name: Yup.string()
      .max(50, "Maximum 50 letters")
      .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed")
      .required("Enter Your Name"),

    email: Yup.string().email("Invalid Email").required("Enter your Email"),

    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Enter Your 10 Digit Mobile Number")
      .required("Enter Your MobileNumber"),
    address: Yup.string().required("Enter Your Address"),
  });

  const formik = useFormik<ChefProfile>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values:any, actions:any) => {
      setLoading(true);
      console.log("values", values);
      const updatedData = Object.assign(data, values);
      console.log("updatedData", updatedData);
      
      UpdateProfile(updatedData, chefId).then((res) => {
   
        if (res?.status === true) {
          navigate("/settings/profile-success", { replace: true });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    },
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD2lTAmm-smGH6KUW24nRU864_bsFZRUpI",
    // libraries,
  });

  const openMap = () => {
    setShowModal(true);
  };

  const handleMapClick = (event: any) => {
    setMapCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setShowMarker(true);
  };

  const closeMap = () => {
    setShowModal(false);
  };

  const handleMarkerDragEnd = (event: any) => {
    setMapCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const fetchAddress = async () => {
    const data = mapCenter;
    await addressUserFetch(data).then((res: any) => {
      formik.setFieldValue("address", res?.address);
      formik.setFieldValue("address1", res?.address1);
      formik.setFieldValue("city", res?.city);
      formik.setFieldValue("state", res?.state);
      formik.setFieldValue("country", res?.country);

      formik.setFieldValue("zipCode", res?.pincode);
      setAddressData(res);
    });
    setShowModal(false);
  };

  const handleGoogleApi = (Inputvalue: any) => {
    formik.setFieldValue("address", Inputvalue);
    if (Inputvalue.length >= 5) {
      callGooglePlacesApi(Inputvalue).then((res: SetStateAction<never[]>) => {
        setSuggestions(res);
      });
    }
  };

  const handleCitySelection = (placeId: any, description: any) => {
    setdropdownCLicked(true);
    formik.setFieldValue("address", description);
    locationLatLongAPIFetch(placeId).then((res: any) => {
      setgoogledata(res);
      console.log("eer", res);
      formik.setFieldValue("address1", res?.address1);
      formik.setFieldValue("city", res?.cityName);
      formik.setFieldValue("state", res?.stateName);
      formik.setFieldValue("country", res?.countryName);
      formik.setFieldValue("latitude", res?.latitude);
      formik.setFieldValue("longitude", res?.longitude);
      formik.setFieldValue("zipCode", res?.pincode);
    });
    setSuggestions([]); // Clear suggestions after selection
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (index < inputs.current.length - 1) {
        // If not the last input field, focus on the next input
        const nextIndex = index + 1;
        inputs.current[nextIndex]?.focus();
      } else {
        // If the last input field, submit the form
        event.currentTarget.form?.dispatchEvent(new Event('submit', { cancelable: true }));
      }
    }
  };
  return (
    <>
      <section className="container-fluid">
        <div className="row">
         {/* <Sidebar />   */}
         <Header />
          <section className="content-section">
            {/* <Header /> */}
            <div className="overlay"></div>
            <div className="drawer small">
            <div className="title row">
                <h3 className="col-10 mb-0">Edit Profile Details</h3>
                <div className="col-2 text-end">
                  <button
                    className="btn p-0 close d-inline-flex"
                    onClick={clickCancel}
                  >
                    <i className="material-symbols-outlined">close</i>
                  </button>
                </div>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="col-12 mb-3 mt-3">
                  <label htmlFor="" className="form-label">
                    Your Name
                  </label>
                  <input
                    type="text"
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid": formik.touched.name && formik.errors.name,
                      },
                      {
                        "is-valid": formik.touched.name && !formik.errors.name,
                      }
                    )}
                    placeholder="Give your name"
                    {...formik.getFieldProps(`name`)}
                    ref={el => inputs.current[1] = el}
                    onKeyDown={e => handleKeyPress(e, 1)}
                    onKeyPress={(e) =>
                      !/^[a-zA-Z0-9\s]*$/.test(e.key) && e.preventDefault()
                    }
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.name}</span>
                    </div>
                  )}
                </div>

                <div className="col-12 mb-3 mt-3">
                  <label htmlFor="" className="form-label">
                    Your Email
                  </label>
                  <input
                    type="email"
                    readOnly
                    className={clsx(
                      "form-control",
                      {
                        "is-invalid":
                          formik.touched.email && formik.errors.email,
                      },
                      {
                        "is-valid":
                          formik.touched.email && !formik.errors.email,
                      }
                    )}
                    ref={el => inputs.current[1] = el}
                    onKeyDown={e => handleKeyPress(e, 1)}
                    placeholder="Give your email"
                    {...formik.getFieldProps(`email`)}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  )}
                </div>

                <div className="col-12 mb-3 mt-3">
                  <label htmlFor="" className="form-label">
                    Your Mobile Number
                  </label>
                  <div className="input-group">
                    <div className="input-group-text">
                      <img src="../assets/images/united-states.png" alt="flag" />{" "}
                      +1
                    </div>
                    <input
                      type="text"
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.mobileNumber &&
                            formik.errors.mobileNumber,
                        },
                        {
                          "is-valid":
                            formik.touched.mobileNumber &&
                            !formik.errors.mobileNumber,
                        }
                      )}
                      
                      ref={el => inputs.current[1] = el}
                      onKeyDown={e => handleKeyPress(e, 1)}
                      placeholder="Give your mobile number"
                      {...formik.getFieldProps(`mobileNumber`)}
                      max={13}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                  {formik.touched.mobileNumber &&
                    formik.errors.mobileNumber && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.mobileNumber}</span>
                      </div>
                    )}
                </div>

                <div className="col-12 mb-3 mt-3">
                  <label htmlFor="" className="form-label">
                    Your Phone Number (optional)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Give your phone number"
                    {...formik.getFieldProps(`phoneNumber`)}
                    max={13}

                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    ref={el => inputs.current[1] = el}
                    onKeyDown={e => handleKeyPress(e, 1)}
                  />
                </div>
                <div className="col-12">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="small-font mb-2"
                  >
                    Your Address (Select your address)
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={clsx(
                        "form-control",
                        {
                          "is-invalid":
                            formik.touched.address && formik.errors.address,
                        },
                        {
                          "is-valid":
                            formik.touched.address && !formik.errors.address,
                        }
                      )}
                      ref={el => inputs.current[1] = el}
                      onKeyDown={e => handleKeyPress(e, 1)}
                      placeholder="Door no., Street, Area..."
                      {...formik.getFieldProps("address")}
                      onChange={(e) => handleGoogleApi(e.target.value)}
                    
                    />
                    <div className="input-group-append">
                      <img
                        onClick={openMap}
                        src="../assets/images/map.png"
                        className="map-icon mt-2 me-3"
                        alt="Map Icon"
                        style={{ width: "20px", height: "25px" }}
                      />
                    </div>
                  </div>
                  {suggestions.length > 0 ? (
                    <ul className="nav flex-column googleStyleUL">
                      {suggestions.map((suggestion: any) => (
                        <li
                          className="nav-item fs-12 googleStyleLI"
                          key={suggestion.place_id}
                          onClick={() =>
                            handleCitySelection(
                              suggestion.place_id,
                              suggestion.description
                            )
                          }
                        >
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  {formik.touched.address1 && formik.errors.address1 && (
                    <div className="fv-plugins-message-container">
                      {/* <span role="alert">{formik.errors.address1}</span> */}
                    </div>
                  )}
                </div>

                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8 text-end">
                      <button
                        className="btn btn-cancel rounded-5"
                        onClick={clickCancel}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={formik.isSubmitting || !formik.isValid}
                        className="btn btn-outline-primary rounded-5 me-2"
                      >
                        {!loading && (
                          <span className="indicator-label">Publish</span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
          <Modal show={showModal} onHide={closeMap}>
            <Modal.Header closeButton>
              <Modal.Title>Select Your Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={4}
                  center={mapCenter}
                  onClick={handleMapClick}
                >
                  <img src={"../../assets/images/locationicon.svg"} />
                  {userLocation && (
                    <Marker
                      position={userLocation}
                      icon={{
                        url: "../../assets/images/locationicon.svg",
                      }}
                      draggable={true}
                      onDragEnd={handleMarkerDragEnd}
                    />
                  )}
                </GoogleMap>
                <div className="d-flex gap-3 mt-2 justify-content-end">
                  <button
                    type="submit"
                    className="btn primary-btn px-3 py-1"
                    onClick={fetchAddress}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </>
  );
};
export { ProfileUpdate };