import React from "react";
import axios from "axios";
import { getToken } from "../../../auth";

//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;

const listDishes = async (ChefId: any, filterData: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/listDish/${ChefId}`,
    filterData
  );
  return response.data;
};

// const getCategory = async () => {
//   const res = await axios.get(
//     `${API_URL}chefPortal/listCategory`,
//   );
//   return res;
// };
const getCategory = async (chefId: any) => {
  const res = await axios.post(`${API_URL}chefPortal/listCategoryApprove`, {
    chefId,
  });
  return res;
};
const getCuisine = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getCuisine`);
  return res;
};

const getType = async () => {
  const res = await axios.get(`${API_URL}chefPortal/getType`, getToken());
  return res;
};
const getMeasurement = async () => {
  const res = await axios.get(
    `${API_URL}chefPortal/getMeasurement`,
    getToken()
  );
  return res;
};
const DishCreate = async (data: FormData) => {
  const response = await axios.post(`${API_URL}chefPortal/addDish`, data);
  return response.data;
};

const DishUpdate = async (id: any, data: FormData) => {
  console.log("id-update apii", id);
  const response = await axios.put(
    `${API_URL}chefPortal/updateDish/${id}`,
    data
  );
  console.log("response.data-dish update-apii", response.data);

  return response.data;
};

const updateDish = async (id: any, enableDisableStatus: any) => {
  const response = await axios.put(
    `${API_URL}chefPortal/enableDisable/${id}`,
    enableDisableStatus
  );
  return response.data;
};

const deleteDish = async (id: any) => {
  const response = await axios.put(`${API_URL}chefPortal/deleteDish/${id}`);
  return response.data;
};
const getDishView = async (id: any) => {
  const response = await axios.get(`${API_URL}chefPortal/viewDish/${id}`);
  return response.data;
};

const createCategory = async (data: FormData) => {
  const response = await axios.post(`${API_URL}chefPortal/addCategory`, data);
  return response.data;
};
const checkDuplicateDish = async (id: any, name: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/find-duplicateDish/${id}`,
    { name },
    getToken()
  );
  return response.data;
};
const checkDuplicateCategory = async (name: any) => {
  const response = await axios.post(
    `${API_URL}chefPortal/find-duplicateCategory`,
    { name },
    getToken()
  );
  return response.data;
};

const listIngredient = async (chefId: any) => {
  const res = await axios.post(`${API_URL}chefPortal/listIngredient`, {
    chefId,
  });
  return res;
};

const addingredients = async (data: any) => {
  console.log("data=====addingredients", data);
  const response = await axios.post(`${API_URL}chefPortal/addingredient`, data);
  return response.data;
};

const findDuplicateIngredient = async (name: any) => {
  console.log("name---------", name);
  const response = await axios.post(
    `${API_URL}chefPortal/find-duplicateIngredient`,
    { name },
    getToken()
  );
  return response.data;
};
const getAddonsType = async (chefId: any) => {
  const res = await axios.post(`${API_URL}chefPortal/get-AddonsType`, {
    chefId,
  });
  return res;
};

const getDishAddons = async (chefId: any) => {
  const res = await axios.post(`${API_URL}chefPortal/get-Addons`, {
    chefId,
  });
  return res;
};
export {
  listDishes,
  getCategory,
  getCuisine,
  getType,
  DishCreate,
  updateDish,
  deleteDish,
  getDishView,
  createCategory,
  checkDuplicateCategory,
  checkDuplicateDish,
  DishUpdate,
  listIngredient,
  addingredients,
  findDuplicateIngredient,
  getMeasurement,
  getDishAddons,
  getAddonsType,
};
