import { Modal, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../../../auth";
import clsx from "clsx";
import { useFormik } from "formik";
import { addingredients, findDuplicateIngredient } from "../core/_requests";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

interface ModalProps {
  show: boolean;
  onHide: () => void;
}

interface Ingredient {
  chefId: string | undefined;
  name: string;
  createdBy: any;
  updatedBy: any;
}

const initialValues: Ingredient = {
  chefId: "",
  name: "",
  createdBy: "",
  updatedBy: "",
};

const AddIngredient: React.FC<ModalProps> = ({ show, onHide }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState<Ingredient>(initialValues);
  const [duplicate, setDuplicate] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inputs = useRef<Array<HTMLInputElement | HTMLSelectElement | null>>([]);
  const formRef = useRef<HTMLFormElement>(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (index < inputs.current.length - 1) {
        // If not the last input field, focus on the next input
        const nextIndex = index + 1;
        inputs.current[nextIndex]?.focus();
      }
    }
  };

  const Schema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed in the Ingredient")
      .max(50, "Maximum 50 letters")
      .required("Ingredient Name is required"),
  });

  const formik = useFormik<Ingredient>({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      values.chefId = currentUser?.uuid;
      values.createdBy = currentUser?.chefId;
      values.updatedBy = currentUser?.chefId;
      const updatedData = Object.assign(data, values);
      console.log("updatedData", updatedData);
      setLoading(true);
      addingredients(updatedData)
        .then((res) => {
          console.log("res?.status", res?.status)
          if (res?.status === true) {
            toast.success(res?.message, { autoClose: 5000 });
            navigate("/", { replace: true });
            // navigate("/dish/add", { replace: true });
            setLoading(false);
            onHide();
          } else {
            toast.error(res?.message, { autoClose: 5000 });
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrorMessage("Something Went Wrong");
        });
      resetForm();
    },
  });

  useEffect(() => {
    findDuplicateIngredient(formik.values.name).then((response) => {
      if (response?.isDuplicate === true) {
        setDuplicate("Ingredient name already exists");
      } else {
        setDuplicate("");
      }
    });
  }, [formik.values.name]);

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Ingredient Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref={formRef} onSubmit={formik.handleSubmit}>
            <div className="col-12 mb-3">
              <label htmlFor="" className="form-label require">
                Ingredient
              </label>
              <input
                type="text"
                className={clsx(
                  "form-control", {
                    "is-invalid": formik.touched.name && formik.errors.name,
                  },
                  {
                    "is-valid": formik.touched.name && !formik.errors.name,
                  }
                )}
                placeholder="Enter Your Ingredient"
                ref={(el) => (inputs.current[0] = el)}
                onKeyDown={e => handleKeyPress(e, 0)}
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              )}
              <span style={{ color: "red" }}>
                {duplicate ? duplicate : ""}
              </span>
            </div>
            <Modal.Footer>
              <Button
                className="btn btn-cancel rounded-5 small me-2 mt-1"
                onClick={onHide}
              >
                Close
              </Button>
              <button
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid || !!duplicate}
                className="btn btn-outline-primary rounded-5 me-2"
              >
                {!loading && (
                  <span className="indicator-label">Publish</span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer /> {/* To render toast notifications */}
    </>
  );
};

export default AddIngredient;
