import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { mostOrerDish } from "../../core/_requests";
import { useAuth } from "../../core/Auth";
interface DishVariant {
  id: number;
  unit: string;
  measurementId: number;
  price: number;
  discount: number;
  finalPrice: number;
}

interface DishDetails {
  uuid: string;
  name: string;
  description: string;
  ingredients: string;
  typeId: string;
  cuisineId: string;
  categoryId: string;
  tags: string;
  dishImage: string;
  dishvariants: DishVariant[];
  dishreviews: any[];
  type: {
    id: number;
    name: string;
    typeIcon: string;
  };
}

interface Dish {
  dishDetails: DishDetails;
  orderCount: number;
}

const MostOrderDish = () => {
  const [data, setData] = useState<Dish[]>([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const chefId =currentUser?.uuid
  const viewDetails = () => {
    navigate("/orders/view");
  };

  console.log("Most Ordered Dishes---data", data);

  useEffect(() => {
    if (chefId) {
      mostOrerDish(chefId).then((res: any) => {
        setData(res);
      });
    }
  }, [currentUser]);

  const renderStars = (rating: any) => {
    if (rating !== null) {
      const stars = [];
      for (let i = 0; i < 5; i++) {
        if (i < Math.floor(rating)) {
          stars.push(
            <i key={i} className="bi bi-star-fill fs-8 text-warning"></i>
          );
        } else if (i === Math.floor(rating) && rating % 1 !== 0) {
          stars.push(
            <i key={i} className="bi bi-star-half fs-8 text-warning"></i>
          );
        } else {
          stars.push(<i key={i} className="bi bi-star fs-8"></i>);
        }
      }
      return stars;
    }
    return null;
  };

  return (
    <div className="snippet-block btm-same-height">
      <div className="head">
        <h6 className="mb-0 fw-bold">Most Ordered Dishes</h6>
        <a href="javascript:;" className="text-link" onClick={viewDetails}>
          View All
          <i className="material-symbols-outlined fall">arrow_forward</i>
        </a>
      </div>
      <div className="snippet-content">
        {data.length > 0 ? (
          data.map((item) => {
            console.log("Item:", item);
            console.log("Item:orderCount", item?.orderCount);
            console.log("Dish Variants:", item.dishDetails.dishvariants);

            return (
              <div className="dish-card mb-4" key={item.dishDetails.uuid}>
                <div className="dish">
                  <div className="thumbnail">
                    <img src={item.dishDetails.dishImage} alt={item.dishDetails.name} />
                  </div>
                  <div className="content">
                    <img
                      className="category-ico"
                      src={item.dishDetails.type.typeIcon}
                      alt={item.dishDetails.name}
                    />
                    <p className="mb-0 text-truncate">
                      <small>{item.dishDetails.name}</small>
                    </p>
                    <div className="rating">
                      {renderStars(item.orderCount)}
                    </div>
                    <div className="rating">
                    {/* <img className="ico" src={item?.dishDetails?.dishImage} alt="Star"/> */}
                    <img
                      className="ico fs-3"
                      style={{ width: "23px" }}
                      src="../media/star.svg"
                      alt="Star"
                    />
                    0
                  </div>
                  </div>
                </div>
                <p className="price mb-0 text-end pe-1 mid-gray">
                  {item.dishDetails.dishvariants && item.dishDetails.dishvariants.length > 0 ? (
                    <small>$ {item.dishDetails.dishvariants[0].finalPrice}</small>
                  ) : (
                    <small>$ 0</small>
                  )}
                </p>
              </div>
            );
          })
        ) : (
          <div className="text-center">
            <div className="thumbnail mt-5">
              <img
                src="../../../assets/images/noDishes.svg"
                style={{ height: "50px" }}
                alt="No Dishes"
              />
            </div>
            No Most Ordered Dishes
          </div>
        )}
      </div>
    </div>
  );
};

export { MostOrderDish };
