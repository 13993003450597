import { useNavigate } from "react-router-dom";
import { useAuth } from "../core/Auth";

import Header from "../../modules/sidebar/Header";
import "../../assets/custom-style.css";
import { MostOrderDish } from "./dashbordcomponent/MostOrderedDish";
import { TopRatedDish } from "./dashbordcomponent/TopRatedDish";
import { RecentRating } from "./dashbordcomponent/RecentRating";
import { Graph } from "./dashbordcomponent/Graph";
import { RecentOreder } from "./dashbordcomponent/RecentOredr";
import { useEffect, useState } from "react";
import { listOrdersData, totalNetValue } from "../core/_requests";
interface Order {
  orderstatusId: number;
  totalAmount: number;
}

const Dashboard = () => {
  const [listOrders, setListOrders] = useState<Order[] | undefined>();
  const [NetValue, setNetValue] = useState(0);
  const { currentUser } = useAuth();
  const date = new Date();
  const day: number = date.getDate();
  const month: string = date.toLocaleString("default", { month: "short" });
  const year: number = date.getFullYear();
  const currentDate: string = `${day} ${month} ${year}`;
  const navigate = useNavigate();

const chefId=currentUser?.uuid
console.log("chefId---dashboard",chefId);
console.log("currentUser--dashboard",currentUser);

  useEffect(() => {
    listOrdersData(chefId).then((res) => {
      setListOrders(res?.data);
    });
    totalNetValue(chefId).then((res: any) => {
      setNetValue(res.totalNetValue);
    });
  }, []);
  const viewReport = () => {
    navigate("/report/list");
  };
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/${encodeURIComponent(searchTerm)}`);
  };
  return (
    <>
      <section className="container-fluid">
        <div className="row">
            <Header />
          <section className="content-section">
       
            <section className="row main-content">
                      <div className="title mb-3 md-3">
              <h5 className="mb-1 mid-gray fw-bold">
                  <small>Hello, Chef {currentUser?.data?.name}!</small>
                </h5>
              </div>
              <div className="col-12 dashboard-section">
                <div className="row mb-4">
                  <div className="col-xl-7 col-12">
                    <div className="row">
                      <div className="col-md-4 col-12 mb-3 mb-md-0">
                        <div className="snippet-block anime blue">
                          <div className="ico-wrap">
                            <span className="material-symbols-outlined">
                              orders
                            </span>
                          </div>
                          <div className="count-cont">
                            <div className="count">
                              <h4 className="medium mb-1">
                                {listOrders?.length}
                                <i className="material-symbols-outlined up">
                                  trending_up
                                </i>
                              </h4>
                            </div>
                            <p className="mb-0 mid-gray">
                              <span className="tiny">Total Orders Received</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12 mb-3 mb-md-0">
                        <div className="snippet-block anime green">
                          <div className="ico-wrap">
                            <span className="material-symbols-outlined">
                              payments
                            </span>
                          </div>
                          <div className="count-cont">
                            <div className="count">
                              <h4 className="medium mb-1">
                                $ {NetValue.toFixed(2)}
                                <i className="material-symbols-outlined up">
                                  trending_up
                                </i>
                              </h4>
                            </div>
                            <p className="mb-0 mid-gray">
                              <span className="tiny">Total Sales</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="snippet-block anime red">
                          <div className="ico-wrap">
                            <span className="material-symbols-outlined">
                              do_not_disturb_on
                            </span>
                          </div>
                          <div className="count-cont">
                            <div className="count">
                              <h4 className="medium mb-1">
                                {
                                  listOrders?.filter(
                                    (order) => order.orderstatusId === 7
                                  ).length
                                }
                                <i className="material-symbols-outlined fall">
                                  trending_down
                                </i>
                              </h4>
                            </div>
                            <p className="mb-0 mid-gray">
                              <span className="tiny">Total Rejected Orders</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-4">
                        <div className="snippet-block">
                          <div className="head">
                            <h6 className="mb-0 fw-bold">
                              Orders by last week
                            </h6>
                            <a
                              href="javascript:;"
                              className="text-link"
                              onClick={viewReport}
                            >
                              View Report
                              <i className="material-symbols-outlined fall">
                                arrow_forward
                              </i>
                            </a>
                          </div>
                          <Graph />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-5 col-12 mt-xl-0 mt-4 mb-2">
                    <RecentOreder />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-xl-4 col-lg-6 col-12 mb-4">
                    <MostOrderDish />
                  </div>
                  <div className="col-xl-4 col-lg-6 col-12 mb-4">
                    <TopRatedDish />
                  </div>
                  <div className="col-xl-4 col-lg-6 col-12 mb-4">
                    <RecentRating />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </section>
    </>
  );
};

export { Dashboard };
