import { useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAuth } from "../../auth";
import Success from "./files/Success";

import ListSettlements from "./files/ListSettlements";
import AddSettlements from "./files/AddSettlements";
import DeleteSettlements from "./files/DeleteSettlements";
import { EditBasicDetailsWrapper } from "./files/EditWarpper";
import EditSuccess from "./files/EditSuccess";
import ViewTransaction from "./files/ViewTransaction";
import ViewSettlements from "./files/ViewSettlements";

const SettlementsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <ListSettlements />
            </>
          }
        />
        <Route
          path="/add"
          element={
            <>
              <AddSettlements />
            </>
          }
        />

        <Route
          path="/edit/:id"
          element={
            <>
              <EditBasicDetailsWrapper />
            </>
          }
        />
        <Route
          path="/delete/:id"
          element={
            <>
              <DeleteSettlements />
            </>
          }
        />
        <Route
          path="/success"
          element={
            <>
              <Success />
            </>
          }
        />
        <Route
          path="/editsuccess"
          element={
            <>
              <EditSuccess />
            </>
          }
        />
      

        <Route
          path="/all-transaction"
          element={
            <>
              <ViewTransaction />
            </>
          }
        />
        <Route
          path="/all-settlement"
          element={
            <>
              <ViewSettlements />
            </>
          }
        />
        <Route index element={<Navigate to="list" />} />
      </Route>
    </Routes>
  );
};

export default SettlementsPage;
